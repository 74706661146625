import { React} from "react";
import PropTypes from "prop-types";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";




// * Linea de Carga *
function LinearProgressWithLabel({ value, completed }) {
  return (
    <Box
      sx={{

        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundImage: 'url("../img/Cargoex.png")',
        height: "100%",
        width: "90%",
        margin: "0 auto",
      }}
    >
      <Box
        sx={{
          width: "80%",
          margin: "0 auto",
          position: "column",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <LinearProgress
          variant={completed ? "determinate" : "indeterminate"}
          value={completed ? 100 : value}
          sx={{ width: "90%", marginTop: '10px' }}
          
        />
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{

            fontWeight: "bold",
            fontSize: "1.5rem", 
            color: "blue",
            marginTrim: "10px",
          }}
        >
          {completed ? "100%" : `${Math.round(value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
  completed: PropTypes.bool.isRequired,
};

export function RedTransparentBackground({ progress, completed, disabled }) {

  return (
    <Box sx={{ width: "100%" }}>
      <LinearProgressWithLabel
        value={progress}
        completed={completed}
        disabled={disabled}
      />
    </Box>
  );
}





