import React, { useState, memo, useEffect } from "react";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { Button, Container, Grid } from "@mui/material";
import { Typography, Paper, Tooltip, Box } from "@mui/material";
import {
  ObtenerCediblesCliente,
  DescargarCediblesMasivoCliente,
  ObtenerTipoUsuarioCliente,
} from "../../services/CediblesService";
import { jsPDF } from "jspdf";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import CircularProgress from "@mui/material/CircularProgress";
import ReusableTableCedibles from "../../components/ClientesComponentes/componentesCedibles/TableCediblesClientes";
import { es } from "date-fns/locale";
import { useSelector } from "react-redux";

const CediblesClientesPage = () => {
  const userInfo = useSelector((state) => state.user.userInfo);
  const id_usuario = userInfo ? userInfo.id : null;
  const [dataTabla, setDataTabla] = useState([]);
  const [fechaDesde, setFechaDesde] = useState(null);
  const [fechaHasta, setFechaHasta] = useState(null);
  const [isLoadingCedibles, setIsLoadingCedibles] = useState(false);
  const [isLoadingDescargar, setIsLoadingDescargar] = useState(false);
  const [esPrimeraBusqueda, setEsPrimeraBusqueda] = useState(true);
  const [codCliente, setCodCliente] = useState(null);
  const [fechaDescargaDesde, setFechaDescargaDesde] = useState(null);
  const [fechaDescargaHasta, setFechaDescargaHasta] = useState(null);

  const columns = [
    { field: "fecha", headerName: "Fecha", width: 200 },
    { field: "guia", headerName: "Guía", width: 150 },
    { field: "odPapel", headerName: "OD", width: 150 },
    {
      field: "pdf",
      headerName: "PDF",
      width: 50,
      renderCell: (params) => createPdfIconLink(params.row.cedibleBase64, `${params.row.guia}.pdf`),
    },
  ];

  const createPdfIconLink = (base64Image, fileName) => {
    const pdf = new jsPDF("p", "mm", "a4");
    const imgData = "data:image/jpeg;base64," + base64Image;
    const pageWidth = 210;
    const pageHeight = 297;
    const margin = 10;
    let imgHeight = pageHeight - 2 * margin;
    let imgWidth = imgHeight / (3 / 4);
    if (imgWidth > pageWidth - 2 * margin) {
      imgWidth = pageWidth - 2 * margin;
    }
    const x = (pageWidth - imgWidth) / 2;
    const y = margin;

    pdf.addImage(imgData, "JPEG", x, y, imgWidth, imgHeight);
    const pdfUrl = pdf.output("bloburl");

    return (
      <a href={pdfUrl} download={fileName} title="Descargar PDF">
        <PictureAsPdfIcon style={{ color: "red" }} />
      </a>
    );
  };

  /* Función para descargar todos los cedibles de un dia */
  const descargarTodosCedibles = async () => {
    try {
      setIsLoadingDescargar(true);

      if (!fechaDescargaDesde || !fechaDescargaHasta) {
        toast.error("Debe seleccionar un rango de fechas para la descarga.");
        return;
      }

      const diferenciaDias = (new Date(fechaDescargaHasta) - new Date(fechaDescargaDesde)) / (1000 * 60 * 60 * 24);
      if (diferenciaDias > 30) {
        toast.error("El rango de fechas no puede ser mayor a 30 días.");
        return;
      }

      const clienteId = codCliente;
      const formattedFechaDescargaDesde = fechaDescargaDesde.toISOString().split("T")[0];
      const formattedFechaDescargaHasta = fechaDescargaHasta.toISOString().split("T")[0];
      const data = {
        codCliente: clienteId,
        fechaDescargaDesde: formattedFechaDescargaDesde,
        fechaDescargaHasta: formattedFechaDescargaHasta,
      };

      const blob = await DescargarCediblesMasivoCliente(data);
      if (!blob || blob.size === 0) {
        toast.error("No hay registros en la base de datos para el rango de fechas seleccionado.");
        return;
      }

      const downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = downloadUrl;
      a.download = "cedibles.zip";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(downloadUrl);
      a.remove();
    } catch (error) {
      console.error("Error al descargar los cedibles:", error);
      toast.error("Error al descargar cedibles.");
    } finally {
      setIsLoadingDescargar(false);
    }
  };

  /* formatear fecha de tabla */
  const formatDate = (isoString) => {
    const date = new Date(isoString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  };

  const rows = dataTabla.map((fila) => ({
    id: fila.ID,
    fecha: formatDate(fila.FH_SYS),
    codigoCliente: fila.COD_CLIENTE,
    guia: fila.GUIA,
    odPapel: fila.OD_PAPEL,
    cedibleBase64: fila.CEDIBLE_BASE64,
  }));

  /* Función para traer cedibles */
  const descargarCedibles = async () => {
    try {
      setIsLoadingCedibles(true);
      const clienteId = codCliente;
      const formattedFechaDesde = fechaDesde.toISOString().split("T")[0];
      const formattedFechaHasta = fechaHasta.toISOString().split("T")[0];
      const data = {
        codCliente: clienteId,
        fechaDesde: formattedFechaDesde,
        fechaHasta: formattedFechaHasta,
      };

      const response = await ObtenerCediblesCliente(data);
      if (!response || !response.registros || response.registros.length === 0) {
        toast.error("No hay registros en la base de datos para el cliente seleccionado.");
        setDataTabla([]);
        return;
      }
      setDataTabla(response.registros);
      if (esPrimeraBusqueda) {
        toast.success("Cedibles encontrados!");
        setEsPrimeraBusqueda(false);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Error al buscar cedibles.");
      setDataTabla([]);
    } finally {
      setIsLoadingCedibles(false);
    }
  };

  const realizarBusqueda = () => {
    setEsPrimeraBusqueda(true);
    descargarCedibles();
  };

  useEffect(() => {
    const fetchCodCliente = async () => {
      try {
        const response = await ObtenerTipoUsuarioCliente(id_usuario);
        if (response && response.codCliente) {
          setCodCliente(response.codCliente);
        } else {
          console.error("No se pudo obtener el código del cliente.");
        }
      } catch (error) {
        console.error("Error al obtener el código del cliente:", error);
      }
    };

    fetchCodCliente();
  }, [id_usuario]);

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
        <Container maxWidth="lg">
          <Paper style={{ padding: "10px", marginBottom: "20px", marginTop: "10px" }}>
            <Grid container justifyContent="center" spacing={1}>
              <Grid item xs={12} sm={3} md={2}>
                <DesktopDatePicker
                  label="Fecha Desde"
                  inputFormat="dd/MM/yyyy"
                  value={fechaDesde}
                  onChange={(newDate) => setFechaDesde(newDate)}
                  slotProps={{
                    textField: {
                      margin: "dense",
                      variant: "outlined",
                      size: "small",
                    },
                  }}
                />
                <Typography variant="caption" display="block" style={{ color: "red" }}>
                  Destinado solo a búsqueda.
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3} md={2}>
                <DesktopDatePicker
                  label="Fecha Hasta"
                  inputFormat="dd/MM/yyyy"
                  value={fechaHasta}
                  onChange={(newDate) => setFechaHasta(newDate)}
                  slotProps={{
                    textField: {
                      margin: "dense",
                      variant: "outlined",
                      size: "small",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={3} md={2} sx={{ marginTop: "10px" }}>
                <Button
                  sx={{
                    backgroundColor: "#041562",
                    color: "#FFFFFF",
                    "&:hover": {
                      backgroundColor: "#031042",
                    },
                    "&.Mui-disabled": {
                      backgroundColor: "#666",
                      color: "#FFF",
                    },
                  }}
                  onClick={realizarBusqueda}
                  variant="contained"
                  color="primary"
                  disabled={isLoadingCedibles}
                >
                  {isLoadingCedibles ? <CircularProgress sx={{ color: "#FFFFFF" }} size={24} /> : "Buscar"}
                </Button>
              </Grid>
              <Grid item xs={12} sm={3} md={2}>
                <DesktopDatePicker
                  label="Fecha Descarga Desde"
                  inputFormat="dd/MM/yyyy"
                  value={fechaDescargaDesde}
                  onChange={(newDate) => setFechaDescargaDesde(newDate)}
                  slotProps={{
                    textField: {
                      margin: "dense",
                      variant: "outlined",
                      size: "small",
                    },
                  }}
                />
                <Typography variant="caption" display="block" style={{ color: "red" }}>
                  Destinado a descarga masiva.
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3} md={2}>
                <DesktopDatePicker
                  label="Fecha Descarga Hasta"
                  inputFormat="dd/MM/yyyy"
                  value={fechaDescargaHasta}
                  onChange={(newDate) => setFechaDescargaHasta(newDate)}
                  slotProps={{
                    textField: {
                      margin: "dense",
                      variant: "outlined",
                      size: "small",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={3} md={2} sx={{ marginTop: "10px" }}>
                <Tooltip title="La descarga de todos los cedibles puede demorar varios minutos debido a la cantidad de archivos. Por favor, espere a la descarga masiva.">
                  <span>
                    <Button
                      sx={{
                        backgroundColor: "#041562",
                        color: "#FFFFFF",
                        "&:hover": {
                          backgroundColor: "#031042",
                        },
                        "&.Mui-disabled": {
                          backgroundColor: "#666",
                          color: "#FFF",
                        },
                      }}
                      onClick={descargarTodosCedibles}
                      variant="contained"
                      color="primary"
                      disabled={isLoadingDescargar}
                    >
                      {isLoadingDescargar ? <CircularProgress sx={{ color: "#FFFFFF" }} size={24} /> : "Descargar"}
                    </Button>
                  </span>
                </Tooltip>
              </Grid>
            </Grid>
          </Paper>
          {!isLoadingCedibles && dataTabla.length > 0 && (
            <Box sx={{ height: 400, width: "100%", marginTop: "20px" }}>
              <ReusableTableCedibles columns={columns} rows={rows} loading={isLoadingCedibles} searchField="guia" />
            </Box>
          )}
          {!isLoadingCedibles && dataTabla.length === 0 && !esPrimeraBusqueda && (
            <Paper style={{ padding: "20px", marginTop: "20px" }}>
              <Typography textAlign="center">No se encontraron cedibles para los criterios de búsqueda.</Typography>
            </Paper>
          )}
        </Container>
      </LocalizationProvider>
    </>
  );
};

export default memo(CediblesClientesPage);
