import axios from "axios";
/* Usa la variable de entorno para la URL base */
const BASE_API_URL = process.env.REACT_APP_BACKEND_URL;

export const obtenerClientesRetiro = async () => {
    try {
      const response = await axios.get(
        `${BASE_API_URL}gestion/getClientesRetiros`
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching getClientesRetiros:", error);
      throw error;
    }
  };
  
  export const obtenerCiudadesHomologadas = async (Ciudad) => {
    try {
      const response = await axios.post(
        `${BASE_API_URL}gestion/getCiudadesHomologadas`,
        { Ciudad }
      );
      if (!response.data.success) {
        console.log("Ciudad no encontrada:", Ciudad);
      }
    } catch (error) {
      throw error;
    }
  };
  
  
  //Obtener Canal CLiente
  export const CanalClientes = async (codigoCliente) => {
    try {
      let data = JSON.stringify({
        COD_CLIENTE: codigoCliente,
      });
      const response = await axios.post(
        "http://app.cargoex.cl/app/cargoex/app/CanalCliente",
        data,
        {
          headers: {
            "X-API-KEY": "55IcsddHxiy2E3q653RpYtb",
          },
        }
      );
  
      return response.data;
    } catch (error) {
      console.error("Hubo un error al hacer la solicitud:", error);
      throw error;
    }
  };
  
  export const CentroCostoClientes = async (codigoCliente) => {
    try {
      let data = JSON.stringify({
        COD_CLIENTE: codigoCliente,
      });
      const response = await axios.post(
        "http://app.cargoex.cl/app/cargoex/app/centroCosto",
        data,
        {
          headers: {
            "X-API-KEY": "55IcsddHxiy2E3q653RpYtb",
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Hubo un error al hacer la solicitud:", error);
      throw error;
    }
  };
  
  //Obtener Codigo Cliente por Id usuario
  export const buscarCliente = async (id) => {
    try {
      const response = await axios.post(
        `${BASE_API_URL}gestion/getObtenerClienteUsuario`,
        {
          id,
        }
      );
  
      if (response.data.length > 0) {
        const codCliente = response.data[0].cod_cliente;
        return codCliente;
      } else {
        console.log("El array de datos está vacío");
        return null; 
      }
    } catch (error) {
      console.error("Error obteniendo Cliente segun ID desde API.JS", error);
      throw error;
    }
  };
  
  //Obtener Tipo usuario por Id usuario
  export const buscarTipoUsuario = async (id) => {
    try {
      const response = await axios.post(`${BASE_API_URL}gestion/getTipoUsuario`, {
        id,
      });
      if (response.data && response.data.length > 0) {
  
        const tipoUsuario = response.data[0].tipo_usuario;
        return tipoUsuario;
      } else {
        console.log("No hay datos desde APS.JS tipo Usuario");
        return null;
      }
    } catch (error) {
      console.error("Error obteniendo datos Tipo Usuario desde API.js", error);
      throw error;
    }
  };
  
  
  export const insertOdRetiro = async (data) => {
    try {
      const response = await axios.post(
        `${BASE_API_URL}gestion/insertOrdenRetiro`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error Insert OD:", error);
      throw error;
    }
  };
  
  export const buscarIataCiudad = async (CIUDAD) => {
    try {
      const response = await axios.post(
        `${BASE_API_URL}gestion/getIataCiudades`,
        {
          CIUDAD,
        }
      );
  
      if (response.data.length > 0) {
        const iataCiudad = response.data[0].IATA;
        return iataCiudad;
      } else {
        console.log("El array de datos está vacío");
        return null; 
      }
  
    } catch (error) {
      console.error("Error obteniendo datos consolidados Centro Costo", error);
      throw error;
    }
  };
  
  export const obtenerOD = async (OD) => {
    try {
      const response = await axios.post(`${BASE_API_URL}gestion/getOrden`, {
        OD: OD, 
      });
      return response.data;
    } catch (error) {
      console.error("Error obteniendo datos ORDEN desde API.js", error);
      throw error;
    }
  };