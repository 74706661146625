import React, { useState, memo, useRef } from "react";
import { Grid, Button, Card, CardContent, Paper, TextField } from "@mui/material";
import Tabla from "../componentesCubicos/tabla";
import * as XLSX from "xlsx";
import Papa from "papaparse";
import { toastDatosEnBlanco, ordenNoModificada, ordenModificada } from "../componentesCubicos/Alertas";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ValidadorArchivo, limiteDeFilas, LinearProgressOverlay } from "../componentesCubicos/Funciones";
import { getOrdenesMedidasNovofarma, verificarOrdenes_Medidas } from "../../../services/CubicosServices";
import { useSelector } from "react-redux";
import { insertOrdenesMedidas } from "../../../services/CubicosServices";

const GestionMedidasMasica = () => {
  const userInfo = useSelector((state) => state.user.userInfo);
  // eslint-disable-next-line no-unused-vars
  const id_usuario = userInfo ? userInfo.id : null;
  const [fecha, setFecha] = useState("");
  const [key, setKey] = useState(Date.now());
  const fileInputRef = useRef(null);
  const [data, setData] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [progress, setProgress] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [jsonDataArray, setJsonDataArray] = useState([]);
  const [isbtnEnviar, setBtnEnviar] = useState(true);

  // La cabecera predefinida, excel debe terner los mismo datos.
  const predefinedHeaders = [
    "COD_BARRA",
    "GUIA",
    "OD",
    "BULTOS_TOTAL",
    "PESO_TOTAL",
    "BULTOS",
    "PESO",
    "ALTO",
    "LARGO",
    "ANCHO",
    "TIPO",
  ];

  // Las columnas obligatorias segun cabecera
  const obligatoriasHeaders = ["OD", "BULTOS", "PESO", "ALTO", "LARGO", "ANCHO", "TIPO"];

  //Funcion validar que la cabecera del excel sea la misma del codigo
  const validateHeaders = (parsedData, predefinedHeaders) => {
    const fileHeaders = parsedData.data[0];
    console.log("Encabezados del archivo CSV:", fileHeaders);
    console.log("Encabezados predefinidos:", predefinedHeaders);

    for (let i = 0; i < predefinedHeaders.length; i++) {
      if (fileHeaders[i] !== predefinedHeaders[i]) {
        return false;
      }
    }
    return true;
  };
  //Carga de Excel y sus validaciones
  const cargaExcelTabla = async (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.readAsArrayBuffer(file);

    await new Promise((resolve) => {
      reader.onload = async (event) => {
        const data = new Uint8Array(event.target.result);
        const binaryString = Array.from(data)
          .map((byte) => String.fromCharCode(byte))
          .join("");

        const workbook = XLSX.read(binaryString, { type: "binary" });
        const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
        const csvData = XLSX.utils.sheet_to_csv(firstSheet);

        Papa.parse(csvData, {
          complete: async (parsedData) => {
            const headersMatch = validateHeaders(parsedData, predefinedHeaders);

            if (ValidadorArchivo(headersMatch)) {
              Papa.parse(csvData, {
                complete: (parsedData) => {
                  const newHeaders = predefinedHeaders;
                  setHeaders(newHeaders);

                  const dataSinObjetosVacios = parsedData.data
                    .slice(0)
                    .map((row) => {
                      let obj = {};
                      newHeaders.forEach((header, index) => {
                        obj[header] = row[header];
                      });

                      return obj;
                    })
                    .filter((obj) => {
                      for (const key in obj) {
                        if (obj[key] !== "") {
                          return true;
                        }
                      }
                      return false;
                    });

                  setData(dataSinObjetosVacios);

                  parsedData.data.slice(0).forEach((row, index) => {
                    const rowData = {
                      OD: row["OD"],
                      CANTIDAD_FRIO: row["BULTOS"],
                      PESO: row["PESO"] ? row["PESO"].toString().replace(",", ".") : null,
                      LARGO: row["LARGO"] ? row["LARGO"].toString().replace(",", ".") : null,
                      ALTO: row["ALTO"] ? row["ALTO"].toString().replace(",", ".") : null,
                      ANCHO: row["ANCHO"] ? row["ANCHO"].toString().replace(",", ".") : null,
                      CANAL: row["TIPO"],
                      ID_REFERENCIA: row["COD_BARRA"],
                      USUARIO: id_usuario,
                      COD_CLIENTE: 632,
                    };

                    if (limiteDeFilas(row)) {
                      jsonDataArray.push(rowData);
                    }
                  });

                  for (let rowIndex = 0; rowIndex < parsedData.data.length; rowIndex++) {
                    const row = parsedData.data[rowIndex];
                    if (limiteDeFilas(row)) {
                      const columnasFaltantes = obligatoriasHeaders.filter((columna) => !row[columna]);
                      if (columnasFaltantes.length > 0) {
                        toastDatosEnBlanco(columnasFaltantes.join(", "), rowIndex + 2);
                        e.target.value = "";
                        setData([]);
                        setJsonDataArray([]);
                        return;
                      } else {
                        resolve();
                      }
                    }
                  }
                },
                header: true,
              });

              setBtnEnviar(false);
              setProgress(100);
              setIsVisible(false);
              setProgress(0);
              setCompleted(false);
            } else {
              e.target.value = "";
              setKey(Date.now());
              fileInputRef.current.value = "";
              setData([]);
              setJsonDataArray([]);
            }
          },
        });
      };
    });
  };

  //Limpiamos Array
  const LimpiaArray = (e) => {
    setJsonDataArray([]);
  };

  const resetState = () => {
    setData([]);
    setJsonDataArray([]);
    setIsVisible(false);
    setProgress(0);
    setCompleted(false);
    fileInputRef.current.value = "";
  };

  const subirDatos = async () => {
    console.log("Json", jsonDataArray);

    try {
      const ods = jsonDataArray.map((item) => item.OD);
      console.log("ODS:", ods);
      const verificarResponse = await verificarOrdenes_Medidas(ods);
      console.log("Respuesta de verificación:", verificarResponse);

      if (Array.isArray(verificarResponse) && verificarResponse.length > 0) {
        toast.error(`Las siguientes órdenes ya fueron agregadas: ${verificarResponse.join(", ")}`, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        return;
      }
    } catch (error) {
      console.error("Error al verificar órdenes duplicadas:", error);
      toast.error("Error al verificar órdenes duplicadas. Por favor, inténtalo de nuevo.", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    }

    const totalOrders = jsonDataArray.length;
    let updateOrdersCount = 0;

    for (const jsonData of jsonDataArray) {
      try {
        setIsVisible(true);

        const response = await insertOrdenesMedidas(jsonData);

        updateOrdersCount++;
        const newProgress = (updateOrdersCount / totalOrders) * 100;
        setProgress(newProgress);

        if (response && response.success) {
          const newProgress = (updateOrdersCount / totalOrders) * 100;
          setProgress(newProgress);
          if (newProgress === 100) {
            setCompleted(true);
          }
        }
      } catch (error) {
        toast.error("Error al modificar órdenes. Por favor, inténtalo de nuevo.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        setIsVisible(false);
        setProgress(0);
        setCompleted(false);
      }
    }
    setProgress(100);

    if (updateOrdersCount > 0) {
      ordenModificada();
      setIsVisible(false);
      setProgress(0);
      setCompleted(false);

      setTimeout(() => {
        setKey(Date.now());
        fileInputRef.current.value = "";
        setData([]);
        setJsonDataArray([]);
        resetState();
      }, 2000);
    } else {
      ordenNoModificada();
    }
  };

  const exportToExcel = (data) => {
    const formattedData = data.flatMap((item) => {
      const rows = [
        {
          COD_BARRA: item.COD_BARRA,
          GUIA: item.GUIA,
          OD: item.OD,
          BULTOS_TOTAL: item.BULTOS,
          PESO_TOTAL: item.PESO,
          BULTOS: 0,
          PESO: 0,
          ALTO: 0,
          LARGO: 0,
          ANCHO: 0,
          TIPO: "TIPO",
        },
      ];

      if (item.BULTOS > 1) {
        rows.push({
          COD_BARRA: item.COD_BARRA,
          GUIA: item.GUIA,
          OD: item.OD,
          BULTOS_TOTAL: item.BULTOS,
          PESO_TOTAL: item.PESO,
          BULTOS: 0,
          PESO: 0,
          ALTO: 0,
          LARGO: 0,
          ANCHO: 0,
          TIPO: "TIPO",
        });
      }

      return rows;
    });

    const worksheet = XLSX.utils.json_to_sheet(formattedData);

    // Definir el ancho de las columnas
    worksheet["!cols"] = [
      { wch: 30 },
      { wch: 20 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 15 },
      { wch: 20 },
    ];

    const workbook = {
      Sheets: { data: worksheet },
      SheetNames: ["data"],
    };

    XLSX.writeFile(workbook, "Planilla Cubicos.xlsx");
  };

  const descargarExcel = async () => {
    const datos = {
      FECHA: fecha,
    };
    const data = await getOrdenesMedidasNovofarma(datos);
    if (data.length > 0) {
      exportToExcel(data);
    } else {
      toast.error("Datos no encontrados para fecha seleccionada.");
    }
  };

  const handleChangeFecha = async (event, newValue) => {
    try {
      setFecha(event.target.value);
    } catch (error) {}
  };

  return (
    <div>
      <Paper style={{ padding: "10px" }}>
        <Form>
          <Grid container spacing={2} alignItems="flex-end">
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <TextField
                fullWidth
                type="date"
                size="small"
                label="Fecha"
                value={fecha}
                onChange={handleChangeFecha}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <Button
                style={{ backgroundColor: "#041562" }}
                className="boton2"
                size="medium"
                variant="contained"
                color="primary"
                onClick={descargarExcel}
              >
                Descargar Detalle
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="flex-end" marginTop={"15px"}>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <Form.Group controlId="formFile">
                <Form.Label className="tu-archivo-de-estilos1">Seleccione Archivo Excel</Form.Label>
                <Form.Control
                  type="file"
                  ref={fileInputRef}
                  key={key}
                  onChange={cargaExcelTabla}
                  onClick={LimpiaArray}
                  size="sm"
                />
              </Form.Group>
            </Grid>

            <Grid item xs={12} sm={4} md={4} lg={4}>
              <Button
                variant="contained"
                size="medium"
                sx={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#041562",
                }}
                onClick={subirDatos}
                disabled={isbtnEnviar}
              >
                Enviar
              </Button>
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <Button
                variant="contained"
                size="medium"
                sx={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#DA251C",
                }}
                onClick={resetState}
              >
                Cancelar
              </Button>
            </Grid>
          </Grid>

          <div>
            <LinearProgressOverlay isVisible={isVisible} progress={progress} completed={completed} disabled={false} />
          </div>

          {data.length > 0 && (
            <Grid item xs={10} sm={6} md={6} lg={6} style={{ width: "100%", maxWidth: "100%" }}>
              <Card elevation={10} className="cardContentWithMargin">
                <CardContent
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    Width: "100%",
                    maxHeight: "75vh",
                    overflowX: "auto",
                    overflowY: "auto",
                  }}
                >
                  <Tabla
                    data={data}
                    headers={headers}
                    onCpdateellU={(rowIndex, header, newValue) => {
                      const newData = [...data];
                      newData[rowIndex][header] = newValue;
                      setData(newData);
                    }}
                  />
                </CardContent>
              </Card>
            </Grid>
          )}
        </Form>
      </Paper>
    </div>
  );
};

export default memo(GestionMedidasMasica);
