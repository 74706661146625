import React, { useState, useRef, useCallback, useEffect } from "react";
import { Paper, Grid, Button, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { getOrdenIdReferencia } from "../../../services/ManifiestosServices";

const ConsultaCodigoBarra = () => {
  const inputRef = useRef(null);
  const [orderData, setOrderData] = useState(null);

  const ordenConsultada = useCallback(
    async (event) => {
      event.preventDefault();
      const ordenValue = inputRef.current.value.trim();
      const datos = {
        COD_CLIENTE: 632,
        COD_BARRA: ordenValue,
      };
      const response = await getOrdenIdReferencia(datos);
      if(response.length > 0)
      {
        setOrderData(response[0]);
        console.log(response);
        inputRef.current.value = "";
      }
      else{
        toast.error("Orden debe ser de Novofarma")
        inputRef.current.value = "";
      }
     
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleConsultarOrden = async (event) => {
    await ordenConsultada(event);
  };

  const formatFecha = (fecha) => {
    const date = new Date(fecha);
    return date.toLocaleDateString('es-ES', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  };

  useEffect(() => {
    if (orderData) {
      const timer = setTimeout(() => {
        setOrderData(null);
      }, 10000);

      return () => clearTimeout(timer);
    }
  }, [orderData]);

  return (
    <>
      <Paper style={{ padding: "10px", marginBottom: "10px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3} md={3} lg={3}>
            <input
              ref={inputRef}
              type="text"
              placeholder="Ingrese Codigo Barra"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  ordenConsultada(e);
                }
              }}
              required
            />
          </Grid>
          <Grid item xs={12} sm={3} md={3} lg={3} justifyContent="flex-end">
            <Button
              variant="contained"
              size="medium"
              sx={{
                width: "100%",
                height: "100%",
                backgroundColor: "#041562",
              }}
              onClick={handleConsultarOrden}
            >
              Consultar
            </Button>
          </Grid>
        </Grid>
      </Paper>
      {orderData && (
        <Paper style={{ padding: "10px", marginTop: "10px" }}>
          <Typography variant="h6">Detalles de la Orden</Typography>
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <Typography>
                <strong>CLIENTE:</strong> {orderData.NOMBRE}
              </Typography>
            </Grid>
            <Grid item>
              <Typography>
                <strong>CÓDIGO BARRA:</strong> {orderData.COD_BARRA}
              </Typography>
            </Grid>
            <Grid item>
              <Typography>
                <strong>OD:</strong> {orderData.OD}
              </Typography>
            </Grid>
            <Grid item>
              <Typography>
                <strong>FECHA:</strong> {formatFecha(orderData.FH_DIGITACION)}
              </Typography>
            </Grid>
            <Grid item>
              <Typography>
                <strong>TIPO:</strong> {orderData.TIPO_ORDEN}
              </Typography>
            </Grid>
            <Grid item>
              <Typography sx={{ color: "blue", fontSize: "2rem" }}>
                <strong>COMUNA DESTINO:</strong> {orderData.NOMBRE_DESTINO}
              </Typography>
            </Grid>
            <Grid item>
              <Typography sx={{ color: "red", fontSize: "3rem" }}>
                <strong>CIUDAD JAULA:</strong> {orderData.NOMBRE_DESTINO_PADRE}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      )}
    </>
  );
};

export default ConsultaCodigoBarra;
