import React, { useState, useEffect, useCallback, useRef } from "react";
import { Button, Grid, TextField, Typography, CircularProgress, Paper } from "@mui/material";
import "bootstrap/dist/css/bootstrap.min.css";
import moment from "moment";
import useSound from "use-sound";
import sonido1 from "../../../components/OperacionesComponentes/componentesManifiestos/Sonidos/AlertaMa.mp3";
import {
  buscarDatosOrden,
  insertPickingTemp,
  insertManifiesto,
  deletePickingTemp,
  insertPicking,
  buscarDatosPickingTempManifiestoEntradaAgente,
  pendientesEntradaAgente,
  downloadManifestPdfGlobal,
} from "../../../services/ManifiestosServices";
import Swal from "sweetalert2";
import TablaComparativa from "../../../components/OperacionesComponentes/ComponentesManifiestoEntradaAgente/TablaComparativa";
import { useSelector } from "react-redux";
import Mail_Gestor from "../../../components/OperacionesComponentes/ComponentesManifiestoEntradaAgente/Mail";
import TablaManifiestosAgentes from "../../../components/OperacionesComponentes/componentesManifiestos/TablaManifiestosAgentes";
import DeleteIcon from "@mui/icons-material/Delete";

function ManifiestoEntradaAgente() {
  const inputRef = useRef(null);
  const userInfo = useSelector((state) => state.user.userInfo);
  const id_user = userInfo ? userInfo.id : null;
  const iataUser = userInfo ? userInfo.iata_agentes : null;
  const ciudadUser = userInfo ? userInfo.nombre_ciudad_agentes : null;
  const nombreUser = userInfo ? userInfo.name : null;
  const correoUser = userInfo ? userInfo.email : null;
  const agenteAsignado = userInfo ? userInfo.AGENTE_ASIGNADO : null;
  const tipoUsuario = userInfo ? userInfo.tipo_usuario : null;
  const [proceso, setProceso] = useState("");
  const [fechaActual, setfechaActual] = useState("");
  const [nota, setNota] = useState("");
  const [idChofer, setIdChofer] = useState("");
  const [ordenesDataTabla, setOrdenesDataTabla] = useState([]);
  const [totalBultos, setTotalBultos] = useState(0);
  const [totalOrdenes, setTotalOrdenes] = useState(0);
  const [ordenesDataTablaM, setOrdenesDataTablaM] = useState([]);
  const [ordenesPendientes, setOrdenesPendientes] = useState();
  const [isbtnEnviar, setIsbtnEnviar] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const [loadingCarga, setLoadingCarga] = React.useState(false);
  const [loadingEliminar, setLoadingEliminar] = React.useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setProceso("ENTRADAAGENTE");
      setLoadingCarga(true); 

      try {
        const fechaActual = moment().format("YYYY-MM-DD");
        setfechaActual(fechaActual);

        const proceso = "ENTRADAAGENTE";

        const datos = {
          COD_USUARIO: id_user,
          PROCESO: proceso,
          COMUNA: iataUser,
        };
        const dataPickingTemp = await buscarDatosPickingTempManifiestoEntradaAgente(datos);

        let totalBultosTemp = 0;
        if (dataPickingTemp && dataPickingTemp.results) {
          dataPickingTemp.results.forEach((orden) => {
            if (orden.TIPO_ORDEN !== "RETIRO") {
              totalBultosTemp += orden.BULTOS;
            }
          });
          setTotalOrdenes(dataPickingTemp.results.length);
        }

        setTotalBultos(totalBultosTemp);

        if (dataPickingTemp && dataPickingTemp.results) {
          setOrdenesDataTabla(dataPickingTemp.results);
        } else {
          setOrdenesDataTabla([]);
        }
      } catch {
      } finally {
        setLoadingCarga(false); 
      }
    };
    fetchData();
    // eslint-disable-next-line no-use-before-define
  }, [ciudadUser, iataUser, id_user, userInfo]);

  const actualizarPendientes = useCallback(async () => {
    const actualizar = async () => {
      try {
        const usuarioBusqueda = tipoUsuario === "AGENTE" ? nombreUser : agenteAsignado;

        const dataManif = await pendientesEntradaAgente(usuarioBusqueda);
        setLoading(false);
        setIsbtnEnviar(false);
        setOrdenesDataTablaM(dataManif);
        setOrdenesPendientes(dataManif.length);

        timeoutRef.current = setTimeout(actualizar, 1 * 60 * 1000);
      } catch (error) {
        console.error("Error al actualizar los datos pendientes de salida:", error);
      }
    };

    actualizar();
  }, [nombreUser, agenteAsignado, tipoUsuario]); 

  const timeoutRef = useRef();

  useEffect(() => {
    actualizarPendientes();

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [actualizarPendientes]);

  const columns = [
    { field: "OD", headerName: "OD", width: 100 },
    { field: "NOMBRE", headerName: "Cliente", width: 350 },
    { field: "BULTOS", headerName: "Bultos", width: 70 },
    { field: "TIPO_ORDEN", headerName: "Tipo Orden", width: 100 },
    { field: "NOMBRE_DESTINO", headerName: "Comuna", width: 200 },
    {
      field: "delete",
      headerName: "Eliminar",
      width: 100,
      renderCell: (params) => (
        <DeleteIcon style={{ color: "#DA251C", cursor: "pointer" }} onClick={() => handleDelete(params.row.OD)} />
      ),
    },
  ];
  /* borrar ordenes de la tabla y también de la base de datos */
  const handleDelete = async (orden) => {
    setLoadingEliminar(true);
    const result = await Swal.fire({
      title: "¿Estás seguro?",
      text: "No podrás revertir esta acción",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, eliminar!",
    });

    if (result.isConfirmed) {
      try {
        const response = await deletePickingTemp({
          OD: orden,
          COD_USUARIO: id_user,
          PROCESO: proceso,
        });

        if (response.message === "PickingTemp Eliminado con éxito") {
          const datos = {
            COD_USUARIO: id_user,
            PROCESO: proceso,
            COMUNA: iataUser,
          };
          try {
            const resultados = await buscarDatosPickingTempManifiestoEntradaAgente(datos);

            let totalBultosTemp = 0;
            if (resultados && resultados.results) {
              resultados.results.forEach((orden) => {
                if (orden.TIPO_ORDEN !== "RETIRO") {
                  totalBultosTemp += orden.BULTOS;
                }
              });
              setTotalOrdenes(resultados.results.length);
            }

            setTotalBultos(totalBultosTemp);

            if (resultados && Array.isArray(resultados.results)) {
              setOrdenesDataTabla(resultados.results);
            } else {
              setOrdenesDataTabla([]);
            }
          } catch (error) {
            console.error("Error al buscar datos de picking temp:", error);
            setOrdenesDataTabla([]);
          }

          Swal.fire("Eliminado!", "El registro ha sido eliminado.", "success");
        } else {
          Swal.fire("No se pudo eliminar", response.message, "error");
        }
      } catch (error) {
        console.error("Error al eliminar el picking temp:", error);
        Swal.fire("Error!", "No se pudo eliminar el registro debido a un error en el servidor.", "error");
      } finally {
        setLoadingEliminar(false);
      }
    }
    setLoadingEliminar(false);
  };

  const getFecha = () => {
    try {
      const fechaActual = new Date();
      const options = {
        timeZone: "America/Santiago",
        hour12: false, 
      };

      const fecha = fechaActual.toLocaleDateString("es-CL", options);

      const [dia, mes, año] = fecha.split("-");

      const nuevaFecha = `${año}-${mes}-${dia}`;

      const hora = fechaActual.toLocaleTimeString("es-CL", {
        ...options, 
        hour12: false,
      });

      const fechaHoraFormateada = `${nuevaFecha} ${hora}`;

      return fechaHoraFormateada;
    } catch (error) {
      console.error("Error al obtener la fecha:", error);
      return null; 
    }
  };

  const showError = (message) => {
    Swal.fire({
      icon: "error",
      title: message,
    });
    playsound();
    inputRef.current.value = "";
  };

  const resetState = () => {
    setNota("");
    inputRef.current.value = "";
    setOrdenesDataTabla([]);
    setTotalBultos(0);
    setIdChofer("");
    const fechaActual = moment().format("YYYY-MM-DD");
    setfechaActual(fechaActual);
    setTotalOrdenes(0);
    setLoading(false);
    setIsbtnEnviar(false);
  };

  const handleChangeNota = (event) => {
    setNota(event.target.value);
  };

  const ingresarOrdenTabla = async (dataOrden) => {
    try {
      if (dataOrden && dataOrden.length > 0) {
        try {
          await insertPickingTemp({
            OD: dataOrden[0].OD,
            COD_USUARIO: id_user,
            COD_CHOFER: id_user,
            COMUNA: iataUser,
            PROCESO: proceso,
          });

          setOrdenesDataTabla([...ordenesDataTabla, dataOrden[0]]);

          if (dataOrden.TIPO_ORDEN !== "RETIRO") {
            setTotalBultos(totalBultos + dataOrden[0].BULTOS);
          }
          setTotalOrdenes(totalOrdenes + 1);
        } catch (error) {
          console.error("Error:", error);
        }
        inputRef.current.value = "";
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const [playsound] = useSound(sonido1);

  const ordenIngresada = useCallback(
    async (event) => {
      event.preventDefault();
      const ordenValue = inputRef.current.value.trim();

      try {
        try {
          const dataOrden = await buscarDatosOrden(ordenValue);
          console.log(dataOrden);
          if (dataOrden && dataOrden.length > 0) {
            const ordenExistente = ordenesDataTabla.some((orden) => orden.ID === dataOrden[0].ID);

            if (!ordenExistente) {
              if (
                (dataOrden[0].TIPO_ORDEN === "ENTREGA" && iataUser === dataOrden[0].IATA_PADRE_COMUNA_DESTINO) ||
                (dataOrden[0].TIPO_ORDEN === "RETIRO" &&
                  (iataUser === dataOrden[0].IATA_PADRE_COMUNA_ORIGEN || dataOrden[0].COD_ESTADO === 99))
              ) {
                try {
                  ingresarOrdenTabla(dataOrden);
                } catch {}
              } else {
                Swal.fire({
                  title: "Seguro Registrar Orden?",
                  text: "Esta a punto de Ingresar una Orden con Destino Diferente!!!",
                  icon: "warning",
                  showCancelButton: true,
                  cancelButtonText: "Cancelar",
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Si, Ingresar!",
                }).then((result) => {
                  if (result.isConfirmed) {
                    ingresarOrdenTabla(dataOrden);
                  } else {
                    inputRef.current.value = "";
                  }
                });
                playsound();
                return;
              }
            } else {
              showError("Orden ya ingresada al manifiesto!!!");
              playsound();
              inputRef.current.value = "";
              return;
            }
            inputRef.current.value = "";
          } else {
            showError("Orden no existe!!!");
            playsound();
            inputRef.current.value = "";
            return;
          }
        } catch (error) {
          console.error("Error:", error);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [idChofer, ordenesDataTabla, ciudadUser],
  );

  const handleActualizar = async (event, newValue) => {
    actualizarPendientes();
  };

  const handleIngresarOrden = async (event) => {
    await ordenIngresada(event);
  };

  const crearManifeisto = async () => {
    try {
      const fecha = await getFecha();
      const data = {
        FH_CREACION: fecha,
        FH_CIERRE: fecha,
        COD_USUARIO: id_user,
        PROCESO: proceso,
        ID_CLIENTE: 0,
        COD_CHOFER: idChofer.value,
        ESTADO: "CERRADO",
        COD_OPERADOR_ENTRADA: id_user,
        COD_CHOFER_ENTRADA: id_user,
        NOMBRE_CHOFER_ENTRADA: nombreUser,
        SELLOS: "",
        CANTIDAD_VALIJAS: 0,
        TOTAL_BULTOS: totalBultos,
        TIPO_RUTA: "TERRESTRE",
        TIPO_MANIFIESTO: proceso,
        ORIGEN: iataUser,
        DESTINO: iataUser,
        NOTA: nota,
        FH_MAIL: "",
        RUTA: iataUser,
      };

      const response = await insertManifiesto(data);

      const datosParaEnviar = {
        COD_USUARIO: id_user,
        TIPO_MANIFIESTO: proceso,
        DESTINO: iataUser,
        ID_MANIFIESTO: response,
      };

      try {
        await insertPicking(datosParaEnviar);
      } catch (error) {
        console.log("Fallo el envio");
      }
      return response;
    } catch (error) {
      console.error("Error al crear el manifiesto:", error);
    }
  };

  const handleCrearManifiesto = async () => {
    setLoading(true);
    setIsbtnEnviar(true);
    try {
      const usuarioBusqueda = tipoUsuario === "AGENTE" ? nombreUser : agenteAsignado;

      const dataManif = await pendientesEntradaAgente(usuarioBusqueda);
      setOrdenesDataTablaM(dataManif);
      setOrdenesPendientes(dataManif.length);

      if (ordenesDataTabla.length === 0) {
        showError("Manifiesto debe contener alguna orden!!!");
        setLoading(false);
        setIsbtnEnviar(false);
        playsound();
        return;
      } else if (dataManif.length !== 0) {
        Swal.fire({
          title: "Seguro de Crear Manifiesto?",
          text: "Aun quedan ordenes pendientes!",
          icon: "warning",
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si, Crear!",
        }).then(async (result) => {
          if (result.isConfirmed) {
            setLoading(true);
            setIsbtnEnviar(true);
            const numeroManifiesto = await crearManifeisto();
            await Mail_Gestor({ proceso, correoUser, numeroManifiesto });
            resetState();
            Swal.fire({
              title: "Manifiesto Creado",
              text: "N°: " + numeroManifiesto,
              icon: "success",
            });
            try {
              await downloadManifestPdfGlobal(numeroManifiesto, proceso, ciudadUser);
              setLoading(false);
            } catch (error) {
              showError("Error al descargar el PDF del manifiesto");
            }
          } else {
            setLoading(false);
            setIsbtnEnviar(false);
          }
        });
      } else {
        Swal.fire({
          title: "Seguro de Crear Manifiesto?",
          text: "Esta a punto de crear un nuevo Manifiesto!",
          icon: "warning",
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si, Crear!",
        }).then(async (result) => {
          if (result.isConfirmed) {
            setLoading(true);
            const numeroManifiesto = await crearManifeisto();
            await Mail_Gestor({ proceso, correoUser, numeroManifiesto });
            resetState();
            Swal.fire({
              title: "Manifiesto Creado",
              text: "N°: " + numeroManifiesto,
              icon: "success",
            });
            try {
              await downloadManifestPdfGlobal(numeroManifiesto, proceso, ciudadUser);
            } catch (error) {
              showError("Error al descargar el PDF del manifiesto");
            }
          }
        });
      }
    } catch (error) {
      showError("Error al buscar manifiestos o crear manifiesto.");
      setLoading(false);
      setIsbtnEnviar(false);
    }
  };

  return (
    <div>
      <Paper style={{ padding: "5px" }}>
        <form>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                disabled
                size="small"
                id="outlined-size-small"
                label="CIUDAD"
                value={ciudadUser}
                sx={{ width: "100%" }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                disabled
                size="small"
                id="outlined-size-small"
                name="FH_ACTUAL"
                label="Fecha"
                sx={{ width: "100%" }}
                value={fechaActual}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                size="small"
                id="outlined-size-small"
                fullWidth
                label="Nota"
                multiline
                rows={1}
                name="message"
                value={nota}
                onChange={handleChangeNota}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}></Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} justifyContent="flex-end">
              <Button
                variant="contained"
                size="medium"
                sx={{
                  width: "100%",
                  height: "90%",
                  backgroundColor: "#041562",
                }}
                onClick={handleCrearManifiesto}
                disabled={isbtnEnviar}
              >
                {loading ? <CircularProgress sx={{ color: "#FFFFFF" }} size={24} /> : "Crear Manifiesto"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
      <Paper style={{ padding: "10px", marginTop: "10px" }}>
        <form>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <input
                ref={inputRef}
                type="text"
                placeholder="Ingrese OD"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    ordenIngresada(e);
                  }
                }}
                required
                disabled={loadingCarga}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={1} lg={1} justifyContent="flex-end">
              <Button
                variant="contained"
                size="medium"
                sx={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#041562",
                }}
                onClick={handleIngresarOrden}
              >
                INGRESAR
              </Button>
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Arial, sans-serif",
                  color: "#041562",
                  fontWeight: "bold",
                  fontSize: "1.3rem",
                }}
              >
                ORDENES: {totalOrdenes}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Arial, sans-serif",
                  color: "#041562",
                  fontWeight: "bold",
                  fontSize: "1.3rem",
                }}
              >
                BULTOS: {totalBultos}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} justifyContent="flex-end">
              <Button
                variant="contained"
                size="medium"
                sx={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#DA251C",
                }}
                onClick={handleActualizar}
              >
                ACTUALIZAR PENDIENTES
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: "10px", marginBottom: "10px" }}>
              <TablaManifiestosAgentes
                rows={ordenesDataTabla}
                columns={columns}
                loading={loadingCarga || loadingEliminar}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: "10px", marginBottom: "10px" }}>
              <TablaComparativa ordenesDataTablaM={ordenesDataTablaM} pendientes={ordenesPendientes} />
            </Grid>
          </Grid>
        </form>
      </Paper>
    </div>
  );
}
export default ManifiestoEntradaAgente;
