import React, { useState, memo, useEffect, useRef } from "react";
import moment from "moment";
import { Grid, Button, Card, CardContent, Autocomplete, TextField, Tooltip, Paper } from "@mui/material";
import Tabla from "../../components/DespachoComponentes/componentesRetiros/tabla";
import * as XLSX from "xlsx";
import Papa from "papaparse";
import { createOrderAPI, obtenerClientesRetiro, CentroCostoClientes } from "../../services/RetirosService";
import {
  toastDatosEnBlanco,
  ordenNoCreada,
  alertaComunasOrigenToast,
  alertaComunasDestinoToast,
  excelCargado,
  excelVacio,
  sinCliente,
} from "../../components/DespachoComponentes/componentesRetiros/Alertas";
import { ExcelDownloadLinkInter } from "../../components/DespachoComponentes/componentesRetiros/DescargaExcel";
import Swal from "sweetalert2";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  ValidadorArchivoAdmin,
  limiteDeFilas,
  ValidarHomologacionCiudades,
  LinearProgressOverlay,
} from "../../components/DespachoComponentes/componentesRetiros/Funciones";
import "../../components/assets/retiros.css";
import { useSelector } from "react-redux";

const RetirosAppInter = () => {
  const userInfo = useSelector((state) => state.user.userInfo);
  const id_usuario = userInfo ? userInfo.id : null;

  const [key, setKey] = useState(Date.now());
  const fileInputRef = useRef(null);
  const [data, setData] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [options, setOptions] = useState([]);
  const [centroCostoOptios, setCentroCostoOptios] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [progress, setProgress] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const [isAutocompleteEnabledCli, setIsAutocompleteEnabledCli] = useState(false);
  const [isAutocompleteEnabledCc, setIsAutocompleteEnabledCc] = useState(false);
  const [isAutocompleteEnabledFh, setIsAutocompleteEnabledFh] = useState(false);
  const [selectedValueCentroCosto, setSelectedValueCentroCosto] = useState("");
  const referenciasGeneradas = new Set();
  const [completed, setCompleted] = useState(false);
  const [isbtnEnviar, setIsbtnEnviar] = useState(true);
  const [fechaRetiros, setFechaRetiros] = useState();
  const [jsonDataArray, setJsonDataArray] = useState([]);
  const [codigoCliente, setCodigoCliente] = useState("");
  const [odCount, setOdCount] = useState(0);

  const [fechaFormateada, setFechaFormateada] = useState("");

  //Obtenemos los centros de costo del cliente
  const obtenerCentroCostoCliente = async (Idc) => {
    try {
      const dataAll = await CentroCostoClientes(Idc);

      const cCostoOptions = dataAll.map((canal) => ({
        value: canal.CENTRO_COSTO,
        label: canal.CENTRO_COSTO,
      }));
      setCentroCostoOptios(cCostoOptions);
    } catch (e) {}
  };

  // La cabecera predefinida, excel debe terner los mismo datos.
  const predefinedHeaders = [
    "NOMBRE ORIGEN",
    "DIRECCION ORIGEN",
    "TELEFONO ORIGEN",
    "COMUNA ORIGEN",
    "CENTRO DE COSTO",
    "DESCRIPCION",
    "CONTACTO ORIGEN",
    "BULTOS",
    "KILOS",
    "GUIA",
    "NOMBRE DESTINO",
    "DIRECCION DESTINO",
    "COMUNA DESTINO",
    "CONTACTO DESTINO",
    "TELEFONO DESTINO",
    "ALTO",
    "LARGO",
    "ANCHO",
    "CORREO",
    "VOLUMEN",
  ];

  // Las columnas obligatorias segun cabecera
  const obligatoriasHeaders = [
    "NOMBRE ORIGEN",
    "DIRECCION ORIGEN",
    "COMUNA ORIGEN",
    "NOMBRE DESTINO",
    "DIRECCION DESTINO",
    "COMUNA DESTINO",
  ];

  //Funcion para generar id referencia
  function generadorReferencia() {
    let referencia;
    let date;
    let TEXT;

    do {
      date = new Date();
      TEXT =
        date.getFullYear() +
        "" +
        (date.getMonth() + 1) +
        "" +
        date.getDate() +
        "" +
        date.getHours() +
        "" +
        date.getMinutes() +
        "" +
        date.getSeconds() +
        "" +
        date.getMilliseconds();

      referencia = TEXT + id_usuario + Math.floor(Math.random() * 1000000);
    } while (referenciasGeneradas.has(referencia));

    referenciasGeneradas.add(referencia);
    return referencia;
  }

  const handleChange = (event, newValue) => {
    try {
      setSelectedValueCentroCosto(newValue.label);
    } catch {}
  };

  //Control Autocomplete Clientes
  const handleInputCliente = async (event, newValue) => {
    try {
      setCodigoCliente(newValue.value);
      const codi = newValue.value;
      if (codi !== null) {
        try {
          obtenerCentroCostoCliente(codi);
          setSelectedOption(newValue);
        } catch {}
      } else {
      }
    } catch (error) {}
  };

  const handleInputChangeFecha = (e) => {
    setFechaRetiros(e.target.value);
  };

  //Funcion validar que la cabecera del excel sea la misma del codigo
  const validateHeaders = (parsedData, predefinedHeaders) => {
    const fileHeaders = parsedData.data[0];
    //-------------------------------------------------------------------
    console.log("Encabezados del archivo CSV:", fileHeaders);
    console.log("Encabezados predefinidos:", predefinedHeaders);
    //-------------------------------------------------------------------
    for (let i = 0; i < predefinedHeaders.length; i++) {
      if (fileHeaders[i] !== predefinedHeaders[i]) {
        return false;
      }
    }
    return true;
  };

  //Obtenemos Clientes
  const ObtenerClientes = async () => {
    try {
      const dataAll = await obtenerClientesRetiro();

      const clientOptions = dataAll.map((cliente) => ({
        value: cliente.ID,
        label: cliente.NOMBRE,
      }));
      setOptions(clientOptions);
    } catch (error) {
      console.error("Error fetching dataAll:", error);
      toast.error("Error obteniendo datos. Por favor, inténtalo de nuevo.");
    }
  };

  useEffect(() => {
    ObtenerClientes();
    const fechaActual = new Date();
    fechaActual.setDate(fechaActual.getDate() + 1);
    const formattedFechaManana = fechaActual.toISOString().split("T")[0];
    setFechaFormateada(fechaActual.toISOString().split("T")[0]);
    setFechaRetiros(formattedFechaManana);
  }, []);

  //Cargamos el excel y se procede avalidar
  const cargaExcelTabla = async (e) => {
    if (!codigoCliente) {
      sinCliente();
      fileInputRef.current.value = "";
      return;
    }

    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsBinaryString(file);

    await new Promise((resolve) => {
      reader.onload = async (event) => {
        const workbook = XLSX.read(event.target.result, { type: "binary" });
        const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
        const csvData = XLSX.utils.sheet_to_csv(firstSheet);

        Papa.parse(csvData, {
          complete: async (parsedData) => {
            const headersMatch = validateHeaders(parsedData, predefinedHeaders);
            if (ValidadorArchivoAdmin(headersMatch, codigoCliente, fechaRetiros, codigoCliente)) {
              Papa.parse(csvData, {
                complete: async (parsedData) => {
                  const newHeaders = predefinedHeaders;
                  setHeaders(newHeaders);

                  const dataSinObjetosVacios = parsedData.data
                    .slice(0)
                    .map((row) => {
                      let obj = {};
                      newHeaders.forEach((header, index) => {
                        obj[header] = row[header];
                      });
                      return obj;
                    })
                    .filter((obj) => {
                      for (const key in obj) {
                        if (obj[key] !== "") {
                          return true;
                        }
                      }
                      return false;
                    });

                  setData(dataSinObjetosVacios);

                  parsedData.data.slice(0).forEach((row, index) => {
                    const rowData = {
                      NOMBRE: row["NOMBRE DESTINO"],
                      DIRECCION: row["DIRECCION DESTINO"],
                      TELEFONO: row["TELEFONO DESTINO"],
                      CONTACTO_DESTINO: row["CONTACTO DESTINO"],
                      COMUNA: row["COMUNA DESTINO"],
                      NOTA: row["DESCRIPCION"],
                      CENTRO_COSTO: row["CENTRO DE COSTO"] || selectedValueCentroCosto,
                      CANAL: row["CENTRO DE COSTO"] || selectedValueCentroCosto,
                      TIPO_NEGOCIO: row["CENTRO DE COSTO"] || selectedValueCentroCosto,
                      BULTOS: row["BULTOS"],
                      PESO: row["KILOS"],
                      ALTO: row["ALTO"],
                      LARGO: row["LARGO"],
                      ANCHO: row["ANCHO"],
                      PESO_VOLUMEN: row["VOLUMEN"],
                      TIPO_ORDEN: "RETIRO",
                      GUIA: row["GUIA"],
                      COD_CLIENTE: codigoCliente,
                      MAIL: row["CORREO"],
                      FECHA_DIGITACION: fechaRetiros,
                      ID_REFERENCIA: codigoCliente === 749 ? row["GUIA"] : generadorReferencia(),
                      USUARIO: id_usuario,
                      NOMBRE_RETIRO: row["NOMBRE ORIGEN"],
                      DIRECCION_RETIRO: row["DIRECCION ORIGEN"],
                      CONTACTO: row["CONTACTO ORIGEN"],
                      COMUNA_ORIGEN: row["COMUNA ORIGEN"],
                      TELEFONO_ORIGEN: row["TELEFONO ORIGEN"],
                      ORIGEN_ORDEN: "PORTAL INGRESO RETIROS",
                    };

                    if (limiteDeFilas(row)) {
                      jsonDataArray.push(JSON.stringify(rowData));
                    }
                  });

                  for (let rowIndex = 0; rowIndex < parsedData.data.length; rowIndex++) {
                    const row = parsedData.data[rowIndex];
                    if (limiteDeFilas(row)) {
                      const columnasFaltantes = obligatoriasHeaders.filter((columna) => !row[columna]);
                      if (columnasFaltantes.length > 0) {
                        toastDatosEnBlanco(columnasFaltantes.join(", "), rowIndex + 2);
                        setIsbtnEnviar(false);
                        e.target.value = "";
                        setData([]);
                        setJsonDataArray([]);
                        return;
                      }
                    }
                  }
                  const guiaFormatErrors = [];
                  parsedData.data.forEach((row, index) => {
                    const guias = row["GUIA"];
                    if (guias) {
                      const guiaParts = guias.split(" - ");
                      const isValidFormat =
                        guiaParts.every((part) => part.trim().length > 0) &&
                        guiaParts.length >= 1 &&
                        guiaParts.length <= 60 &&
                        guias.match(/^([^ -]+( - [^ -]+)*)$/);
                      if (!isValidFormat) {
                        guiaFormatErrors.push(index + 2);
                      }
                    }
                  });

                  if (guiaFormatErrors.length > 0) {
                    const errorMessage = `Filas con formato incorrecto en columna GUIA: la Fila ${guiaFormatErrors.join(
                      ", ",
                    )} No tiene el formato correcto`;
                    toast.error(errorMessage);
                    setIsbtnEnviar(false);
                    e.target.value = "";
                    setData([]);
                    setJsonDataArray([]);
                    return;
                  }

                  try {
                    const comunasInvalidasOrigen = [];
                    const comunasInvalidasDestino = [];

                    const totalFilas = jsonDataArray.length;
                    let filaExcel = 0;
                    setIsVisible(true);

                    await Promise.all(
                      jsonDataArray.map(async (rowData) => {
                        const parsedRow = JSON.parse(rowData);
                        await ValidarHomologacionCiudades(parsedRow.COMUNA_ORIGEN, comunasInvalidasOrigen);
                        await ValidarHomologacionCiudades(parsedRow.COMUNA, comunasInvalidasDestino);
                        filaExcel++;
                        const newProgress = (filaExcel / totalFilas) * 100;
                        setProgress(newProgress);
                      }),
                    );

                    if (comunasInvalidasOrigen.length > 0) {
                      const newProgress = (filaExcel / totalFilas) * 100;
                      setProgress(newProgress);
                      if (newProgress === 100) {
                        setCompleted(true);
                      }
                      alertaComunasOrigenToast(comunasInvalidasOrigen);
                      setData([]);
                      setJsonDataArray([]);
                      setKey(Date.now());
                      e.target.value = "";
                    } else if (comunasInvalidasDestino.length > 0) {
                      const newProgress = (filaExcel / totalFilas) * 100;
                      setProgress(newProgress);
                      if (newProgress === 100) {
                        setCompleted(true);
                      }
                      alertaComunasDestinoToast(comunasInvalidasDestino);
                      setData([]);
                      setJsonDataArray([]);
                      setKey(Date.now());
                      e.target.value = "";
                    } else if (jsonDataArray.length === 0) {
                      excelVacio();
                      fileInputRef.current.value = "";
                    } else {
                      setJsonDataArray(jsonDataArray);
                      const odCount = parsedData.data.filter((row) => row).length;
                      setOdCount(odCount);
                      setIsAutocompleteEnabledCli(true);
                      setIsAutocompleteEnabledCc(true);
                      setIsAutocompleteEnabledFh(true);
                      setIsbtnEnviar(false);
                      excelCargado();
                    }
                  } catch (error) {
                    console.error("Error en el procesamiento de datos:", error);
                  }
                  setProgress(100);
                  setIsVisible(false);
                  setProgress(0);
                  setCompleted(false);
                },
                header: true,
              });
            } else {
              setIsbtnEnviar(true);
              e.target.value = "";
              setKey(Date.now());
              fileInputRef.current.value = "";
              setData([]);
              setJsonDataArray([]);
            }
          },
        });
      };
    });
  };

  const LimpiaArray = (e) => {
    setJsonDataArray([]);
  };

  const tooltipMessage =
    "Si se selecciona un Centro de Costo, este será automáticamente agregado a las filas vacías y se reflejará en el archivo final.";

  const tooltipMessageGuias =
    "Por favor, asegúrece de que el archivo Excel que suba cumpla con el formato en la columna GUIA siguiendo el patrón: N°guía - N°guía (si hay más de una).";

  /* funcion para restablecer estados */
  const resetState = () => {
    setData([]);
    setOdCount(0);
    setJsonDataArray([]);
    setSelectedOption("");
    setSelectedValueCentroCosto("");
    setIsAutocompleteEnabledCli(false);
    setIsAutocompleteEnabledCc(false);
    setIsAutocompleteEnabledFh(false);
    setIsVisible(false);
    setProgress(0);
    setCompleted(false);
    setIsbtnEnviar(true);
    fileInputRef.current.value = "";
  };

  //Creacion de ordenes
  const crearOrdenes = async () => {
    const totalOrders = jsonDataArray.length;
    let createdOrdersCount = 0;

    const createdOrders = [];

    for (const jsonData of jsonDataArray) {
      try {
        setIsVisible(true);
        const response = await createOrderAPI(jsonData);

        createdOrdersCount++;
        const newProgress = (createdOrdersCount / totalOrders) * 100;
        setProgress(newProgress);
        if (response && response.success) {
          createdOrders.push(response);
          const newProgress = (createdOrdersCount / totalOrders) * 100;
          setProgress(newProgress);
          if (newProgress === 100) {
            setCompleted(true);
          }
        }
      } catch (error) {
        toast.error("Error al crear la orden. Por favor, inténtalo de nuevo.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    }
    setProgress(100);

    if (createdOrders.length > 0) {
      Swal.fire({
        title: "Retiros creados con éxito",
        text: "Todas las órdenes se han creado exitosamente.",
        icon: "success",
        confirmButtonText: "Cerrar",
      });
      setIsVisible(false);
      setProgress(0);
      setCompleted(false);

      createdOrders.forEach((order, index) => {
        const rowIndex = index + 1;

        if (rowIndex <= data.length) {
          data[rowIndex - 1]["ORDEN D."] = order.od;
        }
      });

      const selectedCc = selectedValueCentroCosto;
      const updatedData = data.map((row) => ({
        ...row,
        "CENTRO DE COSTO": row["CENTRO DE COSTO"] || selectedCc,
      }));

      const filteredData = updatedData.filter((row) => limiteDeFilas(row, "CENTRO DE COSTO"));
      setData(filteredData);

      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(updatedData, {
        header: headers,
      });

      const fechaActual = moment().format("YYYY-MM-DD");

      const nombreArchivo = `Retiros_${fechaActual}.xlsx`;

      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");

      XLSX.writeFile(workbook, nombreArchivo);

      setTimeout(() => {
        setKey(Date.now());
        fileInputRef.current.value = "";

        setData([]);
        setJsonDataArray([]);
        setFechaRetiros(fechaFormateada);
        resetState();
      }, 2000);
    } else {
      ordenNoCreada();
    }
  };

  return (
    <div>
      <Paper style={{ padding: "10px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Autocomplete
              disablePortal
              id="combo-box-demo1"
              size="small"
              onChange={handleInputCliente}
              options={options}
              value={selectedOption}
              renderInput={(params) => <TextField {...params} label="CLIENTE" />}
              disabled={isAutocompleteEnabledCli}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Tooltip title={tooltipMessage} arrow placement="left">
              <Autocomplete
                disablePortal
                size="small"
                id="combo-box-demo2"
                onChange={handleChange}
                value={selectedValueCentroCosto}
                options={centroCostoOptios}
                renderInput={(params) => <TextField {...params} label="CENTRO COSTO" />}
                disabled={isAutocompleteEnabledCc}
              />
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              type="date"
              size="small"
              label="Fecha"
              value={fechaRetiros}
              onChange={handleInputChangeFecha}
              InputLabelProps={{
                shrink: true,
              }}
              disabled={isAutocompleteEnabledFh}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} marginTop={"5px"} alignItems="flex-end">
          <Grid item xs={12} sm={4}>
            <Tooltip title={tooltipMessageGuias} arrow placement="bottom">
              <div className="same-size-container">
                <Form.Group controlId="formFile">
                  <Form.Label className="tu-archivo-de-estilos1">Seleccione Archivo Excel</Form.Label>
                  <Form.Control
                    type="file"
                    ref={fileInputRef}
                    key={key}
                    onChange={cargaExcelTabla}
                    onClick={LimpiaArray}
                    size="sm"
                  />
                </Form.Group>
              </div>
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button
              style={{
                backgroundColor: isbtnEnviar ? "#BDBDBD" : "#041562",
                color: "#FFFFFF",
              }}
              className="boton1"
              size="large"
              variant="contained"
              color="primary"
              onClick={crearOrdenes}
              disabled={isbtnEnviar}
            >
              Enviar
            </Button>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button
              style={{ backgroundColor: "#DA251C" }}
              className="boton1"
              size="large"
              variant="contained"
              color="primary"
              onClick={resetState}
            >
              Cancelar
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <h3>Órdenes Cargadas: {odCount}</h3>
        </Grid>
        <div className="centered-link">
          <ExcelDownloadLinkInter />
        </div>

        <div>
          <LinearProgressOverlay isVisible={isVisible} progress={progress} completed={completed} disabled={false} />
        </div>

        {data.length > 0 && (
          <Grid item xs={10} sm={10} md={6} style={{ width: "100%", maxWidth: "100%" }}>
            <Card elevation={10} className="cardContentWithMargin">
              <CardContent
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  Width: "100%",
                  maxHeight: "75vh",
                  overflowX: "auto",
                  overflowY: "auto",
                }}
              >
                <Tabla
                  data={data}
                  headers={headers}
                  onCpdateellU={(rowIndex, header, newValue) => {
                    const newData = [...data];
                    newData[rowIndex][header] = newValue;
                    setData(newData);
                  }}
                />
              </CardContent>
            </Card>
          </Grid>
        )}
      </Paper>
    </div>
  );
};

export default memo(RetirosAppInter);
