import React, { useState } from "react";
import { Paper, Grid, Button } from "@mui/material";
import { getOrdenesPendientesRetornoClientes } from "../../../services/PendientesEntradaSalida";
import TablaManifiestos from "../componentesManifiestos/TablaManifiestos";
import { toast } from "react-toastify";

const PendientesTotalRetornoClientes = () => {
  const [dataOrdenes, setDataOrdenes] = useState([]);
  const [loading, setLoading] = React.useState(false);

  const columns = [
    { field: "OD", headerName: "OD", width: 100 },
    { field: "CLIENTES_OD", headerName: "CLIENTE", width: 400 },
    { field: "ORIGEN", headerName: "ORIGEN", width: 150 },
    { field: "DESTINO", headerName: "DESTINO", width: 150 },
    {
      field: "FECHA_INGRESO",
      headerName: "FECHA INGRESO",
      width: 150,
      valueFormatter: (params) => {
        const date = new Date(params.value);
        return date.toLocaleDateString("es-ES", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        });
      },
    },
    {
      field: "TIPO",
      headerName: "TIPO",
      width: 150,
      valueGetter: (params) => {
        const PADRE_ORIGEN = params.row.PADRE_ORIGEN;
        const PADRE_DESTINO = params.row.PADRE_DESTINO;

        if (PADRE_ORIGEN !== "SCL" && PADRE_DESTINO !== "SCL") {
          return "INTER-REGION";
        } else if (PADRE_ORIGEN === "SCL" && PADRE_DESTINO !== "SCL") {
          return "DEVOLUCION";
        } else if (PADRE_ORIGEN !== "SCL" && PADRE_DESTINO === "SCL") {
          return "RETIRO";
        } else {
          return "DESCONOCIDO";
        }
      },
    },
  ];

  const consultar = async () => {
    try {
      setLoading(true);
      const OrdenesData = await getOrdenesPendientesRetornoClientes();
      if (OrdenesData.length > 0) {
        console.log(OrdenesData);
        setDataOrdenes(OrdenesData);
      } else {
        setDataOrdenes([]);
        toast.warning("No existen pendientes");
      }
    } catch (error) {
      console.error("Error al consultar datos:", error);
      toast.error("Ocurrió un error al consultar los datos");
    } finally {
      setLoading(false);
    }
  };

  const cancelar = async () => {
    reset();
  };

  const reset = () => {
    setDataOrdenes([]);
  };

  return (
    <div>
      <Paper style={{ padding: "10px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3} md={3} lg={3}>
            <Button
              style={{ backgroundColor: "#041562", color: "#FFFFFF", width: "100%", height: "100%" }}
              className="boton1"
              size="medium"
              variant="contained"
              color="primary"
              onClick={consultar}
            >
              CONSULTAR
            </Button>
          </Grid>
          <Grid item xs={12} sm={3} md={3} lg={3}>
            <Button
              style={{ backgroundColor: "#DA251C", width: "100%", height: "100%" }}
              className="boton1"
              size="medium"
              variant="contained"
              onClick={cancelar}
            >
              CANCELAR
            </Button>
          </Grid>
        </Grid>
        <Grid>
          <Grid style={{ marginTop: "10px" }}>
            <TablaManifiestos rows={dataOrdenes} columns={columns} loading={loading} />
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};
export default PendientesTotalRetornoClientes;
