import axios from "axios";
const BASE_API_URL = process.env.REACT_APP_BACKEND_URL;

export const buscarAgentes = async (IATA) => {
    try {
      const response = await axios.post(`${BASE_API_URL}manifiestos/getAgentes`, {
        IATA,
      });
      if (response.data && response.data.length > 0) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      console.error("Error obteniendo datos Agentes desde API.js", error);
      throw error;
    }
  };

  export const getOrdenesManifiestosRutaAgente = async (data) => {
    try {
      const response = await axios.post(`${BASE_API_URL}manifiestos/getOrdenesManifiestosRutaAgente`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
  
      return response.data;
    } catch (error) {
      console.error("Error Insert Picking:", error);
      throw error;
    }
  };

  