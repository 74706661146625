import React, { memo, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { obtenerAgentes } from "../../services/PortalAgentesService";
import TableListadoAgentes from "../../components/DespachoComponentes/componentesListadoAgentes/tableListadoAgentes";
import "react-toastify/dist/ReactToastify.css";

const ListadoAgentes = () => {
  // eslint-disable-next-line no-unused-vars
  const userInfo = useSelector((state) => state.user.userInfo);
  const [agentes, setAgentes] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const cargarAgentes = async () => {
      setLoading(true);
      try {
        const data = await obtenerAgentes();
        setAgentes(data);
      } catch (error) {
        console.error("Error al obtener los agentes:", error);
      } finally {
        setLoading(false);
      }
    };
    cargarAgentes();
  }, []);

  const columns = [
    { field: "ID", headerName: "ID", width: 60 },
    { field: "NOMBRE_AGENTE", headerName: "Nombre del agente", width: 180 },
    { field: "IATA", headerName: "Iata", width: 100 },
    { field: "IATA_PADRE", headerName: "Iata padre", width: 100 },
    { field: "NEGOCIO", headerName: "Negocio", width: 130 },
    { field: "TIPO_NEGOCIO", headerName: "Tipo de negocio", width: 130 },
    { field: "CANAL", headerName: "Canal", width: 120 },
    { field: "COD_CLIENTE", headerName: "Cod del cliente", width: 140 },
    {
      field: "FH_SYS",
      headerName: "Fecha del sistema",
      width: 200,
      renderCell: (params) => formatDate(params.value),
    },
  ];

  /* formatear fecha de tabla */
  const formatDate = (isoString) => {
    const date = new Date(isoString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  };

  return (
    <div>
      <TableListadoAgentes columns={columns} rows={agentes} loading={loading} />
    </div>
  );
};

export default memo(ListadoAgentes);
