import React, { useState } from "react";
import { Paper, Grid, Button, List, Typography, ListItemText, ListItem, IconButton } from "@mui/material";
import CustomTextField from "../../../utils/CustomTextField";
import { getManifiestosPorRango } from "../../../services/ManifiestosServices";
import { useSelector } from "react-redux";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";
import { getManifiestosProceso } from "../../../services/ManifiestosServices";
import { format } from "date-fns";
import DescriptionIcon from "@mui/icons-material/Description";

const DescargaManifiestosRango = () => {
  const userInfo = useSelector((state) => state.user.userInfo);
  const cod_cliente = userInfo ? userInfo.cod_cliente : null;
  const tipo_usuario = userInfo ? userInfo.tipo_usuario : null;
  const [fechaInicio, setFechaInicio] = useState("");
  const [fechaTermino, setFechaTermino] = useState("");
  const [manifiestos, setManifiestos] = useState([]);

  const handleChangeFechaInicio = async (e) => {
    setFechaInicio(e.target.value);
  };

  const handleChangeFechaTermino = async (e) => {
    setFechaTermino(e.target.value);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    try {
      setManifiestos([]);

      let proceso = "RETORNOCLIENTES";

      const datos = {
        PROCESO: proceso,
        FECHA_INICIO: fechaInicio,
        FECHA_TERMINO: fechaTermino,
        ORIGEN: "SCL",
      };
      if (tipo_usuario === "CLIENTE") {
        datos.ID_CLIENTE = parseInt(cod_cliente);
      }

      const response = await getManifiestosProceso(datos);

      const codClienteNumber = parseInt(userInfo.cod_cliente, 10);

      const manifiestosFiltrados =
        tipo_usuario === "CLIENTE"
          ? response.filter((manifiesto) => manifiesto.ID_CLIENTE === codClienteNumber)
          : response;

      if (manifiestosFiltrados.length > 0) {
        toast.success("Datos cargados con éxito!");
        setManifiestos(manifiestosFiltrados);
      } else {
        console.log("No se encontraron detalles de manifiestos.");
        toast.warning("No se encontraron detalles de manifiestos.");
      }
    } catch (error) {
      console.error("Error al obtener detalles de la orden:", error);
      toast.error("Error al obtener detalles de la orden.");
    }
  };

  const handleBuscarRango = async (e) => {
    downloadExcel();
  };

  const downloadExcel = async () => {
    const datos = {
      PROCESO: "INHOUSE",
      FECHAINICIO: fechaInicio,
      FECHATERMINO: fechaTermino,
      ID_CLIENTE: parseInt(cod_cliente),
    };
    const ordenesDataTabla = await getManifiestosPorRango(datos);
    if (ordenesDataTabla.length > 0) {
      const filteredData = ordenesDataTabla.map((item) => ({
        OD: item.OD,
        Fecha: new Date(item.FH_SYS).toLocaleDateString("es-ES", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        }),
        Observacion: item.NOTA,
        Destino: item.NOMBRE,
        Dirección: item.DIRECCION,
        "Comuna Destino": item.COMUNA_DESTINO,
        Via: item.VIA,
        Bultos: item.BULTOS,
        Kilos: item.PESO,
        Fecha_Manifiesto: new Date(item.FH_CREACION).toLocaleDateString("es-ES", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        }),
        Id_Manifiesto: item.ID,
      }));

      const worksheet = XLSX.utils.json_to_sheet(filteredData);

      worksheet["!cols"] = [
        { wch: 10 },
        { wch: 15 },
        { wch: 40 },
        { wch: 40 },
        { wch: 40 },
        { wch: 25 },
        { wch: 15 },
        { wch: 10 },
        { wch: 10 },
        { wch: 15 },
      ];
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "HOJA1");

      XLSX.writeFile(workbook, `MANIFIESTO POR RANGO.xlsx`);
    } else {
      toast.error("Rango especificado no tiene datos.");
    }
  };

  return (
    <Paper style={{ padding: "10px", marginBottom: "10px", marginTop: "20px" }}>
      <Typography variant="h6" align="center" gutterBottom>
        {cod_cliente === "549" ? "Descarga por Rango de Fecha" : "Busqueda por Rango de Fecha de Retornos"}
      </Typography>
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        <Grid item xs={12} sm={4} md={4} lg={4}>
          <CustomTextField
            fullWidth
            type="date"
            size="small"
            label="Fecha Inicio"
            value={fechaInicio}
            onChange={handleChangeFechaInicio}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4}>
          <CustomTextField
            fullWidth
            type="date"
            size="small"
            label="Fecha Termino"
            value={fechaTermino}
            onChange={handleChangeFechaTermino}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4}>
          <Button
            onClick={cod_cliente === "549" ? handleBuscarRango : handleFormSubmit}
            fullWidth={true}
            sx={{
              backgroundColor: "#041562",
              color: "#FFFFFF",
              "&:hover": {
                backgroundColor: "#031042",
              },
            }}
          >
            {cod_cliente === "549" ? "Descargar" : "Buscar"}
          </Button>
        </Grid>
      </Grid>
      <div style={{ maxHeight: "650px", overflowY: "auto" }}>
        <List style={{ display: "flex", flexDirection: "column", padding: 0 }}>
          {manifiestos.map((manifiesto) => (
            <ListItem key={manifiesto.ID}>
              <div style={{ position: "relative", display: "inline-block" }}>
                <IconButton
                  onClick={() => {
                    const url =
                      cod_cliente === "632"
                        ? `https://backend.cargoexteam.cl/medicion/creacionManifiestoPDF/${
                            manifiesto.ID
                          }?useCod_Barra=${true}`
                        : `https://backend.cargoexteam.cl/medicion/creacionManifiestoPDFBarCode/${
                            manifiesto.ID
                          }?useBarcode=${false}`;

                    window.open(url, "_blank");
                  }}
                >
                  <DescriptionIcon style={{ color: "#DA251C" }} />
                </IconButton>
                <span
                  style={{
                    position: "absolute",
                    top: "0px",
                    right: "0px",
                    fontSize: "10px",
                    color: "#DA251C",
                  }}
                >
                  PDF
                </span>
              </div>

              <ListItemText
                primary={`Manifiesto: ${manifiesto.ID}  / Creación: ${format(
                  new Date(manifiesto.FH_CREACION),
                  "dd-MM-yyyy"
                )}`}
                secondary={
                  manifiesto.PROCESO === "RETORNOCLIENTES"
                    ? `CLIENTE: ${manifiesto.NOMBRE_CLIENTE} / CHOFER: ${manifiesto.CHOFER}`
                    : null
                }
                style={{ marginLeft: "10px" }}
              />
            </ListItem>
          ))}
        </List>
      </div>
    </Paper>
  );
};

export default DescargaManifiestosRango;
