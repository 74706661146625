import Swal from "sweetalert2";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export function toastCabecera() {
  toast.error("Error en cabecera. Por favor, especificar cabecera valida." );
}

export function toastDatosEnBlanco(COLUMNA, FILA) {
  toast.error(
    `Error en archivo. En la columna ${COLUMNA}, la fila ${FILA} esta vacía` );
}

export function toastFormatoGuia() {
  toast.error(
    `Error en archivo. El formato de guias esta erroneo.` );
}

export function toastDatosEnBlancoCentroCosto(COLUMNA, FILA) {
  toast.error(
    `Error Centro Costo. En la columna ${COLUMNA}, la fila ${FILA} esta vacía` );
}

export function sinCliente() {
  toast.error("Faltan datos. Por favor, ingrese un cliente." );
}

export function sinFecha() {
  toast.error("Faltan datos. Por favor, ingrese una fecha." );
}

export function alerta1() {
  Swal.fire({
    icon: "error",
    title: "Cabecera no corresponde",
    text: "Especificar cabecera valido",
  });
}
export function alerta2(COLUMNA, FILA) {
  Swal.fire({
    icon: "error",
    title: "FALTAN DATOS",
    text: `EN LA COLUMNA ${COLUMNA}, LA FILA ${FILA} ESTÁ VACÍA`,
  });
}

export function alerta3() {
  Swal.fire({
    icon: "error",
    title: "FALTAN DATOS",
    text: `INGRESE CLIENTE`,
  });
}

export function alerta4() {
  Swal.fire({
    icon: "error",
    title: "FALTAN DATOS",
    text: `INGRESE FECHA VALIDA`,
  });
}

export function alerta5() {
  Swal.fire({
    icon: "error",
    title: "ERRONEOS",
    text: `FECHA NO PUEDE SER MENOR A LA ACTUAL`,
  });
}

export function alerta6([COMUNA]) {
  Swal.fire({
    icon: "error",
    title: "DATOS ERRONEOS",
    text: `LA COMUNA ORIGEN: ${[
      COMUNA,
    ]} NO EXISTE, FAVOR DE REVISAR EL ARCHIVO O COMUNICARSE CON TRANS. CARGOEX.`,
  });
}

export function alertaComunasOrigen(comunas) {
  Swal.fire({
    icon: "error",
    title: "DATOS ERRONEOS",
    html: `Las comunas ingresadas no existen: <br>${comunas.join(
      "<br>"
    )}.<br>Favor de revisar el archivo o comunicarse con Trans. Cargoex.`,
  });
}

export function alerta7(COMUNA) {
  Swal.fire({
    icon: "error",
    title: "DATOS ERRONEOS",
    text: `LA COMUNA DESTINO: ${COMUNA} NO EXISTE, FAVOR DE REVISAR EL ARCHIVO O COMUNICARSE CON TRANS. CARGOEX.`,
  });
}

export function alerta8() {
  Swal.fire({
    icon: "error",
    title: "DATOS ERRONEOS",
    text: `FECHA NO PUEDE SER LA MISMA DEL DÍA ACTUAL`,
  });
}
export function toastCreacionPromesa(totalOrders,setProgress) {
  const createRetiros = (createdOrdersCount) => {
    return new Promise((resolve) => {
      const newProgress = (createdOrdersCount / totalOrders) * 100;
      setProgress(newProgress);

      if (createdOrdersCount === totalOrders) {
        resolve();
      }
    });
  };

  toast.promise(createRetiros, {
    position: toast.POSITION.BOTTOM_RIGHT,
    pending: "Creando Retiros",
    success: "Retiros Creados 👌",
    error: "Error en la creación 🤯",
  });
}

export function toastCreacionPromesa2(ter) {
  const resolveAfter3Sec = new Promise((pro) => setTimeout(pro, ter));

  toast.promise(resolveAfter3Sec, {
    position: toast.POSITION.BOTTOM_RIGHT,
    pending: "Creando Retiros",
    success: "Retiros Creados 👌",
    error: "Error en la creacion 🤯",
  });
} 

export function fechaActual() {
  toast.error(
    "Error de datos. Por favor, fecha no puede ser igual a la actual." );
}

export function alertaComunasOrigenToast(comunas) {
  toast.error(
    `Las comunas de origen no existen:\n${comunas.join(
      "\n- "
    )}\n.Favor de revisar el archivo o comunicarse con Trans. Cargoex.`
  );
}
export function alertaComunasDestinoToast(comunas) {
  toast.error(
    `Las comunas de destino no existen:\n${comunas.join(
      "\n-"
    )}\n.Favor de revisar el archivo o comunicarse con Trans. Cargoex.`
  );
}

export function ordenCreada() {
  toast.success("Retiros creadas con éxito. Retiros creados exitosamente.", {
    position: toast.POSITION.BOTTOM_RIGHT,
  });
}

export function excelCargado() {
  toast.success("Excel cargado", {
    position: toast.POSITION.BOTTOM_RIGHT,
  });
}

export function ordenNoCreada() {
  toast.error(
    "Error de creacion. Verificar datos para la creacion de retiros" );
}

export function excelVacio() {
  toast.error(
    "Error, Excel esta vacio");
}


export function fechaMenor() {
  toast.error(
    "Error de datos. Por favor, fecha no puede ser menor a la actual." );
}
