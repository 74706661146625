const defaultHeaders = [
    "CLIENTE",
    "ORDEN",
    "ORDEN PAPEL",
    "DOCUMENTO",
    "CODIGO DE BARRA",
    "EMBALAJE",
    "DISPOSITIVO",
    "FECHA DIGITACION",
    "NOTA",
    "BULTOS",
    "PESO",
    "ALTO",
    "LARGO",
    "ANCHO",
    "PESO VOLUMEN",
    "ESTADO ACTUAL",
    "ESTADO ACTUAL DETALLE",
    "DESTINATARIO",
    "DIRECCION",
    "TIPO SERVICIO DESPACHO / RETIRO",
    "COMUNA ORIGEN",
    "IATA PADRE ORIGEN",
    "COMUNA DESTINO",
    "IATA PADRE DESTINO",
    "TIPO DE CARGA",
    "VIA SERVICIO",
    "CENTRO DE COSTO",
    "TIPO DE NEGOCIO",
    "CANAL",
    "NOMBRE RETIRO",
    "DIRECCION RETIRO",
    "CONTACTO RETIRO",
    "TELEFONO RETIRO",
    "TIEMPO TRANSITO",
    "SLA GESTION",
    /* "SLA GESTION DETALLE", */
    "KPI",
    /* "KPI DETALLE", */
    "NOMBRE RECEPTOR",
    "RUT RECEPTOR",
    "FECHA RECEPCION",
    "HORA RECEPCION",
    "RECEPTOR FOTO",
    "PRIMER EVENTO",
    "PRIMER EVENTO CHOFER",
    "PRIMER EVENTO FECHA",
    "PRIMER EVENTO HORA",
    "PRIMER EVENTO NOMBRE",
    "PRIMER EVENTO RUT",
    "PRIMER EVENTO NOTA",
    "PRIMER EVENTO FOTO",
    "SEGUNDO EVENTO",
    "SEGUNDO EVENTO CHOFER",
    "SEGUNDO EVENTO FECHA",
    "SEGUNDO EVENTO HORA",
    "SEGUNDO EVENTO NOMBRE",
    "SEGUNDO EVENTO RUT",
    "SEGUNDO EVENTO NOTA",
    "SEGUNDO EVENTO FOTO",
    "TERCER EVENTO",
    "TERCER EVENTO CHOFER",
    "TERCER EVENTO FECHA",
    "TERCER EVENTO HORA",
    "TERCER EVENTO NOMBRE",
    "TERCER EVENTO RUT",
    "TERCER EVENTO NOTA",
    "TERCER EVENTO FOTO",
    "CUARTO EVENTO",
    "CUARTO EVENTO CHOFER",
    "CUARTO EVENTO FECHA",
    "CUARTO EVENTO HORA",
    "CUARTO EVENTO NOMBRE",
    "CUARTO EVENTO RUT",
    "CUARTO EVENTO NOTA",
    "CUARTO EVENTO FOTO",
    "CONTACTO",
    "TELEFONO",
    "FECHA IMPRESIÓN ETIQUETA",
    "AGENTE ASIGNADO",
    "CEDIBLES",
    "INHOUSE",
    "ENTRADA ANDEN",
    "SALIDA ANDEN",
    "ENTRADA AGENTE",
    "SALIDA RUTA",
    "SALIDA AGENTE",
    "INGRESO SANTIAGO",
    "MANIFIESTO RETORNO CLIENTE",
    "MANIFIESTO APP",
    "LABORATORIO",
    "PERSONALIZADO",
    "TIPO DE NEVERA",
    "CANTIDAD DE NEVERA",
    "ZONA ALMACENAMIENTO",
  ];
  
  export default defaultHeaders;
  