import React, { useState, Fragment } from "react";
import { useSelector } from "react-redux";
import {
  TextField,
  Container,
  Grid,
  Card,
  IconButton,
  CardContent,
  InputAdornment,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
  Paper,
  Box,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { obtenerDetallesOrdenAgente } from "../../services/ReportesServices";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "@reactuiutils/horizontal-timeline/timeline.css";
import { GoogleMap, Marker } from "@react-google-maps/api";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { es } from "date-fns/locale";
import { formatInTimeZone } from "date-fns-tz";
import { parse, parseISO, compareDesc } from "date-fns";
import ImageZoom from "react-image-zooom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const ConsultaIndividualAgentes = () => {
  const userInfo = useSelector((state) => state.user.userInfo);
  const nombreAgente = userInfo ? userInfo.AGENTE_ASIGNADO : null;
  const [od, setOd] = useState("");
  const [odInfo, setOdInfo] = useState(null);
  const [certificaciones, setCertificaciones] = useState(null);
  const [activeCertification, setActiveCertification] = useState(null);
  const [selectedCertification, setSelectedCertification] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [openImageDialog, setOpenImageDialog] = useState(false);
  const [currentImage, setCurrentImage] = useState("");
  const [eventsByType, setEventsByType] = useState({});
  const [selectedManifiestoType, setSelectedManifiestoType] = useState(null);
  const [manifiestoModalOpen, setManifiestoModalOpen] = useState(false);

  const getImages = (selectedCertification) => {
    const images = [];
    const base64Prefix = "data:image/jpeg;base64,";
    [selectedCertification.FOTO1, selectedCertification.FOTO2, selectedCertification.FOTO3].forEach((img) => {
      if (img && img !== "FALSE") {
        const formattedImage = img.startsWith(base64Prefix) ? img : base64Prefix + img;
        images.push(formattedImage);
      }
    });

    return images;
  };

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", color: "red", right: "10px" }}
        onClick={onClick}
      ></div>
    );
  }

  const formatDateToSantiago = (dateString) => {
    const timeZone = "America/Santiago";
    const date = parseISO(dateString);
    return formatInTimeZone(date, timeZone, "dd-MM-yyyy, HH:mm:ss", { locale: es });
  };

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", color: "red", left: "10px", zIndex: 1 }}
        onClick={onClick}
      ></div>
    );
  }

  const procesoOrder = [
    "INHOUSE",
    "ENTRADAANDEN",
    "SALIDAANDEN",
    "ENTRADAAGENTE",
    "SALIDARUTA",
    "SALIDAAGENTE",
    "INGRESOSANTIAGO",
    "MANIFIESTOAPP",
  ];

  const procesoTitleMap = {
    INHOUSE: "MANIFIESTO INHOUSE",
    ENTRADAANDEN: "ENTRADA ANDÉN",
    SALIDAANDEN: "SALIDA ANDÉN",
    ENTRADAAGENTE: "ENTRADA AGENTE",
    SALIDARUTA: "SALIDA RUTA",
    SALIDAAGENTE: "SALIDA AGENTE",
    INGRESOSANTIAGO: "INGRESO BODEGA",
    MANIFIESTOAPP: "MANIFIESTO APP",
  };

  const prepareTimelineEvents = (manifiestoDataArray) => {
    const eventsByType = {};

    manifiestoDataArray.forEach((manifiestoData) => {
      const procesoKey = manifiestoData.PROCESO;
      const title = procesoTitleMap[procesoKey] || "MANIFIESTO DESCONOCIDO";

      if (!eventsByType[procesoKey]) {
        eventsByType[procesoKey] = {
          title: title,
          manifests: [],
        };
      }

      const event = {
        id: manifiestoData.ID,
        fecha: new Date(manifiestoData.FH_CIERRE).toLocaleString("es-CL", { timeZone: "America/Santiago" }),
        chofer: manifiestoData.NOMBRE_CHOFER,
        usuario: manifiestoData.NOMBRE_USUARIO,
      };

      eventsByType[procesoKey].manifests.push(event);
    });

    return eventsByType;
  };

  const getSliderSettings = (imageCount) => {
    return {
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      adaptiveHeight: true,
      draggable: false,
      swipe: false,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      arrows: imageCount > 1,
    };
  };

  const handleDetailsClick = (certificacion) => {
    setSelectedCertification(certificacion);
    setDialogOpen(true);
  };

  const handleCertificationClick = (certificacion) => {
    if (activeCertification && activeCertification.ID === certificacion.ID) {
      setActiveCertification(null);
    } else {
      setActiveCertification(certificacion);
    }
  };

  const isValidCoordinate = (coordinate) => {
    return coordinate && !isNaN(coordinate);
  };

  const renderGoogleMap = () => {
    if (
      selectedCertification &&
      isValidCoordinate(selectedCertification.LAT_TERRENO) &&
      isValidCoordinate(selectedCertification.LONG_TERRENO)
    ) {
      return (
        <GoogleMap
          mapContainerStyle={{ width: "100%", height: "270px" }}
          center={{
            lat: parseFloat(selectedCertification.LAT_TERRENO),
            lng: parseFloat(selectedCertification.LONG_TERRENO),
          }}
          zoom={15}
        >
          <Marker
            position={{
              lat: parseFloat(selectedCertification.LAT_TERRENO),
              lng: parseFloat(selectedCertification.LONG_TERRENO),
            }}
          />
        </GoogleMap>
      );
    } else {
      return (
        <div
          style={{
            width: "100%",
            height: "270px",
            backgroundColor: "#ccc",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="subtitle1">Ubicación no disponible</Typography>
        </div>
      );
    }
  };

  const handleOpenImageDialog = (img) => {
    setCurrentImage(img);
    setOpenImageDialog(true);
  };

  const handleCloseImageDialog = () => {
    setOpenImageDialog(false);
  };

  const handleTextFieldChange = (event) => {
    setOd(event.target.value);
  };

  const parseDateCertificaciones = (dateStr) => {
    return parse(dateStr, "dd/MM/yyyy HH:mm:ss", new Date());
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await obtenerDetallesOrdenAgente(od, nombreAgente);
      if (response) {
        toast.success("Datos cargados con éxito!");
        if (response.certificaciones) {
          const certificacionesOrdenadas = response.certificaciones.sort((a, b) => {
            const dateA = parseDateCertificaciones(a.FH_GESTION);
            const dateB = parseDateCertificaciones(b.FH_GESTION);
            return compareDesc(dateA, dateB);
          });
          console.log(certificacionesOrdenadas);
          setCertificaciones(certificacionesOrdenadas);
        } else {
          setCertificaciones([]);
        }

        if (response.datosOrden && response.datosOrden.length > 0) {
          setOdInfo(response.datosOrden[0]);
        } else {
          setOdInfo(null);
        }

        if (response.detallesManifiestos && response.detallesManifiestos.length > 0) {
          const eventsByType = prepareTimelineEvents(response.detallesManifiestos);
          setEventsByType(eventsByType);
        } else {
          setEventsByType({});
          console.log("No se encontraron detalles de manifiestos.");
        }
      }
    } catch (error) {
      console.error("Error al obtener detalles de la orden:", error);
      setCertificaciones([]);
      setOdInfo(null);
      toast.error("No se encontraron registros para el OD proporcionado o el agente no tiene acceso a esta orden.");
    }
  };

  return (
    <Container maxWidth="lg" style={{ padding: "5px" }}>
      <Paper style={{ padding: "10px", marginBottom: "5px" }}>
        <form onSubmit={handleFormSubmit}>
          <Tooltip title="Ingrese una OD para la busqueda">
            <TextField
              label="Buscar por OD"
              value={od}
              onChange={handleTextFieldChange}
              fullWidth
              margin="dense"
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton type="submit">
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Tooltip>
        </form>
      </Paper>
      {odInfo && (
        <Grid item xs={12}>
          <Card style={{ marginBottom: "5px", backgroundColor: "#f7f7f7" }}>
            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <div style={{ color: "#041562", fontSize: "14px" }}>
                    <strong>Bultos:</strong> {odInfo.BULTOS}
                  </div>
                </Grid>
              </Grid>
              <div style={{ height: "1px", backgroundColor: "#DA251C", margin: "5px 0" }}></div>
              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <div style={{ color: "#041562", fontSize: "14px" }}>
                    <strong>Fecha Digitación:</strong>
                    {odInfo ? formatDateToSantiago(odInfo.FH_DIGITACION) : "Cargando o no disponible"}
                  </div>
                  <div style={{ color: "#041562", fontSize: "14px" }}>
                    <strong>Nombre Cliente:</strong> {odInfo.NOMBRE_CLIENTE}
                  </div>
                  <div style={{ color: "#041562", fontSize: "14px" }}>
                    <strong>Nota:</strong> {odInfo.NOTA}
                  </div>
                  <div style={{ color: "#041562", fontSize: "14px" }}>
                    <strong>Tipo de carga:</strong> {odInfo.TIPO_CARGA}
                  </div>
                  <div style={{ color: "#041562", fontSize: "14px" }}>
                    <strong>Contacto destino:</strong> {odInfo.CONTACTO_DESTINO}
                  </div>
                  <div style={{ color: "#041562", fontSize: "14px" }}>
                    <strong>Cantidad de nevera:</strong> {odInfo.CANTIDAD_NEVERA}
                  </div>
                  <div style={{ color: "#041562", fontSize: "14px" }}>
                    <strong>Tipo de nevera:</strong> {odInfo.TIPO_NEVERA}
                  </div>
                  <div style={{ color: "#041562", fontSize: "14px" }}>
                    <strong>Personalizado:</strong> {odInfo.PERSONALIZADO}
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div style={{ color: "#041562", fontSize: "14px" }}>
                    <strong>OD Papel:</strong> {odInfo.OD_PAPEL}
                  </div>
                  <div style={{ color: "#041562", fontSize: "14px" }}>
                    <strong>Codigo de Barra:</strong> {odInfo.COD_BARRA || ""}
                  </div>
                  <div style={{ color: "#041562", fontSize: "14px" }}>
                    <strong>Nombre:</strong> {odInfo.NOMBRE}
                  </div>
                  <div style={{ color: "#041562", fontSize: "14px" }}>
                    <strong>Rut:</strong> {odInfo.RUT}
                  </div>
                  <div style={{ color: "#041562", fontSize: "14px" }}>
                    <strong>Telefono:</strong> {odInfo.TELEFONO}
                  </div>
                  <div style={{ color: "#041562", fontSize: "14px" }}>
                    <strong>Fecha de creacion:</strong>
                    {new Date(odInfo.FH_SYS).toLocaleString("es-CL", { timeZone: "America/Santiago" })}
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div style={{ color: "#041562", fontSize: "14px" }}>
                    <strong>Dirección:</strong> {odInfo.DIRECCION}
                  </div>
                  <div style={{ color: "#041562", fontSize: "14px" }}>
                    <strong>Comuna Destino:</strong> {odInfo.NOMBRE_COMUNA_DESTINO}
                  </div>
                  <div style={{ color: "#041562", fontSize: "14px" }}>
                    <strong>Comuna Origen:</strong> {odInfo.NOMBRE_COMUNA_ORIGEN}
                  </div>
                  <div style={{ color: "#041562", fontSize: "14px" }}>
                    <strong>Centro de costo:</strong> {odInfo.CENTRO_COSTO}
                  </div>
                  <div style={{ color: "#041562", fontSize: "14px" }}>
                    <strong>Guia:</strong> {odInfo.GUIA}
                  </div>
                </Grid>
              </Grid>
              <div style={{ height: "1px", backgroundColor: "#DA251C", margin: "5px 0" }}></div>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <div style={{ marginBottom: "5px", color: "#041562", fontSize: "14px" }}>
                    <strong>Datos retiros:</strong>
                  </div>
                  <Grid item xs={12}>
                    {odInfo.NOMBRE_RETIRO || odInfo.DIRECCION_RETIRO || odInfo.CONTACTO ? (
                      <Grid item xs={4}>
                        <div style={{ color: "#041562", fontSize: "14px" }}>
                          <strong>Nombre de retiro:</strong> {odInfo.NOMBRE_RETIRO || ""}
                        </div>
                        <div style={{ color: "#041562", fontSize: "14px" }}>
                          <strong>Direccion del retiro:</strong> {odInfo.DIRECCION_RETIRO || ""}
                        </div>
                        <div style={{ color: "#041562", fontSize: "14px" }}>
                          <strong>Contacto Retiro:</strong> {odInfo.CONTACTO || ""}
                        </div>
                        <div style={{ color: "#041562", fontSize: "14px" }}>
                          <strong>Telefono:</strong> {odInfo.TELEFONO_ORIGEN || ""}
                        </div>
                      </Grid>
                    ) : (
                      <div style={{ color: "#041562", fontSize: "14px" }}></div>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      )}
      {/* codigo nuevo */}
      {Object.keys(eventsByType).length > 0 && (
        <Paper style={{ marginBottom: "5px", padding: "10px" }}>
          <Box display="flex" alignItems="center" justifyContent="center" flexWrap="wrap">
            {procesoOrder.map((key, index) => {
              const title = procesoTitleMap[key];
              const events = eventsByType[key]?.manifests || [];
              return (
                <Fragment key={key}>
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    disabled={events.length === 0}
                    onClick={() => {
                      setSelectedManifiestoType(key);
                      setManifiestoModalOpen(true);
                    }}
                    style={{ marginRight: "5px", marginBottom: "5px", fontSize: "0.75rem", padding: "4px 8px" }}
                  >
                    {title}
                  </Button>
                  {index < procesoOrder.length - 1 && (
                    <ArrowForwardIcon style={{ fontSize: "1rem", marginRight: "5px" }} />
                  )}
                </Fragment>
              );
            })}
          </Box>
        </Paper>
      )}
      {certificaciones && certificaciones.length > 0 && (
        <VerticalTimeline lineColor={"#f0f5fc"} style={{ borderLeft: "2px solid #ccc", fontSize: "10px" }}>
          {certificaciones.map((certificacion, index) => {
            const estadoColor = certificacion.ESTADO_DESCRIPCION === "ENTREGA EXITOSA" ? "#28A745" : "#DA251C";
            return (
              <VerticalTimelineElement
                contentStyle={{ background: "#f0f5fc", color: "black", padding: "10px" }}
                contentArrowStyle={{ borderRight: "7px solid  #f0f5fc" }}
                key={index}
                date={certificacion.FH_GESTION}
                iconStyle={{ background: "#041562", color: "#fff" }}
                icon={<LocalShippingIcon />}
                style={{ fontSize: "5px" }}
                onClick={() => handleCertificationClick(certificacion)}
              >
                <h3 className="vertical-timeline-element-title" style={{ fontSize: "16px", color: estadoColor }}>
                  {certificacion.ESTADO_DESCRIPCION}
                  <IconButton
                    style={{ marginLeft: "5px" }}
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDetailsClick(certificacion);
                    }}
                  >
                    <TouchAppIcon />
                  </IconButton>
                </h3>
              </VerticalTimelineElement>
            );
          })}
        </VerticalTimeline>
      )}

      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} fullWidth maxWidth="md">
        <DialogTitle>Detalles de la Certificación</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              {selectedCertification && (
                <>
                  <Typography variant="body1">
                    <strong>Nombre receptor:</strong> {selectedCertification.NOMBRE}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Rut:</strong> {selectedCertification.RUT}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Fecha de Gestión:</strong> {selectedCertification.FH_GESTION}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Estado:</strong> {selectedCertification.ESTADO_DESCRIPCION}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Comuna Origen:</strong> {selectedCertification.COMUNA_ORIGEN}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Destino:</strong> {selectedCertification.DESTINO}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Chofer:</strong> {selectedCertification.NOMBRE_CHOFER}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Distancia en metros:</strong> {selectedCertification.DISTANCIA_METROS}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Nota:</strong> {selectedCertification.NOTA}
                  </Typography>
                </>
              )}
            </Grid>

            <Grid item xs={4}>
              {selectedCertification && (
                <Slider {...getSliderSettings(getImages(selectedCertification).length)}>
                  {getImages(selectedCertification).map((img, index) => (
                    <div
                      key={index}
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={img}
                        alt={`Foto ${index + 1}`}
                        style={{ width: "100%", height: "270px", cursor: "pointer" }}
                        onClick={() => handleOpenImageDialog(img)}
                      />
                      <span style={{ marginTop: "10px" }}>haz click a la imagen para ver detalle</span>
                    </div>
                  ))}
                </Slider>
              )}
            </Grid>

            <Grid item xs={4}>
              {renderGoogleMap()}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openImageDialog}
        onClose={handleCloseImageDialog}
        aria-labelledby="image-dialog-title"
        maxWidth="lg"
        fullWidth
      >
        <DialogContent
          style={{
            padding: 0,
            overflow: "hidden",
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <ImageZoom
              src={currentImage}
              alt="Enlarged pic"
              zoom="300"
              width="100%"
              height="auto"
              style={{ cursor: "zoom-in" }}
            />
          </div>
        </DialogContent>
      </Dialog>
      <Dialog open={manifiestoModalOpen} onClose={() => setManifiestoModalOpen(false)} fullWidth maxWidth="md">
        <DialogTitle>{eventsByType[selectedManifiestoType]?.title || ""}</DialogTitle>
        <DialogContent>
          {eventsByType[selectedManifiestoType] &&
            eventsByType[selectedManifiestoType].manifests.map((event, index) => (
              <div key={index}>
                <Typography variant="body1">
                  <strong>ID del manifiesto:</strong> {event.id}
                </Typography>
                <Typography variant="body1">
                  <strong>Fecha:</strong> {event.fecha}
                </Typography>
                <Typography variant="body1">
                  <strong>Chofer:</strong> {event.chofer}
                </Typography>
                <Typography variant="body1">
                  <strong>Usuario:</strong> {event.usuario}
                </Typography>
                <div style={{ height: "1px", backgroundColor: "#DA251C", margin: "5px 0" }}></div>
              </div>
            ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setManifiestoModalOpen(false)} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default ConsultaIndividualAgentes;
