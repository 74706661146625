import React, { useState, useEffect } from "react";
import { Container, LinearProgress, Box, Typography, Button } from "@mui/material";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import GeneralChart from "../Administracion/GeneralChart";
import { obtenerGraficosGeneral, descargarReporteExcel } from "../../services/reportabilidadEspecialService";
import AgentChartFirstHalf from "./AgentChartFirstHalf";
import AgentChartSecondHalf from "./AgentChartSecondHalf";

const ReportabilidadGeneral = () => {
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [datos, setDatos] = useState([]);
  const [firstHalfAgents, setFirstHalfAgents] = useState([]);
  const [secondHalfAgents, setSecondHalfAgents] = useState([]);
  const [totalAgents, setTotalAgents] = useState(0);

  const handleDescargarExcel = async () => {
    try {
      const data = await descargarReporteExcel();
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Reporte_Excel.xlsx");
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Error al descargar el archivo:", error);
    }
  };

  useEffect(() => {
    const cargarDatos = async () => {
      if (!isDataLoaded) {
        let interval = setInterval(() => {
          setLoadingProgress((oldProgress) => {
            if (oldProgress >= 80) {
              clearInterval(interval);
              return oldProgress;
            }
            const diff = Math.random() * 10;
            return Math.min(oldProgress + diff, 80);
          });
        }, 500);
      }

      try {
        const data = await obtenerGraficosGeneral();
        setDatos(data);
        if (!isDataLoaded) {
          setLoadingProgress(100);
          setIsDataLoaded(true);
          const agentData = {};
          data.forEach((dato) => {
            const agente = dato.AGENTE_ASIGNADO;
            const iniciales = agente
              .split(" ")
              .map((name) => name[0])
              .join(".");
            const comuna = dato.NOMBRE_CIUDAD_AGENTE;
            const displayLabel = `${comuna} ${iniciales}`;

            if (!agentData[displayLabel]) {
              agentData[displayLabel] = { enTransito: 0, enTransitoConEvento: 0, entregadas: 0, total: 0 };
            }

            if (dato.TIENE_ENTREGA === "SI") {
              agentData[displayLabel].entregadas++;
            } else if (dato.TIENE_ENTREGA === "NO" && dato.TIENE_EVENTO === "SI") {
              agentData[displayLabel].enTransitoConEvento++;
            } else if (dato.TIENE_ENTREGA === "NO" && dato.TIENE_EVENTO === "NO") {
              agentData[displayLabel].enTransito++;
            }
            agentData[displayLabel].total++;
          });

          const agentsList = Object.keys(agentData).map((label) => ({
            name: label,
            "En Transito": agentData[label].enTransito,
            "En Tránsito con evento": agentData[label].enTransitoConEvento,
            Entregadas: agentData[label].entregadas,
          }));

          setTotalAgents(agentsList.length);
          setFirstHalfAgents(agentsList.slice(0, Math.ceil(agentsList.length / 2)));
          setSecondHalfAgents(agentsList.slice(Math.ceil(agentsList.length / 2)));
        }
      } catch (error) {
        console.error("Error al cargar los datos:", error);
        setLoadingProgress(0);
      }
    };

    cargarDatos();
    const intervalId = setInterval(cargarDatos, 60000);
    return () => clearInterval(intervalId);
  }, [isDataLoaded]);

  return (
    <Container
      maxWidth="lg"
      style={{
        height: "90vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box>
        <Button
          onClick={handleDescargarExcel}
          variant="contained"
          color="success"
          style={{ position: "absolute", top: 10, right: 10 }}
        >
          Excel
        </Button>
      </Box>
      {!isDataLoaded && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <LinearProgress
            variant="determinate"
            value={loadingProgress}
            sx={{ width: "50%", height: 10, borderRadius: 5 }}
          />
          <Typography variant="body2" sx={{ mt: 1 }}>
            Cargando... {loadingProgress.toFixed(0)}%
          </Typography>
        </Box>
      )}
      {isDataLoaded && (
        <Carousel
          showArrows={true}
          emulateTouch={false}
          useKeyboardArrows={true}
          infiniteLoop={true}
          autoPlay={true}
          interval={30000}
          showStatus={false}
          showThumbs={false}
        >
          <div>
            <GeneralChart datos={datos} />
          </div>
          <div>
            <AgentChartFirstHalf data={firstHalfAgents} totalAgents={totalAgents} />
          </div>
          <div>
            <AgentChartSecondHalf data={secondHalfAgents} totalAgents={totalAgents} />
          </div>
        </Carousel>
      )}
    </Container>
  );
};

export default ReportabilidadGeneral;
