import React, { memo, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { obtenerEstados } from "../../services/PortalAgentesService";
import TableListadoEstados from "../../components/DespachoComponentes/componentesListadoEstados/tableListadoEstados";
import "react-toastify/dist/ReactToastify.css";

const ListadoEstados = () => {
  // eslint-disable-next-line no-unused-vars
  const userInfo = useSelector((state) => state.user.userInfo);
  const [estados, setEstados] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const cargarEstados = async () => {
      setLoading(true);
      try {
        const data = await obtenerEstados();
        setEstados(data);
      } catch (error) {
        console.error("Error al obtener los estados:", error);
      } finally {
        setLoading(false);
      }
    };
    cargarEstados();
  }, []);

  const columns = [
    { field: "CODIGO", headerName: "Codigo", width: 150 },
    { field: "DESCRIPCION", headerName: "Nombre del estado", width: 300 },
    { field: "TIPO_ORDEN", headerName: "Tipo", width: 150 },
    { field: "APP_ANTIGUO", headerName: "Esta en app antiguo?", width: 150 },
    { field: "APP_NUEVO", headerName: "Esta en app nuevo?", width: 150 },
  ];

  return (
    <div>
      <TableListadoEstados columns={columns} rows={estados} loading={loading} />
    </div>
  );
};

export default memo(ListadoEstados);
