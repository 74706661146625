import axios from "axios";
const BASE_API_URL = process.env.REACT_APP_BACKEND_URL;

export const getConsolidadoOrden = async ({ BUSCAR }) => {
  try {
    const response = await axios.post(`${BASE_API_URL}certificaciones/getConsolidadoOrden`, { BUSCAR });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const editOrdenCertificacion = async (data) => {
  try {

    const response = await axios.put(`${BASE_API_URL}certificaciones/getUpdateOrden`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};
