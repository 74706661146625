import React, { useState, memo, useEffect } from "react";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { TextField, Paper } from "@mui/material";
import { Button, Container, Grid, Modal, Typography, Box, Checkbox } from "@mui/material";
import { obtenerClientesControlSac } from "../../services/ReportesServices";
import {
  obtenerRendicionesPorGuia,
  obtenerRendicionesPorOrden,
  obtenerGuiasParaRendir,
  obtenerRendicionesPorFecha,
  borrarGuiaPendiente,
  cerrarManifiesto,
  buscarCanalRendiciones,
  generarPDFManifiesto,
  generarEXCLManifiesto,
} from "../../services/RendicionesService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector } from "react-redux";
import ReusableTableRendiciones from "../../components/DespachoComponentes/componentesRendiciones/ReusableTableRendiciones";
import { es } from "date-fns/locale";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import { format } from "date-fns";
import TableRendicionesPeri from "../../components/DespachoComponentes/componentesRendiciones/TableRendicionesPeri";

const RendicionCedibles = () => {
  const userInfo = useSelector((state) => state.user.userInfo);
  // eslint-disable-next-line no-unused-vars
  const id_usuario = userInfo ? userInfo.id : null;
  const [clientes, setClientes] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [sLoadingRendiciones, setIsLoadingRendiciones] = useState(false);
  const [cargandoGuias, setCargandoGuia] = useState(false);
  const [sLoadingmanifiesto, setIsLoadingmanifiesto] = useState(false);
  const [od, setOD] = useState("");
  const [guia, setGUIA] = useState("");
  const [fecha, setFecha] = useState(null);
  const [dataTabla, setDataTabla] = useState([]);
  const [dataTablaRendiciones, setDataTablaRendiciones] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedItems, setSelectedItems] = useState(new Set());
  const [cargaRendiciones, setCargaRendiciones] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showPeriModal, setShowPeriModal] = useState(false);
  const handleClosePeriModal = () => {
    setShowPeriModal(false);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelectedItems = new Set(dataTabla.map((item) => item.ID));
      setSelectedItems(newSelectedItems);
      return;
    }
    setSelectedItems(new Set());
  };

  const columns = [
    {
      field: "delete",
      headerName: "Eliminar",
      width: 80,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <DeleteIcon
          style={{ color: "#DA251C", cursor: "pointer" }}
          onClick={() => handleDelete(params.row.GUIA_RENDIDA, params.row.OD, params.row.COD_CLIENTE)}
        />
      ),
    },
    { field: "OD", headerName: "OD", width: 120, align: "center", headerAlign: "center" },
    { field: "GUIA_RENDIDA", headerName: "Guia Rendida", width: 120, align: "center", headerAlign: "center" },
    {
      field: "FH_DIGITACION",
      headerName: "Fecha Digitacion",
      width: 130,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => formatDate(params.value),
    },
    { field: "NOMBRE", headerName: "Nombre", width: 130, align: "center", headerAlign: "center" },
    { field: "RUT", headerName: "Rut", width: 130, align: "center", headerAlign: "center" },
    {
      field: "FECHA_ENTREGA",
      headerName: "Fecha Entrega",
      width: 180,
      align: "center",
      headerAlign: "center",
    },
  ];

  const formatDateToSend = (date) => {
    return format(date, "yyyy-MM-dd");
  };

  /* borrar ordenes de la tabla y también de la base de datos */
  const handleDelete = async (guia, od, codCliente) => {
    const result = await Swal.fire({
      title: "¿Estás seguro?",
      text: "No podrás revertir esta acción",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, eliminar!",
    });

    if (result.isConfirmed) {
      try {
        const response = await borrarGuiaPendiente({
          GUIA: guia,
          OD: od,
          COD_CLIENTE: codCliente,
        });

        if (response.message === "guia Eliminada con éxito") {
          const newData = dataTablaRendiciones.filter(
            (item) => !(item.GUIA_RENDIDA === guia && item.OD === od && item.COD_CLIENTE === codCliente),
          );
          setDataTablaRendiciones(newData);

          Swal.fire("Eliminado!", "El registro ha sido eliminado.", "success");
        } else {
          Swal.fire("No se pudo eliminar", response.message, "error");
        }
      } catch (error) {
        console.error("Error al eliminar la guía:", error);
        Swal.fire("Error!", "No se pudo eliminar el registro debido a un error en el servidor.", "error");
      }
    }
  };

  const handleDateChange = (newDate) => {
    if (newDate) {
      const localMidnightDate = new Date(newDate.setHours(0, 0, 0, 0));
      setFecha(localMidnightDate);
      console.log(localMidnightDate.toISOString().split("T")[0]);
    } else {
      setFecha(null);
    }
  };

  /* formatear fecha de tabla */
  const formatDate = (isoString) => {
    const date = new Date(isoString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  /* cambios en cliente */
  const handleClientChange = async (selectedClient) => {
    if (!selectedClient) {
      return;
    }
    try {
      await buscarCanalRendiciones({ idCliente: selectedClient.id });
    } catch (error) {
      console.error("Error al obtener detalles del cliente:", error);
      toast.error("Error al obtener canales del cliente");
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      BuscarRendiciones();
      event.preventDefault();
    }
  };

  const BuscarRendiciones = async () => {
    setIsLoadingRendiciones(true);
    let data = [];
    let openModalAfterSearch = true;

    try {
      if (guia && selectedClient) {
        const response = await obtenerRendicionesPorGuia(guia, selectedClient.id);
        data = response.datosRendiciones;
      } else if (od && selectedClient) {
        const response = await obtenerRendicionesPorOrden(od, selectedClient.id);
        data = response.datosRendiciones;
      } else if (fecha && selectedClient) {
        const formattedDate = formatDateToSend(fecha);
        const response = await obtenerRendicionesPorFecha(formattedDate, selectedClient.id);
        data = response.rendicionesPendientes;
        setDataTablaRendiciones(data);
        openModalAfterSearch = false;
      }
      const existingGuias = new Set(dataTablaRendiciones.map((item) => item.GUIA_RENDIDA));
      const filteredData = data.filter((item) => !existingGuias.has(item.ID_REFERENCIA));

      if (filteredData.length > 0 && openModalAfterSearch) {
        setDataTabla(filteredData);
        setOpenModal(true);
      } else {
        setDataTabla([]);
        setOpenModal(false);
        if (filteredData.length === 0) {
          toast.info("No hay más guías disponibles para rendir según los filtros aplicados.");
        } else {
          toast.info("Se han cargado las guías pendientes directamente.");
        }
      }
    } catch (error) {
      console.error("Error al buscar rendiciones:", error);
      toast.error("La orden no tiene un evento de entrega o no se encontraron datos.");
    } finally {
      setGUIA("");
      setOD("");
      setIsLoadingRendiciones(false);
    }
  };

  useEffect(() => {
    const fetchClientes = async () => {
      try {
        const response = await obtenerClientesControlSac();
        setClientes(response);
      } catch (error) {
        console.error("Error al obtener la lista de clientes:", error);
      }
    };
    fetchClientes();
  }, []);

  const handleCheck = (id) => {
    setSelectedItems((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(id)) {
        newSet.delete(id);
      } else {
        newSet.add(id);
      }
      return newSet;
    });
  };

  const cargarGuiasSeleccionadas = async () => {
    setCargandoGuia(true);
    if (!fecha) {
      toast.error("Fecha no especificada.");
      return;
    }
    const formattedDate = formatDateToSend(fecha);
    const guiasSeleccionadas = Array.from(selectedItems).map((id) => dataTabla.find((item) => item.ID === id));

    try {
      const response = await obtenerGuiasParaRendir(guiasSeleccionadas, formattedDate);
      if (response && response.updatedRecords) {
        const nuevosRegistros = response.updatedRecords.filter(
          (nuevo) => !dataTablaRendiciones.some((registro) => registro.ID === nuevo.ID),
        );
        setDataTablaRendiciones([...dataTablaRendiciones, ...nuevosRegistros]);
        toast.success("Guías cargadas correctamente");
      }
    } catch (error) {
      console.error("Error al cargar guías:", error);
      toast.error("Error al cargar guías");
    } finally {
      setOpenModal(false);
      setSelectedItems(new Set());
      setCargandoGuia(false);
    }
  };

  const cerrarManifiestoActual = async () => {
    if (!fecha || !selectedClient) {
      toast.error("Fecha o cliente no especificados.");
      return;
    }
    const formattedDate = formatDateToSend(fecha);
    setIsLoadingmanifiesto(true);
    try {
      const result = await cerrarManifiesto(formattedDate, selectedClient.id);
      if (result.codManifiesto) {
        Swal.fire({
          title: "Seleccione el formato de descarga",
          text: "¿Desea descargar el manifiesto en formato PDF o Excel?",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#8B0000",
          cancelButtonColor: "#008000",
          confirmButtonText: "Descargar PDF",
          cancelButtonText: "Descargar Excel",
        }).then((choice) => {
          if (choice.value) {
            descargarPDF(result.codManifiesto);
          } else if (choice.dismiss === Swal.DismissReason.cancel) {
            descargarExcel(result.codManifiesto);
          }
        });
      } else {
        toast.error("Manifiesto no generado correctamente.");
      }
    } catch (error) {
      console.error("Error al Crear manifiesto: ", error);
      toast.error("Error al Crear manifiesto.");
    } finally {
      setIsLoadingmanifiesto(false);
      resetStates();
    }
  };

  const descargarPDF = async (codManifiesto) => {
    try {
      const pdfResult = await generarPDFManifiesto(codManifiesto);
      toast.success(pdfResult.message);
    } catch (pdfError) {
      toast.error("Error al generar el PDF.");
    }
  };

  const descargarExcel = async (codManifiesto) => {
    try {
      const excelResult = await generarEXCLManifiesto(codManifiesto);
      toast.success(excelResult.message);
    } catch (excelError) {
      toast.error("Error al generar el Excel.");
    }
  };

  const resetStates = () => {
    setSelectedClient(null);
    setDataTabla([]);
    setDataTablaRendiciones([]);
    setOD("");
    setGUIA("");
    setFecha(null);
    setSelectedItems(new Set());
    setCargaRendiciones(false);
  };
  const renderGuiasModal = () => (
    <Modal
      open={showModal}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          borderRadius: 2,
          p: 4,
        }}
      >
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Guías Asociadas
        </Typography>
        <Box
          sx={{
            maxHeight: 300,
            overflowY: "auto",
            mt: 2,
          }}
        >
          <Grid container spacing={2}></Grid>
        </Box>
      </Box>
    </Modal>
  );

  const renderModal = () => (
    <Modal
      open={openModal}
      onClose={() => setOpenModal(false)}
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <Box
        sx={{
          width: { xs: "90%", sm: "70%", md: "60%", lg: "50%" },
          bgcolor: "background.paper",
          p: 3,
          borderRadius: 2,
          boxShadow: 24,
          outline: "none",
          maxHeight: "80vh",
          overflow: "auto",
        }}
      >
        <Typography variant="body1" sx={{ color: "red", mb: 2 }}>
          Marque las guías que quiere rendir:
        </Typography>
        <TableContainer component={Paper} sx={{ maxHeight: 350, overflow: "auto" }}>
          <Table sx={{ minWidth: 350 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    indeterminate={selectedItems.size > 0 && selectedItems.size < dataTabla.length}
                    checked={dataTabla.length > 0 && selectedItems.size === dataTabla.length}
                    onChange={handleSelectAllClick}
                    edge="end"
                  />
                </TableCell>
                <TableCell>OD Papel</TableCell>
                <TableCell align="right">OD</TableCell>
                <TableCell align="right">Guía</TableCell>
                <TableCell align="right">Nombre del Cliente</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {dataTabla.map((item) => (
                <TableRow key={item.ID} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell padding="checkbox">
                    <Checkbox edge="end" onChange={() => handleCheck(item.ID)} checked={selectedItems.has(item.ID)} />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {item.OD_PAPEL}
                  </TableCell>
                  <TableCell align="right">{item.OD}</TableCell>
                  <TableCell align="right">{item.ID_REFERENCIA}</TableCell>
                  <TableCell align="right">{item.NOMBRE_CLIENTE}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Button
          sx={{
            backgroundColor: "#041562",
            color: "#FFFFFF",
            "&:hover": {
              backgroundColor: "#031042",
            },
            mt: 2,
          }}
          onClick={cargarGuiasSeleccionadas}
          variant="contained"
          color="primary"
          fullWidth
        >
          {cargandoGuias ? <CircularProgress sx={{ color: "#FFFFFF" }} size={24} /> : "Cargar Guías Seleccionadas"}
        </Button>
      </Box>
    </Modal>
  );

  const renderPeriModal = () => (
    <Modal
      open={showPeriModal}
      onClose={handleClosePeriModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "60%",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          maxHeight: "90vh",
          overflow: "auto",
        }}
      >
        <TableRendicionesPeri
          columns={
            [
              /* Define tus columnas aquí */
            ]
          }
          rows={dataTablaRendiciones}
          loading={false}
        />
      </Box>
    </Modal>
  );

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
        <Container maxWidth="lg">
          <Paper style={{ padding: "10px" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={7}>
                <Autocomplete
                  id="combo-box-cliente"
                  options={clientes.map((cliente) => ({
                    id: cliente.ID,
                    nombre: `${cliente.NOMBRE} (ID: ${cliente.ID})`,
                  }))}
                  getOptionLabel={(option) => option.nombre}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  value={selectedClient}
                  onChange={(event, newValue) => {
                    setSelectedClient(newValue);
                    handleClientChange(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Nombre Cliente"
                      variant="outlined"
                      fullWidth
                      margin="dense"
                      size="small"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <DesktopDatePicker
                  label="Fecha"
                  inputFormat="dd/MM/yyyy"
                  value={fecha}
                  onChange={handleDateChange}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      margin: "dense",
                      variant: "outlined",
                      size: "small",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField
                  type="search"
                  variant="outlined"
                  size="small"
                  placeholder="GUIA"
                  onKeyDown={handleKeyDown}
                  margin="dense"
                  value={guia}
                  fullWidth
                  onChange={(e) => setGUIA(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField
                  type="search"
                  variant="outlined"
                  size="small"
                  placeholder="OD"
                  onKeyDown={handleKeyDown}
                  margin="dense"
                  value={od}
                  fullWidth
                  onChange={(e) => setOD(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <Button
                  sx={{
                    backgroundColor: "#041562",
                    color: "#FFFFFF",
                    "&:hover": {
                      backgroundColor: "#031042",
                    },
                    marginTop: 1,
                  }}
                  onClick={() => BuscarRendiciones(true)}
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  {sLoadingRendiciones ? <CircularProgress sx={{ color: "#FFFFFF" }} size={24} /> : "Buscar"}
                </Button>
              </Grid>
              <Grid item xs={12} md={3}>
                <Button
                  sx={{
                    backgroundColor: "#041562",
                    color: "#FFFFFF",
                    "&:hover": {
                      backgroundColor: "#031042",
                    },
                    marginTop: 1,
                  }}
                  onClick={cerrarManifiestoActual}
                  disabled={!fecha || !selectedClient}
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  {sLoadingmanifiesto ? <CircularProgress sx={{ color: "#FFFFFF" }} size={24} /> : "Crear manifiesto"}
                </Button>
              </Grid>
            </Grid>
          </Paper>
          {dataTablaRendiciones.length > 0 && (
            <ReusableTableRendiciones columns={columns} rows={dataTablaRendiciones} loading={cargaRendiciones} />
          )}
        </Container>
      </LocalizationProvider>
      {renderModal()}
      {renderGuiasModal()}
      {renderPeriModal()}
    </>
  );
};

export default memo(RendicionCedibles);
