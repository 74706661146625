import React, { memo, useEffect, useState } from "react";
import { obtenerChoferes } from "../../services/PortalChoferesService";
import { obtenerOrdenesPrueba, crearManifiestoChofer } from "../../services/PruebasServices";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Box,
  Button,
  TextField,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  CircularProgress,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";

const CrearRetirosPrueba = () => {
  const userInfo = useSelector((state) => state.user.userInfo);
  const id_usuario = userInfo ? userInfo.id : null;
  const [choferes, setChoferes] = useState([]);
  const [ordenes, setOrdenes] = useState([]);
  const [selectedChofer, setSelectedChofer] = useState(null);
  const [selectedOrdenes, setSelectedOrdenes] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(5);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const choferesData = await obtenerChoferes();
        setChoferes(choferesData);

        const ordenesData = await obtenerOrdenesPrueba();
        setOrdenes(ordenesData);
      } catch (error) {
        console.error("Error al obtener los datos:", error);
      }
    };

    fetchData();
  }, []);

  const handleOrdenSelect = (od_papel) => {
    setSelectedOrdenes((prevState) => ({
      ...prevState,
      [od_papel]: !prevState[od_papel],
    }));
  };

  const handleCreateManifest = async () => {
    const selectedOdPapeles = Object.keys(selectedOrdenes).filter((od_papel) => selectedOrdenes[od_papel]);
    if (!selectedChofer) {
      toast.error("Por favor seleccione un chofer.");
      return;
    }
    if (selectedOdPapeles.length === 0) {
      toast.error("Por favor seleccione al menos una orden.");
      return;
    }

    setLoading(true);
    try {
      const response = await crearManifiestoChofer(selectedChofer.COD_CHOFER, selectedOdPapeles, id_usuario);
      console.log(response);
      toast.success("Manifiesto creado con éxito.");
      setSelectedOrdenes({});
    } catch (error) {
      console.error("Error al crear el manifiesto:", error);
      toast.error("Error al crear el manifiesto.");
    } finally {
      setLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    const options = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    };
    const formattedDate = date.toLocaleDateString("es-ES", options);
    const time = date.toLocaleTimeString("es-ES");
    return `${formattedDate} ${time}`;
  };

  return (
    <Box sx={{ p: 2 }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Autocomplete
          sx={{
            width: "320px",
            "@media (min-width:600px)": {
              width: "90%",
            },
            "@media (min-width:760px)": {
              width: "90%",
            },
          }}
          id="combo-box-chofer"
          options={choferes}
          getOptionLabel={(option) => `${option.NOMBRE} (COD: ${option.COD_CHOFER})`}
          isOptionEqualToValue={(option, value) => option.COD_CHOFER === value?.COD_CHOFER}
          value={selectedChofer || null}
          onChange={(event, newValue) => {
            setSelectedChofer(newValue || null);
          }}
          renderInput={(params) => <TextField {...params} label="Seleccione el chofer" variant="outlined" fullWidth />}
        />
        <Button
          variant="contained"
          onClick={handleCreateManifest}
          disabled={loading}
          sx={{
            marginLeft: "10px",
            backgroundColor: "#041562",
            color: "#FFFFFF",
            "&:hover": {
              backgroundColor: "#041562",
            },
            whiteSpace: "nowrap",
          }}
        >
          {loading ? <CircularProgress size={24} sx={{ color: "#FFFFFF" }} /> : "Crear Manifiesto"}
        </Button>
      </Box>
      <Box sx={{ overflowX: "auto" }}>
        <TableContainer component={Paper} sx={{ maxHeight: 500 }}>
          <Table stickyHeader aria-label="Órdenes Table">
            <TableHead>
              <TableRow>
                <TableCell>Seleccionar</TableCell>
                <TableCell>OD</TableCell>
                <TableCell>OD PAPEL</TableCell>
                <TableCell>FECHA DIGITACION</TableCell>
                <TableCell>CODIGO CLIENTE</TableCell>
                <TableCell>NOMBRE DESTINO</TableCell>
                <TableCell>RUT DESTINO</TableCell>
                <TableCell>TELEFONO DESTINO</TableCell>
                <TableCell>CONTACTO DESTINO</TableCell>
                <TableCell>DIRECCION DESTINO</TableCell>
                <TableCell>COMUNA DESTINO</TableCell>
                <TableCell>NOMBRE ORIGEN</TableCell>
                <TableCell>TELEFONO ORIGEN</TableCell>
                <TableCell>CONTACTO ORIGEN</TableCell>
                <TableCell>DIRECCION ORIGEN</TableCell>
                <TableCell>COMUNA ORIGEN</TableCell>
                <TableCell>GUIA</TableCell>
                <TableCell>NOTA</TableCell>
                <TableCell>BULTOS</TableCell>
                <TableCell>TIPO DE ORDEN</TableCell>
                <TableCell>ORIGEN ORDEN</TableCell>
                <TableCell>AGENTE ASIGNADO</TableCell>
                <TableCell>AGENTE ASIGNADO INICIAL</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {ordenes.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((orden) => (
                <TableRow key={orden.OD_PAPEL} hover>
                  <TableCell>
                    <Checkbox
                      checked={!!selectedOrdenes[orden.OD_PAPEL]}
                      onChange={() => handleOrdenSelect(orden.OD_PAPEL)}
                    />
                  </TableCell>
                  <TableCell>{orden.OD}</TableCell>
                  <TableCell>{orden.OD_PAPEL}</TableCell>
                  <TableCell>{formatDate(orden.FH_DIGITACION)}</TableCell>
                  <TableCell>{orden.COD_CLIENTE}</TableCell>
                  <TableCell>{orden.NOMBRE}</TableCell>
                  <TableCell>{orden.RUT}</TableCell>
                  <TableCell>{orden.TELEFONO}</TableCell>
                  <TableCell>{orden.CONTACTO_DESTINO}</TableCell>
                  <TableCell>{orden.DIRECCION}</TableCell>
                  <TableCell>{orden.COMUNA}</TableCell>
                  <TableCell>{orden.NOMBRE_RETIRO}</TableCell>
                  <TableCell>{orden.TELEFONO_ORIGEN}</TableCell>
                  <TableCell>{orden.CONTACTO}</TableCell>
                  <TableCell>{orden.DIRECCION_RETIRO}</TableCell>
                  <TableCell>{orden.COMUNA_ORIGEN}</TableCell>
                  <TableCell>{orden.GUIA}</TableCell>
                  <TableCell>{orden.NOTA}</TableCell>
                  <TableCell>{orden.BULTOS}</TableCell>
                  <TableCell>{orden.TIPO_ORDEN}</TableCell>
                  <TableCell>{orden.ORIGEN_ORDEN}</TableCell>
                  <TableCell>{orden.AGENTE_ASIGNADO}</TableCell>
                  <TableCell>{orden.AGENTE_ASIGNADO_INICIAL}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          <TablePagination
            component="div"
            count={ordenes.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5]}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default memo(CrearRetirosPrueba);
