import axios from "axios";
const BASE_API_URL = process.env.REACT_APP_BACKEND_URL;

const apiClient = axios.create({
  baseURL: BASE_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

/* Descargar reporte Excel */
export const descargarReporteExcel = async () => {
  try {
    const response = await apiClient.post("reportabilidadEspecial/descargarReporteExcel", {}, {
      responseType: 'blob' 
    });
    return response.data;
  } catch (error) {
    console.error("Error al descargar el archivo Excel:", error.response);
    throw error;
  }
};

/* obtenemos Graficos ejecutiva */
export const obtenerGraficosGeneral = async (fechaDesde, fechaHasta) => {
  try {
    const response = await apiClient.post("reportabilidadEspecial/obtenerGraficosGeneral", {
      fechaDesde,
      fechaHasta,
    });
    return response.data;
  } catch (error) {
    console.error("Error al obtener los datos:", error.response);
    throw error;
  }
};

/* obtenemos Graficos ejecutiva */
export const obtenerGraficosEjecutivaControl = async (fechaDesde, fechaHasta) => {
  try {
    const response = await apiClient.post("reportabilidadEspecial/obtenerGraficosEjecutivaControl", {
      fechaDesde,
      fechaHasta,
    });
    return response.data;
  } catch (error) {
    console.error("Error al obtener los datos:", error.response);
    throw error;
  }
};
