import React, { useState, useEffect } from "react";
import { Paper, Grid, Autocomplete, TextField, Button } from "@mui/material";
import { useSelector } from "react-redux";
import { getOrdenesPendientesProcesos } from "../../../services/PendientesEntradaSalida";
import TablaManifiestos from "../componentesManifiestos/TablaManifiestos";
import { toast } from "react-toastify";

const PendientesEntradaSalida = () => {
  const userInfo = useSelector((state) => state.user.userInfo);

  const [proceso1, setProceso1] = useState(null);
  const [proceso2, setProceso2] = useState(null);
  const [procesosOption, setProcesosOption] = useState([]);
  const [fechaSeleccionada1, setFechaSeleccionada1] = useState("");
  const [fechaSeleccionada2, setFechaSeleccionada2] = useState("");
  const [dataOrdenes, setDataOrdenes] = useState([]);
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    const cargarProcesos = async () => {
      try {
        const opciones = [
          { label: "ENTRADA ANDEN", value: "ENTRADAANDEN" },
          { label: "SALIDA ANDEN", value: "SALIDAANDEN" },
        ];
        setProcesosOption(opciones);
      } catch {}
    };
    cargarProcesos();
  }, [userInfo]);

  const columns = [
    { field: "OD", headerName: "OD", width: 100 },
    { field: "OD_PAPEL", headerName: "OD PAPEL", width: 100 },
    { field: "CLIENTE", headerName: "CLIENTE", width: 300 },
    { field: "ORIGEN", headerName: "COMUNA ORIGEN", width: 150 },
    { field: "DESTINO", headerName: "COMUNA DESTINO", width: 150 },
    { field: "CIUDAD_PADRE", headerName: "CIUDAD", width: 150 },
    { field: "TIPO_ORDEN", headerName: "TIPO DE ORDEN", width: 200 },
  ];

  const handleChangeProceso1 = async (e, newValue) => {
    setProceso1(newValue);
  };

  const handleChangeProceso2 = async (e, newValue) => {
    setProceso2(newValue);
  };

  const handleChangeFecha1 = async (e, newValue) => {
    setFechaSeleccionada1(e.target.value);
  };

  const handleChangeFecha2 = async (e, newValue) => {
    setFechaSeleccionada2(e.target.value);
  };

  const consultar = async () => {
    if (!proceso1 || !proceso2 || !fechaSeleccionada1 || !fechaSeleccionada2) {
      toast.error("Faltan datos");
      return;
    }

    if (proceso1 === proceso2) {
      toast.error("Procesos no pueden ser iguales");
      return;
    }

    try {
      setLoading(true);
      const datos = {
        PROCESO1: proceso1.value,
        PROCESO2: proceso2.value,
        FECHA1: fechaSeleccionada1,
        FECHA2: fechaSeleccionada2,
      };
      const OrdenesData = await getOrdenesPendientesProcesos(datos);
      if (OrdenesData.length > 0) {
        setDataOrdenes(OrdenesData);
      }
      } catch (error) {
        console.error("Error al consultar datos:", error);
        toast.error("Ocurrió un error al consultar los datos");
      } finally {
        setLoading(false);
      }
  };

  const cancelar = async () => {
    reset();
  };

  const reset = () => {
    setProceso1(null);
    setProceso2(null);
    setFechaSeleccionada1("");
    setFechaSeleccionada2("");
    setDataOrdenes([]);
  };

  return (
    <div>
      <Paper style={{ padding: "10px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Autocomplete
              disableClearable
              value={proceso1}
              disablePortal
              id="size-small-outlined-multi"
              size="small"
              options={procesosOption}
              onChange={handleChangeProceso1}
              sx={{ width: "100%" }}
              PaperComponent={({ children }) => (
                <Paper
                  sx={{
                    width: "100%",
                    textAlign: "left",
                    "& li": { textAlign: "left" },
                  }}
                >
                  {children}
                </Paper>
              )}
              renderInput={(params) => <TextField {...params} label="PROCESO REALIZADO" />}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <TextField
              fullWidth
              type="date"
              size="small"
              label="FECHA PROCESO REALIZADO"
              value={fechaSeleccionada1}
              onChange={handleChangeFecha1}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Autocomplete
              disableClearable
              value={proceso2}
              disablePortal
              id="size-small-outlined-multi"
              size="small"
              options={procesosOption}
              onChange={handleChangeProceso2}
              sx={{ width: "100%" }}
              PaperComponent={({ children }) => (
                <Paper
                  sx={{
                    width: "100%",
                    textAlign: "left",
                    "& li": { textAlign: "left" },
                  }}
                >
                  {children}
                </Paper>
              )}
              renderInput={(params) => <TextField {...params} label="PROCESO NO REALIZADO" />}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <TextField
              fullWidth
              type="date"
              size="small"
              label="FECHA PROCESO NO REALIZADO"
              value={fechaSeleccionada2}
              onChange={handleChangeFecha2}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Button
              style={{ backgroundColor: "#041562", color: "#FFFFFF" }}
              className="boton1"
              size="medium"
              variant="contained"
              color="primary"
              onClick={consultar}
            >
              CONSULTAR
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Button
              style={{ backgroundColor: "#DA251C" }}
              className="boton1"
              size="medium"
              variant="contained"
              onClick={cancelar}
            >
              CANCELAR
            </Button>
          </Grid>
        </Grid>
        <Grid>
          <Grid style={{ marginTop: "10px" }}>
            <TablaManifiestos rows={dataOrdenes} columns={columns} loading={loading} />
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};
export default PendientesEntradaSalida;
