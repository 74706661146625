import axios from "axios";
import { toast } from "react-toastify";
const BASE_API_URL = process.env.REACT_APP_BACKEND_URL;


export const buscarChoferes = async (nombre) => {
    try {
      const response = await axios.post(`${BASE_API_URL}manifiestos/getChoferesAgente`, {
        nombre,
      });
      if (response.data && response.data.length > 0) {
        return response.data;
      } else {
        toast.error("Usuario sin choferes asignado");
        return null;
      }
    } catch (error) {
      console.error("Error obteniendo datos Chofer desde API.js", error);
      throw error;
    }
  };
  

  export const buscarOrdenesComunas = async (iatas, agente) => {
    try {
      const response = await axios.post(`${BASE_API_URL}manifiestos/getOrdenesManifiestoComunas`, {
        comunas: iatas, AGENTE_ASIGNADO : agente
      });
      if (response.data && response.data.length > 0) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      console.error("Error obteniendo ordenes desde API.js", error);
      throw error;
    }
  };

  export const insertPickingManifiestoMasivo= async (data) => {
    try {
      const response = await axios.post(`${BASE_API_URL}manifiestos/getInsertPickingComunas`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
  
      return response.data;
    } catch (error) {
      console.error("Error Insert Picking:", error);
      throw error;
    }
  };
  