import React, { memo, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { obtenerChoferes } from "../../services/PortalChoferesService";
import TableListadoChoferes from "../../components/DespachoComponentes/componentesListadoChoferes/tableListadoChoferes";
import "react-toastify/dist/ReactToastify.css";

const ListadoChoferes = () => {
  // eslint-disable-next-line no-unused-vars
  const userInfo = useSelector((state) => state.user.userInfo);
  const [choferes, setChoferes] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const cargarChoferes = async () => {
      setLoading(true);
      try {
        const data = await obtenerChoferes();
        setChoferes(data);
      } catch (error) {
        console.error("Error al obtener los choferes:", error);
      } finally {
        setLoading(false);
      }
    };
    cargarChoferes();
  }, []);

  const columns = [
    { field: "NOMBRE", headerName: "Nombre", width: 250 },
    { field: "RUT", headerName: "Rut", width: 130 },
    { field: "CIUDAD", headerName: "Ciudad", width: 100 },
    { field: "MAIL", headerName: "Mail", width: 150 },
    { field: "COD_CHOFER", headerName: "Código", width: 100 },
    { field: "ESTADO", headerName: "Estado", width: 100 },
    { field: "TRANSPORTISTA", headerName: "Transportista", width: 120 },
    { field: "TELEFONO", headerName: "Teléfono", width: 100 },
    { field: "CHOFER_ADMINISTRATIVO", headerName: "Chofer Administrativo", width: 180 },
    { field: "AGENTE_ASIGNADO", headerName: "Agente Asignado", width: 180 },
    {
      field: "FH_SYS",
      headerName: "Fecha del sistema",
      width: 180,
      renderCell: (params) => formatDate(params.value),
    },
  ];

  /* formatear fecha de tabla */
  const formatDate = (isoString) => {
    const date = new Date(isoString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  };

  return (
    <div>
      <TableListadoChoferes columns={columns} rows={choferes} loading={loading} />
    </div>
  );
};

export default memo(ListadoChoferes);
