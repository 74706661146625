import React from "react";
import { Link } from "react-router-dom";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import { ExpandLess, ExpandMore, Filter1, Download } from "@mui/icons-material";
import ContentPasteGoIcon from "@mui/icons-material/ContentPasteGo";
import AssignmentLateIcon from "@mui/icons-material/AssignmentLate";
import AssignmentReturnedIcon from "@mui/icons-material/AssignmentReturned";
import EditIcon from "@mui/icons-material/Edit";
import { useSelector } from "react-redux";

function MenuClientes({ isDrawerOpen, openSubMenu, handleClick, isVisible, hasVisibleSubmenus, location }) {
  const userInfo = useSelector((state) => state.user.userInfo);

  // Función para determinar si mostrar 'Gestión de Órdenes de Clientes'
  const shouldShowGestionOrdenesClientes = () => {
    return userInfo.cod_cliente === "395" && isVisible("GestionOrdenesClientes");
  };

  // Función para determinar si mostrar 'Control de Cedibles Novo'
  const shouldShowControlCediblesNovo = () => {
    return userInfo.cod_cliente === "632" && isVisible("ControlCediblesNovo");
  };

  // Función para determinar si ocultar 'Cedibles Cliente Page'
  const shouldHideCediblesClientePage = () => {
    return userInfo.cod_cliente === "632";
  };

  return (
    hasVisibleSubmenus([
      "RetirosCliente",
      "CediblesClientePage",
      "ControlCediblesNovo",
      "ManifiestosSalidaInhouseCliente",
      "GestionOrdenesClientes",
    ]) && (
      <>
        <ListItemButton onClick={() => handleClick("clientes")}>
          <ListItemIcon sx={{ color: "white" }}>
            <ContentPasteGoIcon />
          </ListItemIcon>
          {isDrawerOpen && <ListItemText primary="Clientes" sx={{ color: "white" }} />}
          {isDrawerOpen &&
            (openSubMenu === "clientes" ? (
              <ExpandLess sx={{ color: "white" }} />
            ) : (
              <ExpandMore sx={{ color: "white" }} />
            ))}
        </ListItemButton>
        <Collapse in={openSubMenu === "clientes"} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {shouldShowGestionOrdenesClientes() && (
              <ListItemButton
                component={Link}
                to="/GestionOrdenesClientes"
                sx={{
                  bgcolor: location.pathname === "/GestionOrdenesClientes" ? "white" : "transparent",
                  color: location.pathname === "/GestionOrdenesClientes" ? "#041562" : "white",
                  ".MuiListItemText-primary": { fontSize: "0.775rem" },
                  pl: isDrawerOpen ? 4 : 2,
                  "& .MuiListItemIcon-root": {
                    color: location.pathname === "/GestionOrdenesClientes" ? "#041562" : "white",
                    minWidth: isDrawerOpen ? "auto" : 25,
                    mr: isDrawerOpen ? 2 : "auto",
                    justifyContent: "center",
                  },
                  "&:hover": {
                    bgcolor: "white",
                    ".MuiListItemIcon-root": { color: "#041562" },
                    ".MuiListItemText-primary": { color: "#041562" },
                    borderRadius: "10px",
                  },
                  borderRadius: location.pathname === "/GestionOrdenesClientes" ? "10px" : "0px",
                  margin: location.pathname === "/GestionOrdenesClientes" ? "8px" : "0px",
                  overflow: "hidden",
                }}
              >
                <ListItemIcon>
                  <EditIcon />
                </ListItemIcon>
                {isDrawerOpen && <ListItemText primary="Edición de Ordenes" />}
              </ListItemButton>
            )}
            {isVisible("RetirosClienteStgo") && (
              <ListItemButton
                component={Link}
                to="/RetirosClienteStgo"
                sx={{
                  bgcolor: location.pathname === "/RetirosClienteStgo" ? "white" : "transparent",
                  color: location.pathname === "/RetirosClienteStgo" ? "#041562" : "white",
                  ".MuiListItemText-primary": {
                    fontSize: "0.775rem",
                  },
                  pl: isDrawerOpen ? 4 : 2,
                  "& .MuiListItemIcon-root": {
                    color: location.pathname === "/RetirosClienteStgo" ? "#041562" : "white",
                    minWidth: isDrawerOpen ? "auto" : 25,
                    mr: isDrawerOpen ? 2 : "auto",
                    justifyContent: "center",
                  },
                  "& .MuiListItemText-primary": {
                    color: location.pathname === "/RetirosClienteStgo" ? "#041562" : "white",
                  },
                  "&:hover": {
                    bgcolor: "white",
                    ".MuiListItemIcon-root": { color: "#041562" },
                    ".MuiListItemText-primary": { color: "#041562" },
                    borderRadius: "10px",
                  },
                  borderRadius: location.pathname === "/RetirosClienteStgo" ? "10px" : "0px",
                  margin: location.pathname === "/RetirosClienteStgo" ? "8px" : "0px",
                  overflow: "hidden",
                }}
              >
                <ListItemIcon>
                  <AssignmentReturnedIcon />
                </ListItemIcon>
                {isDrawerOpen && <ListItemText primary="Carga de retiros Santiago" />}
              </ListItemButton>
            )}
            {isVisible("RetirosClienteInter") && (
              <ListItemButton
                component={Link}
                to="/RetirosClienteInter"
                sx={{
                  bgcolor: location.pathname === "/RetirosClienteInter" ? "white" : "transparent",
                  color: location.pathname === "/RetirosClienteInter" ? "#041562" : "white",
                  ".MuiListItemText-primary": {
                    fontSize: "0.775rem",
                  },
                  pl: isDrawerOpen ? 4 : 2,
                  "& .MuiListItemIcon-root": {
                    color: location.pathname === "/RetirosClienteInter" ? "#041562" : "white",
                    minWidth: isDrawerOpen ? "auto" : 25,
                    mr: isDrawerOpen ? 2 : "auto",
                    justifyContent: "center",
                  },
                  "& .MuiListItemText-primary": {
                    color: location.pathname === "/RetirosClienteInter" ? "#041562" : "white",
                  },
                  "&:hover": {
                    bgcolor: "white",
                    ".MuiListItemIcon-root": { color: "#041562" },
                    ".MuiListItemText-primary": { color: "#041562" },
                    borderRadius: "10px",
                  },
                  borderRadius: location.pathname === "/RetirosClienteInter" ? "10px" : "0px",
                  margin: location.pathname === "/RetirosClienteInter" ? "8px" : "0px",
                  overflow: "hidden",
                }}
              >
                <ListItemIcon>
                  <AssignmentReturnedIcon />
                </ListItemIcon>
                {isDrawerOpen && <ListItemText primary="Carga de retiros Inter-Region" />}
              </ListItemButton>
            )}

            {shouldShowControlCediblesNovo() && (
              <ListItemButton
                component={Link}
                to="/ControlCediblesNovo"
                sx={{
                  bgcolor: location.pathname === "/ControlCediblesNovo" ? "white" : "transparent",
                  color: location.pathname === "/ControlCediblesNovo" ? "#041562" : "white",
                  ".MuiListItemText-primary": {
                    fontSize: "0.775rem",
                  },
                  pl: isDrawerOpen ? 4 : 2,
                  "& .MuiListItemIcon-root": {
                    color: location.pathname === "/ControlCediblesNovo" ? "#041562" : "white",
                    minWidth: isDrawerOpen ? "auto" : 25,
                    mr: isDrawerOpen ? 2 : "auto",
                    justifyContent: "center",
                  },
                  "& .MuiListItemText-primary": {
                    color: location.pathname === "/ControlCediblesNovo" ? "#041562" : "white",
                  },
                  "&:hover": {
                    bgcolor: "white",
                    ".MuiListItemIcon-root": { color: "#041562" },
                    ".MuiListItemText-primary": { color: "#041562" },
                    borderRadius: "10px",
                  },
                  borderRadius: location.pathname === "/ControlCediblesNovo" ? "10px" : "0px",
                  margin: location.pathname === "/ControlCediblesNovo" ? "8px" : "0px",
                  overflow: "hidden",
                }}
              >
                <ListItemIcon>
                  <AssignmentLateIcon />
                </ListItemIcon>
                {isDrawerOpen && <ListItemText primary="Control de cedible" />}
              </ListItemButton>
            )}

            {!shouldHideCediblesClientePage() && isVisible("CediblesClientePage") && (
              <ListItemButton
                component={Link}
                to="/CediblesClientePage"
                sx={{
                  bgcolor: location.pathname === "/CediblesClientePage" ? "white" : "transparent",
                  color: location.pathname === "/CediblesClientePage" ? "#041562" : "white",
                  ".MuiListItemText-primary": {
                    fontSize: "0.775rem",
                  },
                  pl: isDrawerOpen ? 4 : 2,
                  "& .MuiListItemIcon-root": {
                    color: location.pathname === "/CediblesClientePage" ? "#041562" : "white",
                    minWidth: isDrawerOpen ? "auto" : 25,
                    mr: isDrawerOpen ? 2 : "auto",
                    justifyContent: "center",
                  },
                  "& .MuiListItemText-primary": {
                    color: location.pathname === "/CediblesClientePage" ? "#041562" : "white",
                  },
                  "&:hover": {
                    bgcolor: "white",
                    ".MuiListItemIcon-root": { color: "#041562" },
                    ".MuiListItemText-primary": { color: "#041562" },
                    borderRadius: "10px",
                  },
                  borderRadius: location.pathname === "/CediblesClientePage" ? "10px" : "0px",
                  margin: location.pathname === "/CediblesClientePage" ? "8px" : "0px",
                  overflow: "hidden",
                }}
              >
                <ListItemIcon>
                  <AssignmentLateIcon />
                </ListItemIcon>
                {isDrawerOpen && <ListItemText primary="Control de cedibles" />}
              </ListItemButton>
            )}
            {isVisible("ManifiestosSalidaInhouseCliente") && (
              <ListItemButton
                component={Link}
                to="/ManifiestosSalidaInhouseCliente"
                sx={{
                  bgcolor: location.pathname === "/ManifiestosSalidaInhouseCliente" ? "white" : "transparent",
                  color: location.pathname === "/ManifiestosSalidaInhouseCliente" ? "#041562" : "white",
                  ".MuiListItemText-primary": {
                    fontSize: "0.775rem",
                  },
                  pl: isDrawerOpen ? 4 : 2,
                  "& .MuiListItemIcon-root": {
                    color: location.pathname === "/ManifiestosSalidaInhouseCliente" ? "#041562" : "white",
                    minWidth: isDrawerOpen ? "auto" : 25,
                    mr: isDrawerOpen ? 2 : "auto",
                    justifyContent: "center",
                  },
                  "& .MuiListItemText-primary": {
                    color: location.pathname === "/ManifiestosSalidaInhouseCliente" ? "#041562" : "white",
                  },
                  "&:hover": {
                    bgcolor: "white",
                    ".MuiListItemIcon-root": { color: "#041562" },
                    ".MuiListItemText-primary": { color: "#041562" },
                    borderRadius: "10px",
                  },
                  borderRadius: location.pathname === "/ManifiestosSalidaInhouseCliente" ? "10px" : "0px",
                  margin: location.pathname === "/ManifiestosSalidaInhouseCliente" ? "8px" : "0px",
                  overflow: "hidden",
                }}
              >
                <ListItemIcon>
                  <Filter1 />
                </ListItemIcon>
                {isDrawerOpen && <ListItemText primary="Manifiesto Salida Inhouse" />}
              </ListItemButton>
            )}
            {isVisible("DescargaManifiestosClientes") && (
              <ListItemButton
                component={Link}
                to="/DescargaManifiestosClientes"
                sx={{
                  bgcolor: location.pathname === "/DescargaManifiestosClientes" ? "white" : "transparent",
                  color: location.pathname === "/DescargaManifiestosClientes" ? "#041562" : "white",
                  ".MuiListItemText-primary": {
                    fontSize: "0.775rem",
                  },
                  pl: isDrawerOpen ? 4 : 2,
                  "& .MuiListItemIcon-root": {
                    color: location.pathname === "/DescargaManifiestosClientes" ? "#041562" : "white",
                    minWidth: isDrawerOpen ? "auto" : 25,
                    mr: isDrawerOpen ? 2 : "auto",
                    justifyContent: "center",
                  },
                  "& .MuiListItemText-primary": {
                    color: location.pathname === "/DescargaManifiestosClientes" ? "#041562" : "white",
                  },
                  "&:hover": {
                    bgcolor: "white",
                    ".MuiListItemIcon-root": { color: "#041562" },
                    ".MuiListItemText-primary": { color: "#041562" },
                    borderRadius: "10px",
                  },
                  borderRadius: location.pathname === "/DescargaManifiestosClientes" ? "10px" : "0px",
                  margin: location.pathname === "/DescargaManifiestosClientes" ? "8px" : "0px",
                  overflow: "hidden",
                }}
              >
                <ListItemIcon>
                  <Download />
                </ListItemIcon>
                {isDrawerOpen && <ListItemText primary="Descarga manifiestos" />}
              </ListItemButton>
            )}
          </List>
        </Collapse>
      </>
    )
  );
}

export default MenuClientes;
