import React, { useState } from "react";
import { Grid, Modal } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolderOpen } from "@fortawesome/free-solid-svg-icons";
import "react-toastify/dist/ReactToastify.css";
import Table from "react-bootstrap/Table";

const MovimientosParaOrden = ({ movimientos }) => {
  const [currentMovimiento, setCurrentMovimiento] = React.useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = (movimiento) => {
    setCurrentMovimiento(movimiento);
    setIsModalOpen(true);
  };

  /* Función para cerrar el modal */
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Grid item xs={12} sm={5} md={4} lg={12}>
      <div style={{ overflowX: "auto", maxHeight: "200px" }}>
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>Usuario</th>
              <th>Proceso</th>
              <th>Fecha de evento</th>
              <th>Datos</th>
            </tr>
          </thead>
          <tbody>
            {movimientos.map((movimiento, index) => (
              <tr key={index}>
                <td>{movimiento.NOMBRE}</td>
                <td>{movimiento.PROCESO}</td>
                <td>{movimiento.FH_SYS}</td>
                <td>
                  <FontAwesomeIcon
                    icon={faFolderOpen}
                    color="blue"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleOpenModal(movimiento.INSTRUCCION)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Modal open={isModalOpen} onClose={handleCloseModal}>
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "white",
              padding: "20px",
              boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
              borderRadius: "10px",
              maxWidth: "80%",
              maxHeight: "80%",
              overflowY: "auto",
            }}
          >
            {currentMovimiento && (
              <div style={{ textAlign: "center" }}>
                {Object.entries(JSON.parse(currentMovimiento)).map(([key, value], index) => {
                  if (
                    value &&
                    typeof value === "string" &&
                    (value.startsWith("/9") || value.match(/^[A-Za-z0-9+/=]+z/))
                  ) {
                    const imageSrc = value.startsWith("data:image") ? value : `data:image/jpeg;base64,${value}`;
                    return (
                      <div key={index}>
                        <p>{key}:</p>
                        <img
                          src={imageSrc}
                          alt={`Imagen asociada a ${key}`}
                          style={{ maxWidth: "100%", maxHeight: "200px" }}
                        />
                      </div>
                    );
                  } else {
                    return <p key={index} style={{ margin: "10px 0" }}>{`${key}: ${value}`}</p>;
                  }
                })}
              </div>
            )}
          </div>
        </Modal>
      </div>
    </Grid>
  );
};

export default MovimientosParaOrden;
