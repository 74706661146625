import axios from "axios";
/* Usa la variable de entorno para la URL base */
const BASE_API_URL = process.env.REACT_APP_BACKEND_URL;

/* Actualizamos datos con excel */
export const updateParametros = async (data) => {
  try {
    const response = await axios.post(`${BASE_API_URL}gestion/getUpdateCubicos`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching getUpdateCubicos:", error);
    throw error;
  }
};

export const insertOrdenesMedidas = async (data) => {
  try {
    const response = await axios.post(`${BASE_API_URL}manifiestosConsultas/getInsertOrdenesMedidas`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error Insert Picking:", error);
    throw error;
  }
};

export const verificarOrdenes_Medidas = async (ods) => {
  try {
    const response = await axios.post(
      `${BASE_API_URL}manifiestosConsultas/verificarOrdenes_Medidas`,

      {ods}
    );
    if (response.data.duplicados.length > 0) {
      return response.data.duplicados;
    }
    return []; 
  } catch (error) {
    console.error("Error al verificar ODs duplicadas:", error);
    return [];
  }
};

export const getOrdenBarraMedidas = async (data) => {
  try {
    const response = await axios.post(
      `${BASE_API_URL}manifiestosConsultas/getOrdenBarraMedidas`,

      data
    );
    return response.data;
  } catch (error) {
    console.error("Error obteniendo datos PT desde API.js", error);
    throw error;
  }
};

export const getOrdenesMedidasNovofarma = async (data) => {
  try {
    const response = await axios.post(`${BASE_API_URL}manifiestosConsultas/getOrdenesMedidasNovofarma`, data);
    return response.data;
  } catch (error) {
    console.error("Error obteniendo datos Ordenes desde API.js", error);
    throw error;
  }
};
