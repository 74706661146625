import React, { useState, useEffect } from "react";
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
  Checkbox,
  ListItemText,
  OutlinedInput,
  ListSubheader,
} from "@mui/material";
import { guardarCabecerasUsuario, obtenerCabecerasPersonalizadas } from "../../../services/ReportesServices";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import defaultHeaders from "../../../utils/defaultHeaders";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const HeaderSelector = ({ userId, userHeaders, onUpdateHeaders }) => {
  const [selectedHeaders, setSelectedHeaders] = useState(userHeaders || defaultHeaders);

  useEffect(() => {
    setSelectedHeaders(userHeaders.length > 0 ? userHeaders : defaultHeaders);
  }, [userHeaders]);

  const handleChange = (event) => {
    const value = event.target.value;
    setSelectedHeaders(typeof value === "string" ? value.split(",") : value);
  };

  const selectAllHeaders = () => {
    setSelectedHeaders(defaultHeaders);
  };

  const deselectAllHeaders = () => {
    setSelectedHeaders([]);
  };

  const savePreferences = async () => {
    try {
      await guardarCabecerasUsuario(userId, selectedHeaders);
      const updatedHeaders = await obtenerCabecerasPersonalizadas(userId);
      toast.success("Cabeceras guardadas con éxito");
      onUpdateHeaders(updatedHeaders); // Actualizar con cabeceras frescas del servidor
    } catch (error) {
      console.error("Error al guardar cabeceras:", error);
      toast.error("Error al guardar cabeceras");
    }
  };
  

  return (
    <FormControl fullWidth>
      <InputLabel>Selecciona Cabeceras para archivo excel</InputLabel>
      <Select
        multiple
        value={selectedHeaders}
        onChange={handleChange}
        input={<OutlinedInput label="Selecciona Cabeceras para archivo excel" />}
        renderValue={(selected) => selected.join(", ")}
        MenuProps={MenuProps}
      >
        <ListSubheader>
          <Button onClick={selectAllHeaders} color="primary">
            Seleccionar Todas
          </Button>
          <Button onClick={deselectAllHeaders} color="primary">
            Deseleccionar Todas
          </Button>
        </ListSubheader>
        {defaultHeaders.map((header) => (
          <MenuItem key={header} value={header}>
            <Checkbox checked={selectedHeaders.includes(header)} />
            <ListItemText primary={header} />
          </MenuItem>
        ))}
      </Select>
      <Button onClick={savePreferences} style={{ marginTop: 20 }}>
        Guardar Preferencias
      </Button>
    </FormControl>
  );
};

export default HeaderSelector;
