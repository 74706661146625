import React from "react";
import { Box } from "@mui/material";
import { DataGrid, esES } from "@mui/x-data-grid";
import "react-toastify/dist/ReactToastify.css";
import DeleteIcon from "@mui/icons-material/Delete";

const TablaOrdenes = ({dataTablaCarga, loading, handleDeleteButtonClick }) => {

  const rows = dataTablaCarga.map((item) => ({
    id: item.OD, 
    OD: item.OD,
    CLIENTE: item.NOMBRE_CLIENTE,
    ORIGEN: item.NOMBRE_GESTION,
    DIRECCION: item.DIRECCION_GESTION,
    COMUNA: item.NOMBRE_COMUNA,
  }));

  const columns = [
    {
      field: "ELIMINAR",
      headerName: "Eliminar",
      width: 100,
      renderCell: (params) => (
        <DeleteIcon style={{ color: "#DA251C" }} onClick={() => handleDeleteButtonClick(params.row.OD)}/>

      ),
    },
    { field: "OD", headerName: "OD", width: 150 },
    { field: "CLIENTE", headerName: "CLIENTE", width: 200 },
    { field: "ORIGEN", headerName: "ORIGEN", width: 200 },
    { field: "DIRECCION", headerName: "DIRECCIÓN", width: 300 },
    { field: "COMUNA", headerName: "COMUNA", width: 150 },
  ];
  return (
    <Box sx={{ width: "100%", height: '550px' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        loading={loading}
        checkboxSelection={false}
        disableColumnMenu={false}
        disableColumnSelector
        disableRowSelectionOnClick
        getRowId={(row) => row.OD}
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        sx={{
          "& .MuiDataGrid-virtualScroller": {
            overflowY: "auto",
          },
        }}
      />
    </Box>
  );
};

export default TablaOrdenes;
