import React, { useState } from "react";
import { Table, TableBody, TableContainer, TableHead, TableRow, Paper, Checkbox, TextField } from "@mui/material";
import { Box } from "@mui/material";
import { TablePagination } from "@mui/material";
import { StyledTableRow, StyledTableCell, StyledTableCell2 } from "./Styles";

const TablaGestorManifiesto = ({ ordenesDataTabla, setSelectedItems }) => {
  const [selectedItems, setLocalSelectedItems] = useState(new Set());
  const [containerHeight] = useState(520);
  const [page, setPage] = useState(0);
  const [searchOD, setSearchOD] = useState(""); 
  const rowsPerPage = 10;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelectedItems = new Set(ordenesDataTabla.map((item) => item.OD));
      setLocalSelectedItems(newSelectedItems);
      setSelectedItems(newSelectedItems);
      return;
    }
    setLocalSelectedItems(new Set());
    setSelectedItems(new Set()); 
  };

  const handleCheck = (id) => {
    setLocalSelectedItems((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(id)) {
        newSet.delete(id);
      } else {
        newSet.add(id);
      }
      setSelectedItems(newSet); 
      return newSet;
    });
  };

  const filteredData = ordenesDataTabla.filter((orden) => orden.OD.toString().includes(searchOD.trim()));

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const paginatedData = filteredData.slice(startIndex, endIndex);

  return (
    <div>
      <Box sx={{ padding: 2 }}>
        <TextField
          label="Buscar OD"
          variant="outlined"
          size="small"
          value={searchOD}
          onChange={(e) => setSearchOD(e.target.value)}
          fullWidth
        />
      </Box>

      <Paper style={{ width: "100%" }}>
        <TableContainer sx={{ maxHeight: containerHeight - 46 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <StyledTableCell2 padding="checkbox">
                  <Checkbox
                    indeterminate={selectedItems.size > 0 && selectedItems.size < ordenesDataTabla.length}
                    checked={ordenesDataTabla.length > 0 && selectedItems.size === ordenesDataTabla.length}
                    onChange={handleSelectAllClick}
                    edge="end"
                  />
                </StyledTableCell2>
                <StyledTableCell2>OD</StyledTableCell2>
                <StyledTableCell2>CLIENTE</StyledTableCell2>
                <StyledTableCell2>NOMBRE</StyledTableCell2>
                <StyledTableCell2>DIRECCION</StyledTableCell2>
                <StyledTableCell2>BULTOS</StyledTableCell2>
                <StyledTableCell2>COMUNA</StyledTableCell2>
                <StyledTableCell2>TIPO</StyledTableCell2>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedData.map((orden) => (
                <StyledTableRow key={orden.OD}>
                  <StyledTableCell>
                    <Checkbox edge="end" onChange={() => handleCheck(orden.OD)} checked={selectedItems.has(orden.OD)} />
                  </StyledTableCell>
                  <StyledTableCell>{orden.OD}</StyledTableCell>
                  <StyledTableCell
                    style={{
                      maxWidth: 150,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {orden.CLIENTE}
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      maxWidth: 150,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {orden.NOMBRE}
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      maxWidth: 300,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {orden.TIPO_ORDEN === "RETIRO" ? orden.DIRECCION_RETIRO || orden.DIRECCION : orden.DIRECCION}
                  </StyledTableCell>
                  <StyledTableCell>{orden.BULTOS}</StyledTableCell>
                  <StyledTableCell>
                    {orden.TIPO_ORDEN === "RETIRO" ? orden.COMUNA_ORIGEN : orden.COMUNA_DESTINO}
                  </StyledTableCell>
                  <StyledTableCell>{orden.TIPO_ORDEN}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={filteredData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            labelRowsPerPage="Página"
            rowsPerPageOptions={[10]}
          />
        </TableContainer>
      </Paper>
    </div>
  );
};

export default TablaGestorManifiesto;
