import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Container,
  Grid,
  Card,
  CardMedia,
  IconButton,
  CardContent,
  InputAdornment,
} from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers";
import { toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";
import { FloatingLabel } from "react-bootstrap";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { es } from "date-fns/locale";
import {
  obtenerComunas,
  obtenerChoferesPorComuna,
  obtenerEstados,
  createCertificacion,
  obtenerInfoOd,
} from "../../../services/EventosManualServices";
import Autocomplete from "@mui/material/Autocomplete";
import SearchIcon from "@mui/icons-material/Search";
import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";


const CreateModalEventos = ({ open, onClose, onSave }) => {
  /* accedemos al id de usuario */
  const userInfo = useSelector((state) => state.user.userInfo);
  const id_usuario = userInfo ? userInfo.id : null;
  const [fecha, setFecha] = useState(new Date());
  const [hora, setHora] = React.useState(new Date());
  const [archivos, setArchivos] = useState([]);
  const [comunas, setComunas] = useState([]);
  const [estados, setEstados] = useState([]);
  const [comunaSeleccionada, setComunaSeleccionada] = useState(null);
  const [estadosSeleccionada, setEstadosSeleccionada] = useState(null);
  const [choferes, setChoferes] = useState([]);
  const [choferSeleccionado, setChoferSeleccionado] = useState(null);
  const [nombre, setNombre] = useState("");
  const [rut, setRut] = useState("");
  const [od, setOd] = useState("");
  const [nota, setNota] = useState("");
  const [odInfo, setOdInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleFormSubmit = (event) => {
    event.preventDefault();
    fetchOdInfo();
  };

  const handleOdChange = (event) => {

    const text = event.target.value;
    if (/^\d*$/.test(text) || text === "") {
      setOd(text);
    }
  };

  const fetchOdInfo = async () => {
    if (!od) {
      toast.error("Por favor, introduce un número de OD válido.");
      return;
    }

    try {
      const info = await obtenerInfoOd(od);
      if (info) {
        setOdInfo(info);
      } else {
        toast.error("No se encontraron datos para la OD proporcionada.");
        setOdInfo(null);
      }
    } catch (error) {
      console.error("Error al obtener información de la OD:", error);
      toast.error("Error al obtener información de la OD.");
    }
  };

  const handleArchivoChange = async (event) => {
    const nuevosArchivos = Array.from(event.target.files);
    const totalArchivos = archivos.length + nuevosArchivos.length;
    if (totalArchivos > 3) {
      toast.error("Solo puedes cargar hasta 3 imágenes.");
      return;
    }

    try {
      const fotosBase64 = await Promise.all(nuevosArchivos.map((archivo) => convertirABase64(archivo)));
      setArchivos((prevArchivos) => [...prevArchivos, ...fotosBase64]);
    } catch (error) {
      console.error("Error al convertir archivos a base64:", error);
      toast.error("Error al procesar el archivo.");
    }
  };

  const handleDeleteImage = (index) => {
    setArchivos((prev) => prev.filter((_, i) => i !== index));
  };

  const renderImages = () => {
    return archivos.map((base64StringConPrefijo, index) => (
      <Card key={index} style={{ width: 100, height: 100, margin: 5, position: "relative" }}>
        <CardMedia
          component="img"
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
          image={base64StringConPrefijo}
          alt={`Imagen cargada ${index + 1}`}
        />
        <IconButton
          style={{ color: "red", position: "absolute", top: 0, right: 0 }}
          onClick={() => handleDeleteImage(index)}
        >
          <DeleteIcon />
        </IconButton>
      </Card>
    ));
  };

  const handleFechaChange = (newValue) => {
    setFecha(newValue);
  };

  const handleHoraChange = (newValue) => {
    setHora(newValue);
  };

  const formatFechaHora = () => {
    const fechaFormato = fecha.toLocaleDateString("es-ES", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    const horaFormato = hora.toLocaleTimeString("es-ES", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
    return `${fechaFormato} ${horaFormato}`;
  };

  const convertirABase64 = (archivo) => {
    return new Promise((resolve, reject) => {
      try {
        const reader = new FileReader();
        reader.readAsDataURL(archivo);
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.onerror = (error) => {
          console.error("Error al leer el archivo", error);
          reject(error);
        };
      } catch (error) {
        console.error("Error al configurar FileReader", error);
        reject(error);
      }
    });
  };

  const prepararImagenesParaEnvio = (imagenesConPrefijo) => {
    return imagenesConPrefijo.map((imagenBase64) => {
      const posicionComa = imagenBase64.indexOf(",");
      return posicionComa !== -1 ? imagenBase64.substring(posicionComa + 1) : imagenBase64;
    });
  };


  const handleSubmit = async (event) => {
    setIsLoading(true);
    event.preventDefault();
    if (!od) {
      toast.error("Por favor, ingresa un valor para OD.");
      return;
    }

    const codigoEstadoSeleccionado = estadosSeleccionada ? estadosSeleccionada.code : null;
    const fotosBase64SinPrefijo = prepararImagenesParaEnvio(archivos);

    const certificacionData = {
      OD: od,
      OD_PAPEL: od,
      COD_CHOFER: choferSeleccionado ? choferSeleccionado.COD_CHOFER : null,
      RUT: rut || "Sin rut",
      NOMBRE: nombre || "Sin nombre",
      NOTA: nota,
      LAT_TERRENO: "0",
      LONG_TERRENO: "0",
      FH_GESTION: formatFechaHora(),
      COD_ESTADO: codigoEstadoSeleccionado,
      FOTO1: fotosBase64SinPrefijo[0] || "FALSE",
      FOTO2: fotosBase64SinPrefijo.length > 1 ? fotosBase64SinPrefijo[1] : "FALSE",
      FOTO3: fotosBase64SinPrefijo.length > 2 ? fotosBase64SinPrefijo[2] : "FALSE",
      USUARIO: id_usuario,
      TN: "0",
      ORIGEN_CERTIFICACION: "CREACION EVENTO MANUAL PORTAL NUEVO",
    };
    console.log(certificacionData);
    try {
      const response = await createCertificacion(certificacionData);
      if (response.success) {
        toast.success("Operación completada con éxito");
        setEstadosSeleccionada(null);
        setNombre("");
        setOd("");
        setRut("");
        setNota("");
        setArchivos([]);
        setFecha(new Date());
        setHora(new Date());
        setComunaSeleccionada(null);
        setChoferSeleccionado(null);
        setOdInfo(null);
        setIsLoading(false);
      } else {
        toast.error(response.message || "Error desconocido");
      }
    } catch (error) {
      toast.error("Error al procesar la solicitud: " + error.message);
    }
  };

  useEffect(() => {
    const cargarEstados = async () => {
      try {
        const estadosObtenidas = await obtenerEstados();
        setEstados(estadosObtenidas);
      } catch (error) {
        console.error("Error al obtener estados:", error);
      }
    };
    cargarEstados();
  }, []);

  useEffect(() => {
    const cargarComunas = async () => {
      try {
        const comunasObtenidas = await obtenerComunas();
        setComunas(comunasObtenidas);
      } catch (error) {
        console.error("Error al obtener comunas:", error);
      }
    };
    cargarComunas();
  }, []);

  useEffect(() => {
    const cargarChoferes = async () => {
      if (comunaSeleccionada) {
        try {
          const choferesObtenidos = await obtenerChoferesPorComuna(comunaSeleccionada.IATA);
          setChoferes(choferesObtenidos);
        } catch (error) {
          console.error("Error al obtener choferes:", error);
        }
      }
    };
    cargarChoferes();
  }, [comunaSeleccionada]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
      <Container style={{ marginTop: "5px" }} maxWidth="lg">
        <Grid container spacing={3}>
          {odInfo && (
            <Grid item xs={12}>
              <Card style={{ backgroundColor: "#f7f7f7" }}>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <div
                        style={{
                          color: "#041562",
                          marginBottom: "5px",
                          fontSize: "14px",
                        }}
                      >
                        Fecha de Digitación:{" "}
                        {new Date(odInfo.FH_DIGITACION).toLocaleString("es-CL", { timeZone: "America/Santiago" })}
                      </div>
                      <div
                        style={{
                          color: "#041562",
                          marginBottom: "5px",
                          fontSize: "14px",
                        }}
                      >
                        Nombre Cliente: {odInfo.NOMBRE_CLIENTE}
                      </div>
                      <div
                        style={{
                          color: "#041562",
                          marginBottom: "5px",
                          fontSize: "14px",
                        }}
                      >
                        Nota: {odInfo.NOTA}
                      </div>
                    </Grid>
                    <Grid item xs={4}>
                      <div
                        style={{
                          color: "#041562",
                          marginBottom: "5px",
                          fontSize: "14px",
                        }}
                      >
                        OD Papel: {odInfo.OD_PAPEL}
                      </div>
                      <div
                        style={{
                          color: "#041562",
                          marginBottom: "5px",
                          fontSize: "14px",
                        }}
                      >
                        Nombre: {odInfo.NOMBRE}
                      </div>
                      <div
                        style={{
                          color: "#041562",
                          marginBottom: "5px",
                          fontSize: "14px",
                        }}
                      >
                        Rut: {odInfo.RUT}
                      </div>
                    </Grid>
                    <Grid item xs={4}>
                      <div
                        style={{
                          color: "#041562",
                          marginBottom: "5px",
                          fontSize: "14px",
                        }}
                      >
                        Dirección: {odInfo.DIRECCION}
                      </div>
                      <div
                        style={{
                          color: "#041562",
                          marginBottom: "5px",
                          fontSize: "14px",
                        }}
                      >
                        Comuna Destino: {odInfo.NOMBRE_COMUNA_DESTINO}
                      </div>
                      <div
                        style={{
                          color: "#041562",
                          marginBottom: "5px",
                          fontSize: "14px",
                        }}
                      >
                        Comuna Origen: {odInfo.NOMBRE_COMUNA_ORIGEN}
                      </div>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          )}
          <Grid item xs={12} sm={4}>
            <form onSubmit={handleFormSubmit}>
              <TextField
                label="OD"
                value={od}
                onChange={handleOdChange}
                fullWidth
                margin="dense"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton type="submit">
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </form>
            <Autocomplete
              id="combo-box-estado"
              options={estados}
              getOptionLabel={(option) => option.description || "Descripción no disponible"}
              isOptionEqualToValue={(option, value) => option.code === value.code}
              value={estadosSeleccionada}
              onChange={(event, newValue) => setEstadosSeleccionada(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Estado"
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  InputProps={{
                    ...params.InputProps,
                  }}
                />
              )}
            />
            {/* Time Picker */}
            <TimePicker
              label="Hora"
              value={hora}
              onChange={handleHoraChange}
              slotProps={{
                textField: {
                  variant: "outlined",
                  fullWidth: true,
                  margin: "dense",
                },
              }}
            />
          </Grid>
          {/* Column 2 */}
          <Grid item xs={12} sm={4}>
            <Autocomplete
              id="combo-box-comuna"
              options={comunas}
              getOptionLabel={(option) => option.NOMBRE}
              isOptionEqualToValue={(option, value) => option.IATA === value.IATA}
              value={comunaSeleccionada}
              onChange={(event, newValue) => setComunaSeleccionada(newValue)}
              renderInput={(params) => (
                <TextField {...params} label="Comuna" variant="outlined" fullWidth margin="dense" />
              )}
            />
            <Autocomplete
              id="combo-box-choferes"
              options={choferes}
              getOptionLabel={(option) => option.NOMBRE}
              value={choferSeleccionado}
              onChange={(event, newValue) => setChoferSeleccionado(newValue)}
              renderInput={(params) => (
                <TextField {...params} label="Chofer" variant="outlined" fullWidth margin="dense" />
              )}
            />
            {/* Date Picker */}
            <FloatingLabel controlId="floatingInput">
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
                  <DatePicker
                    label="Fecha"
                    format="dd/MM/yyyy"
                    value={fecha}
                    onChange={handleFechaChange}
                    slotProps={{
                      textField: {
                        variant: "outlined",
                        fullWidth: true,
                        margin: "dense",
                      },
                    }}
                  />
                </LocalizationProvider>
              </FloatingLabel>
           
          </Grid>
          {/* Column 3 */}
          <Grid item xs={12} sm={4}>
            <TextField
              label="Nombre"
              value={nombre}
              onChange={(e) => setNombre(e.target.value)}
              fullWidth
              margin="dense"
            />
            <TextField label="Rut" value={rut} onChange={(e) => setRut(e.target.value)} fullWidth margin="dense" />
            <TextField label="Nota" value={nota} onChange={(e) => setNota(e.target.value)} fullWidth margin="dense" />
          </Grid>
          <Grid item xs={12}>
            <TextField
              type="file"
              onChange={handleArchivoChange}
              fullWidth
              margin="dense"
              InputLabelProps={{
                shrink: true,
              }}
              label="Subir Imagen"
              inputProps={{ multiple: true, accept: "image/*" }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {renderImages()}
          </Grid>
        </Grid>
        <Grid container justifyContent="center" style={{ marginTop: "10px" }}>
          <Grid item xs={12}>
            <Button
              fullWidth
              margin="dense"
              variant="outlined"
              color="primary"
              onClick={handleSubmit}
              style={{
                backgroundColor: "#041562",
                color: "#FFFFFF",
                marginTop: 10,
              }}
            >
              {isLoading ? <CircularProgress sx={{ color: "#FFFFFF" }} size={24} /> : "Crear evento"}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </LocalizationProvider>
  );
};

export default CreateModalEventos;
