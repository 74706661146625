import React from "react";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  TableCell,
  TextField,
} from "@mui/material";

const TablaOrdenesSeleccion = ({
  dataTabla,
  filterText,
  setFilterText,
  selectedItems,
  handleSelectAllClick,
  filteredData,
  handleCheck,
}) => {
  const formatearFecha = (fecha) => {
    const date = new Date(fecha);
    const dia = date.getDate().toString().padStart(2, "0");
    const mes = (date.getMonth() + 1).toString().padStart(2, "0");
    const año = date.getFullYear();
    return `${dia}-${mes}-${año}`;
  };

  return (
    <TableContainer component={Paper}>
      <TextField
        placeholder="Buscar..."
        variant="outlined"
        type="search"
        size="small"
        value={filterText}
        fullWidth
        sx={{ mb: 2 }}
        onChange={(e) => setFilterText(e.target.value)}
      />
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox">
              <Checkbox
                indeterminate={selectedItems.size > 0 && selectedItems.size < dataTabla.length}
                checked={dataTabla.length > 0 && selectedItems.size === dataTabla.length}
                onChange={handleSelectAllClick}
                edge="end"
              />
            </TableCell>
            <TableCell sx={{ width: "5%" }}>OD</TableCell>
            <TableCell align="right" sx={{ width: "20%" }}>
              CLIENTE
            </TableCell>
            <TableCell align="right" sx={{ width: "30%" }}>
              DESCRIPCION
            </TableCell>
            <TableCell align="right" sx={{ width: "20%" }}>
              ORIGEN
            </TableCell>
            <TableCell align="right" sx={{ width: "30%" }}>
              DIRECCION
            </TableCell>
            <TableCell align="right" sx={{ width: "10%" }}>
              FECHA
            </TableCell>
            <TableCell align="right" sx={{ width: "15%" }}>
              COMUNA
            </TableCell>
            <TableCell align="right">ULTIMA GESTION</TableCell>
            <TableCell align="right">FECHA GESTION</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredData.map((row) => (
            <TableRow key={row.OD} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell padding="checkbox">
                <Checkbox edge="end" onChange={() => handleCheck(row.OD)} checked={selectedItems.has(row.OD)} />
              </TableCell>
              <TableCell component="th" scope="row">
                {row.OD}
              </TableCell>
              <TableCell align="right">{row.NOMBRE_CLIENTE}</TableCell>
              <TableCell align="right">{row.NOTA}</TableCell>
              <TableCell align="right">{row.NOMBRE_GESTION}</TableCell>
              <TableCell align="right">{row.DIRECCION_GESTION}</TableCell>
              <TableCell align="right">{formatearFecha(row.FH_DIGITACION)}</TableCell>
              <TableCell align="right">{row.NOMBRE_COMUNA}</TableCell>
              <TableCell align="right">{row.COD_ESTADO}</TableCell>
              <TableCell align="right">{row.FH_GESTION}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TablaOrdenesSeleccion;
