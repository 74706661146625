import React, { useState, useEffect } from "react";
import { Modal, Card, CardContent, Avatar, Typography, Divider, CardActions, Button, TextField } from "@mui/material";
import ChangePasswordModal from "./ChangePasswordModal";
import { obtenerUltimaVersionCambiar, actualizarVersionApp } from "../services/LoginServices";

const UserProfileModal = ({ open, handleClose, userInfo }) => {
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const [version, setVersion] = useState("");
  const [editVersion, setEditVersion] = useState(false);
  const [newVersion, setNewVersion] = useState("");

  useEffect(() => {
    const fetchVersion = async () => {
      try {
        const latestVersion = await obtenerUltimaVersionCambiar();
        setVersion(latestVersion);
      } catch (error) {
        console.error("Error al obtener la última versión del APK:", error);
      }
    };
    if (open) {
      fetchVersion();
    }
  }, [open]);

  const handleOpenChangePassword = () => {
    setOpenChangePassword(true);
  };

  const handleCloseChangePassword = () => {
    setOpenChangePassword(false);
  };

  const handleEditVersionClick = () => {
    setEditVersion(true);
    setNewVersion(version);
  };

  const handleSaveVersion = async () => {
    try {
      await actualizarVersionApp(newVersion);
      setVersion(newVersion);
      setEditVersion(false);
    } catch (error) {
      console.error("Error al actualizar la versión:", error);
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <>
        <Card
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            maxWidth: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 1,
            borderRadius: 2,
          }}
        >
          <CardContent>
            <Avatar
              sx={{
                bgcolor: "primary.dark",
                width: 56,
                height: 56,
                mt: 0,
                mr: "auto",
                mb: 0,
                ml: "auto",
              }}
            ></Avatar>
            <Typography variant="h5" component="h2" align="center" gutterBottom>
              Información del Perfil
            </Typography>
            <Divider variant="middle" sx={{ my: 1, backgroundColor: "#DA251C" }} />
            <Typography variant="body1" align="center">
              {userInfo.name}
            </Typography>
            <Divider variant="middle" sx={{ my: 1, backgroundColor: "#DA251C" }} />
            <Typography variant="body1" align="center">
              {userInfo.tipo_usuario}
            </Typography>
            <Divider variant="middle" sx={{ my: 1, backgroundColor: "#DA251C" }} />
            <Typography variant="body1" align="center">
              {userInfo.email}
            </Typography>
            <Divider variant="middle" sx={{ my: 1, backgroundColor: "#DA251C" }} />
            <Typography variant="body1" align="center">
              {userInfo.ESTADO}
            </Typography>
            <Divider variant="middle" sx={{ my: 1, backgroundColor: "#DA251C" }} />
            {editVersion ? (
              <CardActions sx={{ justifyContent: "center", flexDirection: "column" }}>
                <TextField
                  fullWidth
                  label="Nueva Versión"
                  value={newVersion}
                  onChange={(e) => setNewVersion(e.target.value)}
                  sx={{ mb: 2 }}
                />
                <Button variant="contained" onClick={handleSaveVersion} sx={{ mb: 1 }}>
                  Guardar
                </Button>
                <Button variant="outlined" onClick={() => setEditVersion(false)}>
                  Cancelar
                </Button>
              </CardActions>
            ) : (
              userInfo.id === 978 || userInfo.id === 1 || userInfo.id === 410 || userInfo.id === 737 || userInfo.id === 1210 ? (
                <CardActions sx={{ justifyContent: "center" }}>
                  <Button onClick={handleEditVersionClick} variant="contained">
                    Editar Versión
                  </Button>
                </CardActions>
              ) : null
            )}
          </CardContent>
          <CardActions sx={{ justifyContent: "center" }}>
            <Button style={{ backgroundColor: "primary.main" }} variant="contained" onClick={handleOpenChangePassword}>
              Cambiar Contraseña
            </Button>
          </CardActions>
          <CardActions sx={{ justifyContent: "center" }}>
            <Button style={{ backgroundColor: "#DA251C" }} variant="contained" onClick={handleClose}>
              Cerrar
            </Button>
          </CardActions>
        </Card>
        <ChangePasswordModal open={openChangePassword} handleClose={handleCloseChangePassword} userInfo={userInfo} />
      </>
    </Modal>
  );
};

export default UserProfileModal;
