import React, { useState } from "react";
import { Button, TextField, Grid, Paper, Typography, Box } from "@mui/material";
import { solicitarRestablecimiento, verificarCodigo, cambiarContraseña } from "../services/RestablecimientoServices";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import fondo from "../components/assets/img/restablecer.jpg";

function SolicitarRestablecimiento() {
  const [email, setEmail] = useState("");
  const [codigoEnviado, setCodigoEnviado] = useState(false);
  const [codigo, setCodigo] = useState(Array(6).fill(""));
  const [nuevaContraseña, setNuevaContraseña] = useState("");
  const [codigoVerificado, setCodigoVerificado] = useState(false);
  const [confirmarContraseña, setConfirmarContraseña] = useState("");
  const navigate = useNavigate();

  /* Maneja el cambio en los inputs del código */
  const handleCodigoChange = (index, value) => {
    const newCodigo = [...codigo];
    newCodigo[index] = value;
    setCodigo(newCodigo);
    if (value && index < 5) {
      document.getElementById(`codigo-${index + 1}`).focus();
    }
  };

  const handleCodigoPaste = (event) => {
    event.preventDefault();
    const clipboardData = event.clipboardData || window.clipboardData;
    const pastedText = clipboardData.getData("text");
    if (pastedText.length === 6 && /^\d+$/.test(pastedText)) {
      setCodigo(pastedText.split(""));
    } else {
      toast.error("El texto pegado debe tener una longitud de 6 y contener solo números.");
    }
  };

  const solicitudRestablecerContraseña = async (event) => {
    event.preventDefault();
    try {
      const respuesta = await solicitarRestablecimiento(email);
      if (respuesta.success) {
        setCodigoEnviado(true);
        toast.success("Código enviado con éxito, por favor revisa tu correo.");
      } else {
        toast.error(respuesta.message || "El correo electrónico proporcionado no coincide con ningún usuario.");
      }
    } catch (error) {
      console.error("Error al solicitar el restablecimiento de contraseña:", error);
      toast.error("Ocurrió un error al tratar de solicitar el restablecimiento de contraseña.");
    }
  };

  const handleVerificarCodigo = async () => {
    const codigoCompleto = codigo.join("");
    try {
      const respuesta = await verificarCodigo(email, codigoCompleto);
      if (respuesta.success) {
        setCodigoVerificado(true);
        toast.success("Código correcto, puedes cambiar tu contraseña.");
      } else {
        setCodigoVerificado(false);
        toast.error(respuesta.message || "Código ingresado incorrecto, por favor intenta nuevamente.");
      }
    } catch (error) {
      console.error("Error al verificar el código:", error);
      setCodigoVerificado(false);
      toast.error(error.response?.data?.message || "Error al verificar el código.");
    }
  };

  const handleRestablecerContraseña = async () => {
    if (nuevaContraseña !== confirmarContraseña) {
      toast.error("Las contraseñas no coinciden.");
      return;
    }
    try {
      const respuesta = await cambiarContraseña(email, nuevaContraseña);
      if (respuesta.success) {
        console.log("Contraseña restablecida con éxito");
        toast.success("Contraseña restablecida con éxito");
        navigate("/login");
      } else {
        toast.error(respuesta.message || "Error al restablecer la contraseña.");
      }
    } catch (error) {
      console.error("Error al restablecer la contraseña:", error);
      toast.error("Error al restablecer la contraseña.");
    }
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{
        minHeight: "100vh",
        backgroundImage: ` url(${fondo})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Grid item xs={10} sm={8} md={6} lg={4}>
        <Box position="relative" display="inline-block" width="100%">
          <IconButton
            onClick={() => navigate("/")}
            aria-label="volver"
            style={{
              position: "absolute",
              top: 10,
              left: 10,
              color: "#DA251C",
              zIndex: 1,
            }}
          >
            <ArrowBackIcon />
          </IconButton>
          <Paper elevation={3} style={{ padding: "20px", textAlign: "center", paddingTop: "48px" }}>
            {!codigoEnviado ? (
              <>
                {/* Formulario de solicitud de restablecimiento */}
                <Typography variant="h6" gutterBottom>
                  Solicitud de Restablecimiento de Contraseña
                </Typography>
                <form onSubmit={solicitudRestablecerContraseña}>
                  <Typography style={{ color: "#DA251C" }} variant="body2" gutterBottom>
                    Por favor ingresa tu correo electrónico y te enviaremos un código para restablecer tu contraseña.
                  </Typography>
                  <TextField
                    label="Correo electrónico"
                    type="email"
                    required
                    fullWidth
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    style={{ backgroundColor: "#041562", color: "#fff", marginTop: "20px" }}
                  >
                    Enviar código
                  </Button>
                </form>
              </>
            ) : (
              <>
                {/* Entrada para el código de verificación */}
                <Typography variant="h6" gutterBottom>
                  Ingresa el Código de Verificación
                </Typography>
                <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
                  {codigo.map((digit, index) => (
                    <TextField
                      key={index}
                      id={`codigo-${index}`}
                      type="text"
                      inputProps={{ maxLength: 1 }}
                      value={digit}
                      onPaste={handleCodigoPaste}
                      onChange={(e) => handleCodigoChange(index, e.target.value)}
                      sx={{ width: "40px" }}
                    />
                  ))}
                </Box>
                {codigoVerificado && (
                  <>
                    <TextField
                      label="Nueva Contraseña"
                      type="password"
                      required
                      fullWidth
                      value={nuevaContraseña}
                      onChange={(e) => setNuevaContraseña(e.target.value)}
                      sx={{ mt: 4 }}
                    />
                    <TextField
                      label="Confirmar Nueva Contraseña"
                      type="password"
                      required
                      fullWidth
                      value={confirmarContraseña}
                      onChange={(e) => setConfirmarContraseña(e.target.value)}
                      sx={{ mt: 2 }}
                    />
                  </>
                )}
                <Button
                  onClick={codigoVerificado ? handleRestablecerContraseña : handleVerificarCodigo}
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, backgroundColor: "#041562", color: "#fff" }}
                >
                  {codigoVerificado ? "Cambiar Contraseña" : "Verificar Código"}
                </Button>
              </>
            )}
          </Paper>
        </Box>
      </Grid>
    </Grid>
  );
}

export default SolicitarRestablecimiento;
