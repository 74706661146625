import React from "react";
import * as XLSX from "xlsx";
import Link from "@mui/material/Link";

export const ExcelDownloadLinkStgo = () => {
  const handleDownload = (e) => {
    e.preventDefault(); 
    const header = [
      "OD",
      "NOMBRE CLIENTE",
      "DIRECCION",
      "TELEFONO",
      "CIUDAD",
      "CENTRO DE COSTO",
      "DESCRIPCION DE CARGA",
      "CONTACTO",
      "BULTO",
      "KILO",
      "GUIA",
    ];

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet([header]);

    ws["!cols"] = [
      { width: 15 },
      { width: 30 },
      { width: 30 },
      { width: 10 },
      { width: 13 },
      { width: 20 },
      { width: 25 },
      { width: 15 },
      { width: 10 },
      { width: 10 },
      { width: 10 },
    ];

    ws["A2"] = { v: "Ejemplo", t: "s", s: { font: { bold: true } } }; 
    ws["B2"] = { v: 25, t: "s", s: { font: { bold: true } } }; 

    XLSX.utils.book_append_sheet(wb, ws, "Hoja1");

    XLSX.writeFile(wb, "FormatoRetirosStgo.xlsx");
  };

  return (
    <Link component="button" variant="body2" onClick={handleDownload}>
      Descargar Formato de Retiros Santiago
    </Link>
  );
};

export const ExcelDownloadLinkInter = () => {
  const handleDownload = (e) => {
    e.preventDefault(); 
    const header = [
      "OD",
      "NOMBRE ORIGEN",
      "DIRECCION ORIGEN",
      "TELEFONO ORIGEN",
      "CIUDAD ORIGEN",
      "CENTRO DE COSTO",
      "DESCRIPCION",
      "CONTACTO ORIGEN",
      "BULTO",
      "KILO",
      "GUIA",
      "NOMBRE DESTINO",
      "DIRECCION DESTINO",
      "CIUDAD DESTINO",
      "CONTACTO DESTINO",
      "TELEFONO DESTINO",
      "ALTO",
      "LARGO",
      "ANCHO",
      "VOLUMEN"
    ];

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet([header]);

    ws["!cols"] = [
      { width: 15 },
      { width: 30 },
      { width: 30 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 25 },
      { width: 20 },
      { width: 10 },
      { width: 10 },
      { width: 10 },
      { width: 30 },
      { width: 30 },
      { width: 15 },
      { width: 20 },
      { width: 20 },
      { width: 10 },
      { width: 10 },
      { width: 10 },
      { width: 10 },
    ];

    ws["A2"] = { v: "Ejemplo", t: "s", s: { font: { bold: true } } };
    ws["B2"] = { v: 25, t: "s", s: { font: { bold: true } } };

    XLSX.utils.book_append_sheet(wb, ws, "Hoja1");

    XLSX.writeFile(wb, "FormatoRetirosInter.xlsx");
  };

  return (
    <Link component="button" variant="body2" onClick={handleDownload}>
      Descargar Formato de Retiros Inter-Region
    </Link>
  );
};

