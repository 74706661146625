import React from "react";
import { Box } from "@mui/material";
import { DataGrid, esES } from "@mui/x-data-grid";
import "react-toastify/dist/ReactToastify.css";

const TablaOrdenesAgentes = ({columns, rows, loading  }) => {


  return (
    <Box sx={{ width: "100%", height: '550px' }}>
      <DataGrid
        rows={rows}
        columns={columns.map((column) => ({
          ...column,
        }))}
        loading={loading}
        checkboxSelection={false}
        disableColumnMenu={false}
        disableColumnSelector
        disableRowSelectionOnClick
        getRowId={(row) => row.OD}
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        sx={{
          "& .MuiDataGrid-virtualScroller": {
            overflowY: "auto",
          },
        }}
      />
    </Box>
  );
};

export default TablaOrdenesAgentes;
