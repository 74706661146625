import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import GestionOrdenes from "./pages/Facturacion/gestionOrdenes";
import PortalMovimientos from "./pages/Facturacion/portalMovimientos";
import ImprimirEtiquetaOrdenes from "./pages/Facturacion/ImprimirEtiquetaOrdenes";
import CorreccionOrden from "./pages/Despacho/CorreccionOrden";
import CediblesPage from "./pages/Despacho/CediblesPage";
import ControlCediblesSac from "./pages/Despacho/ControlCediblesSac";
import RetirosAppStgo from "./pages/Despacho/RetirosAppStgo";
import RetirosAppInter from "./pages/Despacho/RetirosAppInter";
import InicioSac from "./pages/Despacho/InicioSac";
import LoginPage from "./pages/LoginPage";
import InicioFacturacion from "./pages/Facturacion/InicioFacturacion";
import InicioSupervisora from "./pages/Supervisora/InicioSupervisora";
import InicioOperaciones from "./pages/Operaciones/InicioOperaciones";
import InicioAdministracion from "./pages/Administracion/inicioAdministracion";
import InicioClientes from "./pages/Clientes/InicioClientes";
import InicioGraficos from "./pages/Graficos/inicioGraficoss";
import ManifiestosSalidaInhouse from "./pages/Operaciones/ManifiestosPorOd/ManifiestosSalidaInhouse";
import ManifiestosSalidaInhouseCliente from "./pages/Clientes/ManifiestosSalidaInhouseCliente";
import ManifiestoEntradaAgente from "./pages/Operaciones/ManifiestosPorOd/ManifiestoEntradaAgente";
import ManifiestoRetornoCliente from "./pages/Operaciones/ManifiestosPorOd/ManifiestoRetornoCliente";
import InicioAgente from "./pages/Agentes/InicioAgente";
import ListaCorreos from "./pages/Operaciones/ListaCorreos";
import Listados from "./pages/Despacho/Listados";
import ListadosManifiestos from "./pages/Operaciones/ManifiestosPorOd/ListadosManifiestos";
import Eventos from "./pages/Despacho/Eventos";
import DescargaManifiestos from "./pages/Operaciones/DescargaManifiestos";
import DescargaManifiestosClientes from "./pages/Clientes/DescargaManifiestosClientes";
import PortalUsuarios from "./pages/Administracion/PortalUsuarios";
import PortalClientes from "./pages/Administracion/PortalClientes";
import CrearRetirosPrueba from "./pages/Administracion/RetirosPruebas";
import PortalChoferes from "./pages/Administracion/PortalChoferes";
import PortalVehiculos from "./pages/Administracion/PortalVehiculos";
import EstadisticasGlobal from "./pages/Administracion/EstadisticasGlobal";
import RedirectToInitialPage from "./RedirectToInitialPage";
import ReporteAgentes from "./pages/Reportes/ReporteAgentes";
import ConsultaIndividual from "./pages/Reportes/ConsultaIndividual";
import ConsultaIndividualAgentes from "./pages/Reportes/ConsultaIndividualAgentes";
import ConsultaMasivoAgentes from "./pages/Reportes/ConsultaMasivoAgentes";
import ConsultaMasivaAgentes from "./pages/Reportes/ConsultaMasivaAgentes";
import ConsultaMasivo from "./pages/Reportes/ConsultaMasivo";
import RendicionCedibles from "./pages/Despacho/RendicionCedibles";
import ManifiestoIngresoBodega from "./pages/Operaciones/ManifiestosPorOd/ManifiestoIngresoBodega";
import ManifiestoSalida from "./pages/Operaciones/ManifiestosPorOd/ManifiestoSalida";
import ManifiestosRutaRef from "./pages/Operaciones/ManifiestosPorIdReferencia/ManifiestosRutaRef";
import ManifiestoRetornoClienteRef from "./pages/Operaciones/ManifiestosPorIdReferencia/ManifiestoRetornoClienteRef";
import ManifiestoSalidaRef from "./pages/Operaciones/ManifiestosPorIdReferencia/ManifiestoSalidaRef";
import ManifiestoEntradaAgenteRef from "./pages/Operaciones/ManifiestosPorIdReferencia/ManifiestoEntradaAgenteRef";
import ManifiestosRetornoRef from "./pages/Operaciones/ManifiestosPorIdReferencia/ManifiestosRetornoRef";
import ManifiestoEntradaSantiagoRef from "./pages/Operaciones/ManifiestosPorIdReferencia/ManifiestoEntradaSantiagoRef";
import ManifiestoIngresoBodegaRef from "./pages/Operaciones/ManifiestosPorIdReferencia/ManifiestoIngresoBodegaRef";
import ManifiestosSalidaInhouseRef from "./pages/Operaciones/ManifiestosPorIdReferencia/ManifiestosSalidaInhouseRef";
import GestionMedidas from "./pages/Operaciones/ManifiestosPorIdReferencia/GestionMedidas";
import RetirosClienteStgo from "./pages/Clientes/RetirosClientesStgo.";
import RetirosClientesInter from "./pages/Clientes/RetirosClientesInter";
import CediblesClientePage from "./pages/Clientes/CediblesClientePage";
import ControlCediblesNovo from "./pages/Clientes/ControlCediblesNovo";
import ConsultaMasivoCliente from "./pages/Clientes/ConsultaMasivoCliente";
import ConsultaMasivaCliente from "./pages/Clientes/ConsultaMasivaCliente";
import ConsultaIndividualClientesCodigoBarra from "./pages/Clientes/ConsultaIndividualClientesCodigoBarra";
import ConsultaIndividualClientes from "./pages/Clientes/ConsultaIndividualClientes";
import ConsultaPage from "./pages/Reportes/ConsultaPage";
import ConsultaCedibles from "./pages/Reportes/ConsultaCedibles";
import ConsultaCodigoBarra from "./pages/Operaciones/ManifiestosPorIdReferencia/ConsultaCodigoBarra";
import CubicosOperaciones from "./pages/Operaciones/CubicosOperaciones";
import SolicitarRestablecimiento from "../src/pages/SolicitarRestablecimiento";
import RetirosSantiago from "./pages/Despacho/RetirosSantiago";
import RetirosInterRegion from "./pages/Despacho/RetirosInterRegion";
import ManifiestosRetorno from "./pages/Operaciones/ManifiestosPorOd/ManifiestosRetorno";
import ManifiestoEntradaSantiago from "./pages/Operaciones/ManifiestosPorOd/ManifiestoEntradaSantiago";
import ManifiestoRutaAgentes from "./pages/Despacho/ManifiestoRutaAgentes";
import GestionOrdenesClientes from "./pages/Clientes/gestionOrdenesClientes";
import ListadoPendientes from "./pages/Operaciones/ListadoPendientes";
import ReportabilidadGeneral from "./pages/Graficos/ReportabilidadGeneral";
import ReportabilidadAgentes from "./pages/Graficos/ReportabilidadAgentes";
import ReportabilidadEjecutiva from "./pages/Graficos/ReportabilidadEjecutiva";
import ControlSupervisora from "./pages/Administracion/ControlSupervisora";
import AsignacionClientesEjecutivas from "./pages/Supervisora/asignacionClientesEjecutivas";
import RendicionCediblesViejo from "./pages/Despacho/RendicionCediblesViejo";
import DescargaNovofarmaRango from "./pages/Facturacion/DescargaNovofarmaRango";
import EdicionAgenteOrden from "./pages/Despacho/EdicionAgenteOrden";
import ClientesControlCalidad from "./pages/ControlCalidad/ClientesControlCalidad";
import GestorOrdenesMasivas from "./pages/Facturacion/GestorOrdenesMasivas";
import GestorManifiestoSalida from "./pages/Supervisor/GestorManifiestoSalida";
import Sidebar from "./routes/Sidebar";
import { useSelector } from "react-redux";
import { CssBaseline, Box, useTheme, useMediaQuery } from "@mui/material";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import { useDispatch } from "react-redux";
import { login, logout } from "./components/componentesRedux/userSlice";
import validarToken from "./services/authService";
import { toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import InicioJefatura from "./pages/Jefatura/inicioJefatura";
import InicioControlCalidad from "./pages/ControlCalidad/inicioControlCalidad";

function App() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isAuthenticating, setIsAuthenticating] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const validarYActualizarUsuario = async () => {
        setIsAuthenticating(true);
        const resultado = await validarToken(token);
        if (resultado.exito) {
          dispatch(login(resultado.datos.data));
        } else {
          toast.warning(
            "¡Oh no! Parece que tu sesión ha expirado. Por favor, inicia sesión nuevamente para continuar.",
          );
          console.error(resultado.error);
          dispatch(logout());
          localStorage.removeItem("token");
        }
        setIsAuthenticating(false);
      };
      validarYActualizarUsuario();
    } else {
      setIsAuthenticating(false);
    }
  }, [dispatch]);

  const handleDrawerOpenChange = (isOpen) => {
    setIsDrawerOpen(isOpen);
  };

  const mainContentStyle = {
    flexGrow: 1,
    p: 3,
    width: isDesktop ? "1300px" : "100%",
    marginLeft: isDrawerOpen ? (isDesktop ? "5px" : theme.spacing(7) + 1) : 0,
    marginTop: isMobile ? theme.spacing(8) : 0,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  };

  if (isAuthenticating) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" height="100vh">
        <CircularProgress size={80} />
      </Box>
    );
  }
  return (
    <>
      <ToastContainer autoClose={4000} />
      <Router>
        <CssBaseline />
        {isLoggedIn ? (
          <>
            <Box sx={{ display: "flex", width: "100%", height: "100vh" }}>
              <Sidebar onDrawerOpenChange={handleDrawerOpenChange} isDrawerOpen={isDrawerOpen} />
              <Box component="main" sx={mainContentStyle}>
                <Routes>
                  {/* Ruta principal para redireccionar a la página inicial correspondiente */}
                  <Route path="/" element={<RedirectToInitialPage />} />
                  {/* Rutas Sac */}
                  <Route path="/inicio-sac" element={<InicioSac />} />
                  <Route path="/RetirosAppStgo" element={<RetirosAppStgo />} />
                  <Route path="/RetirosAppInter" element={<RetirosAppInter />} />
                  <Route path="/controlCedibles" element={<ControlCediblesSac />} />
                  <Route path="/cedibles" element={<CediblesPage />} />
                  <Route path="/correccionOrden" element={<CorreccionOrden />} />
                  <Route path="/RendicionCedibles" element={<RendicionCedibles />} />
                  <Route path="/RendicionCediblesViejo" element={<RendicionCediblesViejo />} />
                  <Route path="/Listados" element={<Listados />} />
                  <Route path="/Eventos" element={<Eventos />} />
                  <Route path="/RetirosSantiago" element={<RetirosSantiago />} />
                  <Route path="/RetirosInterRegion" element={<RetirosInterRegion />} />
                  <Route path="/ManifiestoRutaAgentes" element={<ManifiestoRutaAgentes />} />
                  <Route path="/EdicionAgenteOrden" element={<EdicionAgenteOrden />} />
                  {/* Rutas supervisora */}
                  <Route path="/inicio-supervisora" element={<InicioSupervisora />} />
                  {/* Rutas Facturacion */}
                  <Route path="/inicio-facturacion" element={<InicioFacturacion />} />
                  <Route path="/ordenes" element={<GestionOrdenes />} />
                  <Route path="/portalMovimientos" element={<PortalMovimientos />} />
                  <Route path="/ImprimirEtiquetaOrdenes" element={<ImprimirEtiquetaOrdenes />} />
                  <Route path="/DescargaNovofarmaRango" element={<DescargaNovofarmaRango />} />
                  <Route path="/GestorOrdenesMasivas" element={<GestorOrdenesMasivas />} />
                  {/* Rutas operaciones */}
                  <Route path="/inicio-operaciones" element={<InicioOperaciones />} />
                  <Route path="/ListaCorreos" element={<ListaCorreos />} />
                  <Route path="/CubicosOperaciones" element={<CubicosOperaciones />} />
                  <Route path="/DescargaManifiestos" element={<DescargaManifiestos />} />
                  <Route path="/ListadosManifiestos" element={<ListadosManifiestos />} />
                  <Route path="/ListadoPendientes" element={<ListadoPendientes />} />
                  <Route path="/ManifiestoIngresoBodega" element={<ManifiestoIngresoBodega />} />
                  <Route path="/ManifiestoSalida" element={<ManifiestoSalida />} />
                  <Route path="/ManifiestosSalidaInhouse" element={<ManifiestosSalidaInhouse />} />
                  <Route path="/ManifiestoEntradaAgente" element={<ManifiestoEntradaAgente />} />
                  <Route path="/ManifiestoEntradaSantiago" element={<ManifiestoEntradaSantiago />} />
                  <Route path="/ManifiestoRetornoCliente" element={<ManifiestoRetornoCliente />} />
                  <Route path="/ManifiestosRetorno" element={<ManifiestosRetorno />} />
                  <Route path="/ManifiestoRutaRef" element={<ManifiestosRutaRef />} />
                  <Route path="/ManifiestoRetornoClienteRef" element={<ManifiestoRetornoClienteRef />} />
                  <Route path="/ManifiestoSalidaRef" element={<ManifiestoSalidaRef />} />
                  <Route path="/ManifiestoEntradaAgenteRef" element={<ManifiestoEntradaAgenteRef />} />
                  <Route path="/ManifiestosRetornoRef" element={<ManifiestosRetornoRef />} />
                  <Route path="/ManifiestoEntradaSantiagoRef" element={<ManifiestoEntradaSantiagoRef />} />
                  <Route path="/ManifiestoIngresoBodegaRef" element={<ManifiestoIngresoBodegaRef />} />
                  <Route path="/ManifiestosSalidaInhouseRef" element={<ManifiestosSalidaInhouseRef />} />
                  <Route path="/ConsultaCodigoBarra" element={<ConsultaCodigoBarra />} />
                  <Route path="/GestionMedidas" element={<GestionMedidas />} />
                  {/* Rutas supervisor */}
                  <Route path="/GestorManifiestoSalida" element={<GestorManifiestoSalida />} />
                  {/* Rutas agente */}
                  <Route path="/Inicio-agente" element={<InicioAgente />} />
                  {/* Rutas administracion */}
                  <Route path="/inicio-administracion" element={<InicioAdministracion />} />
                  <Route path="/PortalUsuarios" element={<PortalUsuarios />} />
                  <Route path="/PortalClientes" element={<PortalClientes />} />
                  <Route path="/CrearRetirosPrueba" element={<CrearRetirosPrueba />} />
                  <Route path="/PortalChoferes" element={<PortalChoferes />} />
                  <Route path="/EstadisticasGlobal" element={<EstadisticasGlobal />} />
                  <Route path="/ControlSupervisora" element={<ControlSupervisora />} />
                  <Route path="/AsignacionClientesEjecutivas" element={<AsignacionClientesEjecutivas />} />
                  <Route path="/PortalVehiculos" element={<PortalVehiculos />} />
                  {/* Rutas reportes */}
                  <Route path="/reporteAgentes" element={<ReporteAgentes />} />
                  <Route path="/ConsultaMasivo" element={<ConsultaMasivo />} />
                  <Route path="/ConsultaMasivoAgentes" element={<ConsultaMasivoAgentes />} />
                  <Route path="/ConsultaMasivaAgentes" element={<ConsultaMasivaAgentes />} />
                  <Route path="/ConsultaIndividual" element={<ConsultaIndividual />} />
                  <Route path="/ConsultaIndividualAgentes" element={<ConsultaIndividualAgentes />} />
                  <Route path="/ConsultaPage" element={<ConsultaPage />} />
                  <Route path="/ConsultaCedibles" element={<ConsultaCedibles />} />
                  {/* Rutas clientes */}
                  <Route path="/inicio-clientes" element={<InicioClientes />} />
                  <Route path="/RetirosClienteStgo" element={<RetirosClienteStgo />} />
                  <Route path="/RetirosClienteInter" element={<RetirosClientesInter />} />
                  <Route path="/CediblesClientePage" element={<CediblesClientePage />} />
                  <Route path="/ControlCediblesNovo" element={<ControlCediblesNovo />} />
                  <Route path="/ConsultaMasivoCliente" element={<ConsultaMasivoCliente />} />
                  <Route path="/ConsultaMasivaCliente" element={<ConsultaMasivaCliente />} />
                  <Route path="/GestionOrdenesClientes" element={<GestionOrdenesClientes />} />
                  <Route path="/ConsultaIndividualClientes" element={<ConsultaIndividualClientes />} />
                  <Route path="/ManifiestosSalidaInhouseCliente" element={<ManifiestosSalidaInhouseCliente />} />
                  <Route path="/DescargaManifiestosClientes" element={<DescargaManifiestosClientes />} />
                  <Route
                    path="/ConsultaIndividualClientesCodigoBarra"
                    element={<ConsultaIndividualClientesCodigoBarra />}
                  />
                  {/* Rutas graficos */}
                  <Route path="/inicio-graficos" element={<InicioGraficos />} />
                  <Route path="/ReportabilidadEjecutiva" element={<ReportabilidadEjecutiva />} />
                  <Route path="/ReportabilidadGeneral" element={<ReportabilidadGeneral />} />
                  <Route path="/ReportabilidadAgentes" element={<ReportabilidadAgentes />} />
                  {/* Rutas jefatura */}
                  <Route path="/inicio-jefatura" element={<InicioJefatura />} />
                  {/* Rutas control calidad */}
                  <Route path="inicio-controlCalidad" element={<InicioControlCalidad />} />
                  <Route path="ClientesControlCalidad" element={<ClientesControlCalidad />} />
                </Routes>
              </Box>
            </Box>
          </>
        ) : (
          <Routes>
            {/* Rutas accesibles sin necesidad de autenticación */}
            <Route path="/login" element={<LoginPage />} />
            <Route path="/solicitarRestablecimiento" element={<SolicitarRestablecimiento />} />
            {/* Redirección por defecto para usuarios no autenticados */}
            <Route path="*" element={<LoginPage />} />
          </Routes>
        )}
      </Router>
    </>
  );
}

export default App;
