import React, { useState, useMemo } from "react";
import { Box, TextField } from "@mui/material";
import { DataGrid, esES } from "@mui/x-data-grid";

const TableMailGestor = ({ columns, rows, loading, searchField }) => {
  const [searchText, setSearchText] = useState("");

  const filteredRows = useMemo(() => {
    const searchLowercase = searchText.toLowerCase();
    return rows.filter((row) => {
      const matchManifiesto = row.asunto.match(/N° (\d+)/);
      const numeroManifiesto = matchManifiesto ? matchManifiesto[1] : "";
      const searchValueDestinatario = row.destinatario.toLowerCase().includes(searchLowercase);
      const searchValueManifiesto = numeroManifiesto.includes(searchLowercase);
      return searchValueDestinatario || searchValueManifiesto;
    });
  }, [searchText, rows]);

  return (
    <Box sx={{ height: 580, width: 1 }}>
      <TextField
        fullWidth
        size="small"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        variant="outlined"
        placeholder={`Buscar por N° de manifiesto o por destinatario...`}
        sx={{ mb: 1 }}
      />
      <DataGrid
        rows={filteredRows}
        columns={columns.map((column) => ({
          ...column,
        }))}
        pageSize={5}
        loading={loading}
        checkboxSelection={false}
        autoPageSize={true}
        disableColumnMenu={true}
        disableColumnSelector
        disableRowSelectionOnClick
        disableColumnFilter
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        sx={{
          ".MuiDataGrid-virtualScroller": {
            overflowX: "auto",
          },
        }}
      />
    </Box>
  );
};

export default TableMailGestor;

