import axios from "axios";
const BASE_API_URL = process.env.REACT_APP_BACKEND_URL;

/* iniciar sesion */
export const iniciarSesion = async (email, password) => {
  try {
    const response = await axios.post(`${BASE_API_URL}login/iniciarSesion`, {
      email,
      password,
    });
    return response.data;
  } catch (error) {
    console.error("Error en iniciarSesion:", error);
    throw error;
  }
};

/* Obtener la última versión */
export const obtenerUltimaVersionCambiar = async () => {
  try {
    const response = await axios.get(`${BASE_API_URL}appMovil/obtenerUltimaVersionCambiar`);
    return response.data.version;
  } catch (error) {
    console.error("Error en obtenerUltimaVersion:", error);
    throw error;
  }
};

/* Actualizar la versión */
export const actualizarVersionApp = async (nuevaVersion) => {
  try {
    const response = await axios.put(`${BASE_API_URL}appMovil/actualizarVersion`, {
      version: nuevaVersion,
    });
    return response.data;
  } catch (error) {
    console.error("Error en actualizarVersion:", error);
    throw error;
  }
};
