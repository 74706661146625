import React, { memo, useEffect, useState } from "react";
import { Container, Grid, Typography, Card, CardContent, Box, Button, Modal } from "@mui/material";
import TableEjecutivas from "../../components/AdministracionComponentes/componentesEjecutivas/tableEjecutivas";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SeguimientoEjecutivas = ({ datos }) => {
  const [customerData, setCustomerData] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState({ rows: [], title: "" });

  const columns = [
    { field: "CLIENTE", headerName: "Cliente", width: 200 },
    { field: "OD_PAPEL", headerName: "OD", width: 100 },
    { field: "FH_DIGITACION", headerName: "Fecha Digitacion", width: 160 },
    { field: "NOMBRE", headerName: "Nombre", width: 150 },
    { field: "DIRECCION", headerName: "Direccion", width: 200 },
    { field: "BULTOS", headerName: "Bultos", width: 130 },
    { field: "COMUNA_ORIGEN_NOMBRE", headerName: "Comuna Origen", width: 130 },
    { field: "COMUNA_ORIGEN", headerName: "Iata Origen", width: 130 },
    { field: "COMUNA_DESTINO_NOMBRE", headerName: "Comuna Destino", width: 130 },
    { field: "COMUNA", headerName: "Iata Destino", width: 130 },
    { field: "TIPO_ORDEN", headerName: "Tipo De Orden", width: 130 },
    { field: "TIENE_ENTREGA", headerName: "Tiene entrega?", width: 130 },
    { field: "TIENE_EVENTO", headerName: "Tiene evento?", width: 130 },
    { field: "DESCRIPCION_EVENTO", headerName: "Evento", width: 130 },
  ];

  const handleClose = () => setOpenModal(false);

  const handleClick = (type, data) => {
    if (!data || data.length === 0) {
      toast.error("No hay datos para mostrar para " + type);
      return;
    }
    const filteredRows = data.map((d) => ({
      CLIENTE: d.CLIENTE,
      OD_PAPEL: d.OD_PAPEL,
      FH_DIGITACION: d.FH_DIGITACION,
      NOMBRE: d.NOMBRE,
      DIRECCION: d.DIRECCION,
      BULTOS: d.BULTOS,
      COMUNA_ORIGEN_NOMBRE: d.COMUNA_ORIGEN_NOMBRE,
      COMUNA_ORIGEN: d.COMUNA_ORIGEN,
      COMUNA_DESTINO_NOMBRE: d.COMUNA_DESTINO_NOMBRE,
      COMUNA: d.COMUNA,
      TIPO_ORDEN: d.TIPO_ORDEN,
      TIENE_ENTREGA: d.TIENE_ENTREGA,
      TIENE_EVENTO: d.TIENE_EVENTO,
      DESCRIPCION_EVENTO: d.DESCRIPCION_EVENTO,
    }));
    setModalData({ rows: filteredRows, title: type });
    setOpenModal(true);
  };

  const nextPage = () => {
    setPageIndex((prev) => (prev + 1) % customerData.length);
  };

  const prevPage = () => {
    setPageIndex((prev) => (prev - 1 + customerData.length) % customerData.length);
  };

  useEffect(() => {
    const data = {};
    datos.forEach((dato) => {
      const cliente = dato.CLIENTE;
      const ejecutiva = dato.EJECUTIVA || "No asignada";
      if (!data[ejecutiva]) {
        data[ejecutiva] = { clientes: [], totalOrdenes: 0, totalClientes: 0 };
      }
      let clienteFound = data[ejecutiva].clientes.find((c) => c.cliente === cliente);
      if (!clienteFound) {
        clienteFound = {
          cliente,
          pendientes: 0,
          enTransito: 0,
          cerrados: 0,
          total: 0,
          pendientesData: [],
          enTransitoData: [],
          cerradosData: [],
        };
        data[ejecutiva].clientes.push(clienteFound);
        data[ejecutiva].totalClientes++;
      }
      if (dato.TIENE_ENTREGA === "SI") {
        clienteFound.cerrados++;
        clienteFound.cerradosData.push(dato);
      } else if (dato.TIENE_ENTREGA === "NO" && dato.TIENE_EVENTO === "SI") {
        clienteFound.enTransito++;
        clienteFound.enTransitoData.push(dato);
      } else if (dato.TIENE_ENTREGA === "NO" && dato.TIENE_EVENTO === "NO") {
        clienteFound.pendientes++;
        clienteFound.pendientesData.push(dato);
      }
  
      clienteFound.total++;
      data[ejecutiva].totalOrdenes++;
    });
  
    setCustomerData(
      Object.keys(data).map((key) => ({
        ejecutiva: key,
        totalClientes: data[key].totalClientes,
        totalOrdenes: data[key].totalOrdenes,
        clientes: data[key].clientes.map((client) => ({
          ...client,
          efectividad: client.total > 0 ? ((client.cerrados / client.total) * 100).toFixed(2) : 0,
        })),
      }))
    );
  }, [datos]);
  

  return (
    <Container
      maxWidth={false}
      sx={{
        height: "90vh",
        padding: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography variant="h6" gutterBottom sx={{ textAlign: "center", marginTop: 5 }}>
        Ejecutiva: {customerData[pageIndex]?.ejecutiva} / Total de Órdenes: {customerData[pageIndex]?.totalOrdenes} /
        Total de Clientes: {customerData[pageIndex]?.totalClientes}
      </Typography>
      <Grid container spacing={2} className="customer-grid" sx={{ width: "100%", overflow: "auto" }}>
        {customerData[pageIndex]?.clientes.map((client, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={`${client.cliente}-${index}`}>
            <Card raised sx={{ height: "90%" }} className={`customer-card ${pageIndex % 2 === 0 ? "flip-card" : ""}`}>
              <CardContent
                sx={{ padding: 1, display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center" }}
                className="card-content"
              >
                <Typography variant="subtitle2" noWrap sx={{ textAlign: "center", width: "100%" }}>
                  {client.cliente}
                </Typography>
                <Typography
                  variant="caption"
                  sx={{ textAlign: "center", width: "100%", color: "#333", fontSize: "0.8rem" }}
                >
                  Total Órdenes: {client.total}
                </Typography>
                <Button
                  onClick={() => handleClick("Pendientes", client.pendientesData)}
                  sx={{color: "#DA251C", mt: 0.1, textAlign: "center" }}
                >
                  Pendientes: {client.pendientes}
                </Button>
                <Button
                  onClick={() => handleClick("En tránsito", client.enTransitoData)}
                  sx={{color: "#041562", mt: 0.1, textAlign: "center" }}
                >
                  En tránsito: {client.enTransito}
                </Button>
                <Button
                  onClick={() => handleClick("Cerrados", client.cerradosData)}
                  sx={{color: "#4fa662", mt: 0.1, textAlign: "center" }}
                >
                  Cerrados: {client.cerrados}
                </Button>
                <Typography
                  variant="h6"
                  color="primary"
                  sx={{ fontWeight: "bold", textAlign: "center", fontSize: "0.875rem", mt: 1 }}
                >
                  Efectividad: {client.efectividad}%
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Box sx={{ display: "flex", justifyContent: "center", gap: 2, marginTop: 2 }}>
        <Button variant="outlined" onClick={prevPage}>
          Anterior
        </Button>
        <Button variant="outlined" onClick={nextPage}>
          Siguiente
        </Button>
      </Box>
      <Modal open={openModal} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 800,
            maxHeight: "90vh",
            overflow: "auto",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <TableEjecutivas columns={columns} rows={modalData.rows} loading={false} title={modalData.title} />
        </Box>
      </Modal>
    </Container>
  );  
};

export default memo(SeguimientoEjecutivas);



