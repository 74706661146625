import React from "react";
import { Autocomplete, TextField, Paper, Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

export const AutocompletePersonalizado = ({
  selectedValue,
  setSelectedValue,
  options,
  onChange,
  label = "Seleccione una opción",
  disableClearable = true,
  size = "small",
}) => {
  return (
    <Autocomplete
      disableClearable={disableClearable}
      value={selectedValue}
      disablePortal
      id="autocomplete-personalizado"
      size={size}
      options={options}
      onChange={onChange}
      sx={{ width: "100%" }}
      PaperComponent={({ children }) => (
        <Paper
          sx={{
            width: "100%",
            textAlign: "left",
            "& li": { textAlign: "left" },
          }}
        >
          {children}
        </Paper>
      )}
      renderInput={(params) => <TextField {...params} label={label} />}
      isOptionEqualToValue={(option, value) => option.value === value.value}
    />
  );
};

export const TextFieldPersonalizado = ({ label, value, onChange, disabled = false, multiline = false, rows = 1 }) => (
  <TextField
    size="small"
    label={label}
    value={value}
    onChange={onChange}
    disabled={disabled}
    fullWidth
    multiline={multiline}
    rows={rows}
  />
);

export const BotonPersonalizado = ({
  label,
  onClick,
  color,
  disabled = false,
  loading = false,
  size = "medium",
  fullWidth = false,
}) => {
  return (
    <Button
      variant="contained"
      size={size}
      sx={{
        backgroundColor: color || "#041562",
        color: "#FFFFFF",
        ":hover": {
          backgroundColor: color ? color : "#334A92",
        },
        width: fullWidth ? "100%" : "auto",
      }}
      onClick={onClick}
      disabled={disabled || loading}
    >
      {loading ? <CircularProgress sx={{ color: "#FFFFFF" }} size={24} /> : label}
    </Button>
  );
};
