import {
  toastCabecera,
} from "./Alertas";
import Backdrop from "@mui/material/Backdrop";
import { RedTransparentBackground } from "./Progress";


export const obtenerFechaActualFormateada = () => {
  const fechaActual = new Date();

  const year = fechaActual.getFullYear();
  const month = String(fechaActual.getMonth() + 1).padStart(2, "0");
  const day = String(fechaActual.getDate()).padStart(2, "0");

  const fechaFormateada = `${year}-${month}-${day}`;

  return fechaFormateada;
};

export const limiteDeFilas = (row) => {
  for (const column of Object.values(row)) {
    if (column) {
      return true;
    }
  }
  return false;
};

export const ValidadorArchivo = (headersMatch) => {
  const condition5 = headersMatch;

 if (!condition5) {
    return toastCabecera();
  } else {
    return true;
  }
};



export const LinearProgressOverlay = ({ isVisible, progress, completed }) => {
  return (
    <div>
      <Backdrop
        open={isVisible}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, color: "#fff" }}
      >
        <RedTransparentBackground progress={progress} completed={completed} />
      </Backdrop>
    </div>
  );
};


