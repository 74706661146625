import React, { useState, memo, useRef } from "react";
import { Grid, Button, Card, CardContent } from "@mui/material";
import Tabla from "../componentesMasivos/Tabla";
import * as XLSX from "xlsx";
import Papa from "papaparse";
import { createCertificacion } from "../../../services/EventosManualServices";
import {
  toastDatosEnBlanco,
  certificacioneNoCreada,
  certificacioneCreadas,
} from "../componentesMasivos/Alertas";
import { Form, Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  ValidadorArchivo,
  limiteDeFilas,
  LinearProgressOverlay,
  convertDateToInputFormat,
} from "../componentesMasivos/Funciones";
import "../../assets/eventos.css";
import { useSelector } from "react-redux";

const CreateModalEventosMasivo = () => {
  const userInfo = useSelector((state) => state.user.userInfo);
  const id_usuario = userInfo ? userInfo.id : null;
  const [key, setKey] = useState(Date.now());
  const fileInputRef = useRef(null);
  const [data, setData] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [progress, setProgress] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [jsonDataArray, setJsonDataArray] = useState([]);
  const [isbtnEnviar, setIsbtnEnviar] = useState(true);

  // La cabecera predefinida, excel debe terner los mismo datos.
  const predefinedHeaders = ["ORDEN", "NOMBRE", "RUT", "NOTA", "CODIGO EVENTO", "FECHA Y HORA"];

  /* funcion para validar la fecha correctamente */
  function validarFormatoFechaHora(fechaHora) {
    const regex = /^\d{2}\/\d{2}\/\d{4} \d{1,2}:\d{2}:\d{2}$/;
    return regex.test(fechaHora);
  }

  // Las columnas obligatorias segun cabecera
  const obligatoriasHeaders = ["ORDEN", "CODIGO EVENTO", "FECHA Y HORA"];

  //Funcion validar que la cabecera del excel sea la misma del codigo
  const validateHeaders = (parsedData, predefinedHeaders) => {
    const fileHeaders = parsedData.data[0];
    
    console.log("Encabezados del archivo CSV:", fileHeaders);
    console.log("Encabezados predefinidos:", predefinedHeaders);
    
    for (let i = 0; i < predefinedHeaders.length; i++) {
      if (fileHeaders[i] !== predefinedHeaders[i]) {
        return false;
      }
    }
    return true;
  };


  //Carga de Excel y sus validaciones
  const cargaExcelTabla = async (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsBinaryString(file);

    await new Promise((resolve) => {
      reader.onload = async (event) => {
        const workbook = XLSX.read(event.target.result, {
          type: "binary",
          dateNF: "dd-mm-yyyy h:mm",
        });
        const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
        const csvData = XLSX.utils.sheet_to_csv(firstSheet);

        Papa.parse(csvData, {
          complete: async (parsedData) => {
            const headersMatch = validateHeaders(parsedData, predefinedHeaders);

            if (ValidadorArchivo(headersMatch)) {
              Papa.parse(csvData, {
                complete: (parsedData) => {
                  const newHeaders = predefinedHeaders;
                  setHeaders(newHeaders);

                  const dataSinObjetosVacios = parsedData.data
                    .slice(0)
                    .map((row) => {
                      let obj = {};
                      newHeaders.forEach((header, index) => {
                        obj[header] = row[header];
                      });

                      return obj;
                    })
                    .filter((obj) => {
                      for (const key in obj) {
                        if (obj[key] !== "") {
                          return true; 
                        }
                      }
                      return false; 
                    });

                  setData(dataSinObjetosVacios);

                  parsedData.data.slice(0).forEach((row, index) => {
                    const rowData = {};
                    rowData["OD"] = row["ORDEN"];
                    rowData["OD_PAPEL"] = row["ORDEN"];
                    rowData["NOMBRE"] = row["NOMBRE"];
                    rowData["RUT"] = row["RUT"];
                    rowData["DIRECCION"] = row["DIRECCION"];
                    rowData["COD_ESTADO"] = row["CODIGO EVENTO"];
                    rowData["COMUNA_ORIGEN"] = row["COMUNA ORIGEN"];
                    rowData["NOTA"] = row["NOTA"];

                    rowData["FH_GESTION"] = convertDateToInputFormat(
                      row["FECHA Y HORA"].toString().replace(/-/g, "/")
                    );

                    const fechaHora = row["FECHA Y HORA"];
                    if (!validarFormatoFechaHora(fechaHora)) {
                      console.log("fechita rara");
                      return;
                    }

                    rowData["FH_GESTION"] = convertDateToInputFormat(fechaHora.toString().replace(/-/g, "/"));
                    rowData["TN"] = "";
                    rowData["TIPO_CERTIFICACION"] = "NORMAL";
                    rowData["MULTIENTREGA"] = "TRUE";
                    rowData["ORIGEN_CERTIFICACION"] = "PORTAL EVENTOS MASIVOS";
                    rowData["USUARIO"] = id_usuario;
                    rowData["FOTO1"] = "FALSE";
                    rowData["FOTO2"] = "FALSE";
                    rowData["FOTO3"] = "FALSE";

                    if (limiteDeFilas(row)) {
                      jsonDataArray.push(JSON.stringify(rowData));
                    }
                  });

                  for (let rowIndex = 0; rowIndex < parsedData.data.length; rowIndex++) {
                    const row = parsedData.data[rowIndex];
                    if (limiteDeFilas(row)) {
                      const columnasFaltantes = obligatoriasHeaders.filter((columna) => !row[columna]);
                      if (columnasFaltantes.length > 0) {
                        toastDatosEnBlanco(columnasFaltantes.join(", "), rowIndex + 2);
                        e.target.value = "";
                        setData([]);
                        setJsonDataArray([]);
                        return;
                      } else {
                        resolve();
                      }
                    }
                  }
                },
                header: true,
              });

              setProgress(100);
              setIsVisible(false);
              setProgress(0);
              setCompleted(false);
              setIsbtnEnviar(false);
              console.log("DATOs:",jsonDataArray);
            } else {
              e.target.value = "";
              setKey(Date.now());
              fileInputRef.current.value = "";
              setData([]);
              setJsonDataArray([]);
            }
          },
        });
      };
    });
  };

  //Limpiamos Array
  const LimpiaArray = (e) => {
    setJsonDataArray([]);
  };

  //Boton Cancelar
  const Cancelar = () => {
    setKey(Date.now());
    fileInputRef.current.value = "";
    setData([]);
    setJsonDataArray([]);
    setIsbtnEnviar(true);
  };


  //Creacion de ordenes, desde el boton que viene por defecto no habilitado
  const crearEventosMasivos = async () => {
    const totalOrders = jsonDataArray.length;
    let createdOrdersCount = 0;

    for (const jsonData of jsonDataArray) {
      try {
        setIsVisible(true);

        await createCertificacion(jsonData);

        createdOrdersCount++;
        const newProgress = (createdOrdersCount / totalOrders) * 100;
        setProgress(newProgress);
      } catch (error) {
        toast.error("Error al crear certificación. Por favor, inténtalo de nuevo.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    }

    setProgress(100);

    if (createdOrdersCount > 0) {
      certificacioneCreadas();
      setIsVisible(false);
      setProgress(0);
      setCompleted(false);

      setTimeout(() => {
        setKey(Date.now());
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }

        setData([]);
        setJsonDataArray([]);
        setIsbtnEnviar(true); 

      }, 2000);
    } else {
      certificacioneNoCreada();
    }
  };

  return (
    <div>
      <Card style={{ margin: "40px" }}>
        <CardContent>
          <Form>
            <Row className="mb-2">
              <Col xs={6} md={4} className="mb-3 mb-md-0 d-flex justify-content-center align-items-center">
                <div className="same-size-container">
                  <Form.Group controlId="formFile">
                    <Form.Label className="tu-archivo-de-estilos1">Seleccione Archivo Excel</Form.Label>
                    <Form.Control
                      type="file"
                      ref={fileInputRef}
                      key={key}
                      onChange={cargaExcelTabla}
                      onClick={LimpiaArray}
                      size="sm"
                    />
                  </Form.Group>
                </div>
              </Col>

              <Col xs={6} md={4} className="mb-3 mb-md-0 d-flex justify-content-start align-items-end">
                <Button
                  style={{
                    backgroundColor: isbtnEnviar ? "#BDBDBD" : "#041562",
                    color: "#FFFFFF",
                  }}
                  className="boton2"
                  size="large"
                  variant="contained"
                  color="primary"
                  onClick={crearEventosMasivos}
                  disabled={isbtnEnviar}>
                  Importar
                </Button>
              </Col>
              <Col xs={6} md={4} className="mb-3 mb-md-0 d-flex justify-content-start align-items-end">
                <Button
                  style={{ backgroundColor: "#DA251C" }}
                  className="boton2"
                  size="large"
                  variant="contained"
                  color="primary"
                  onClick={Cancelar}
                >
                  Cancelar
                </Button>
              </Col>
            </Row>
            <div className="centered-link">
              <a
                href={`${process.env.REACT_APP_BACKEND_URL}certificaciones/downloadModeloFechasEventos`}
                download="Modelo_Fechas_eventos.xlsx"
                style={{
                  marginTop: "10px",
                  color: "#041562",
                  textAlign: "center",
                  display: "inline-block",
                  width: "100%",
                }}
              >
                Descargar Modelo de Fechas
              </a>
            </div>

            <div>
              <LinearProgressOverlay isVisible={isVisible} progress={progress} completed={completed} disabled={false} />
            </div>

            {data.length > 0 && (
              <Grid item xs={10} sm={10} md={6} style={{ width: "100%", maxWidth: "100%" }}>
                <Card elevation={10} className="cardContentWithMargin">
                  <CardContent
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      Width: "100%",
                      maxHeight: "75vh",
                      overflowX: "auto",
                      overflowY: "auto",
                    }}
                  >
                    <Tabla
                      data={data}
                      headers={headers}
                      onCpdateellU={(rowIndex, header, newValue) => {
                        const newData = [...data];
                        newData[rowIndex][header] = newValue;
                        setData(newData);
                      }}
                    />
                  </CardContent>
                </Card>
              </Grid>
            )}
          </Form>
        </CardContent>
      </Card>
    </div>
  );
};

export default memo(CreateModalEventosMasivo);

