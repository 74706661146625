import React from "react";
import { Link } from "react-router-dom";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import { ExpandLess, ExpandMore, Accessibility, ContactPage } from "@mui/icons-material";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import AirplaneTicketIcon from '@mui/icons-material/AirplaneTicket';
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import TuneIcon from "@mui/icons-material/Tune";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';

function MenuAdministracion({ isDrawerOpen, openSubMenu, handleClick, isVisible, hasVisibleSubmenus, location }) {
  return (
    hasVisibleSubmenus([
      "PortalUsuarios",
      "PortalClientes",
      "PortalChoferes",
      "EstadisticasGlobal",
      "ControlSupervisora",
      "AsignacionClientesEjecutivas",
      "PortalVehiculos",
      "CrearRetirosPrueba",
    ]) && (
      <>
        <ListItemButton onClick={() => handleClick("administracion")}>
          <ListItemIcon sx={{ color: "white" }}>
            <ContactPage />
          </ListItemIcon>
          {isDrawerOpen && <ListItemText primary="Administración" sx={{ color: "white" }} />}
          {isDrawerOpen &&
            (openSubMenu === "administracion" ? (
              <ExpandLess sx={{ color: "white" }} />
            ) : (
              <ExpandMore sx={{ color: "white" }} />
            ))}
        </ListItemButton>

        <Collapse in={openSubMenu === "administracion"} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {isVisible("PortalUsuarios") && (
              <ListItemButton
                component={Link}
                to="/PortalUsuarios"
                sx={{
                  bgcolor: location.pathname === "/PortalUsuarios" ? "white" : "transparent",
                  color: location.pathname === "/PortalUsuarios" ? "#041562" : "white",
                  ".MuiListItemText-primary": {
                    fontSize: "0.775rem",
                  },
                  pl: isDrawerOpen ? 4 : 2,
                  "& .MuiListItemIcon-root": {
                    color: location.pathname === "/PortalUsuarios" ? "#041562" : "white",
                    minWidth: isDrawerOpen ? "auto" : 25,
                    mr: isDrawerOpen ? 2 : "auto",
                    justifyContent: "center",
                  },
                  "& .MuiListItemText-primary": {
                    color: location.pathname === "/PortalUsuarios" ? "#041562" : "white",
                  },
                  "&:hover": {
                    bgcolor: "white",
                    ".MuiListItemIcon-root": { color: "#041562" },
                    ".MuiListItemText-primary": { color: "#041562" },
                    borderRadius: "10px",
                  },
                  borderRadius: location.pathname === "/PortalUsuarios" ? "10px" : "0px",
                  margin: location.pathname === "/PortalUsuarios" ? "8px" : "0px",
                  overflow: "hidden",
                }}
              >
                <ListItemIcon>
                  <Accessibility />
                </ListItemIcon>
                {isDrawerOpen && <ListItemText primary="Administrar usuarios" />}
              </ListItemButton>
            )}
            {isVisible("PortalChoferes") && (
              <ListItemButton
                component={Link}
                to="/PortalChoferes"
                sx={{
                  bgcolor: location.pathname === "/PortalChoferes" ? "white" : "transparent",
                  color: location.pathname === "/PortalChoferes" ? "#041562" : "white",
                  ".MuiListItemText-primary": {
                    fontSize: "0.775rem",
                  },
                  pl: isDrawerOpen ? 4 : 2,
                  "& .MuiListItemIcon-root": {
                    color: location.pathname === "/PortalChoferes" ? "#041562" : "white",
                    minWidth: isDrawerOpen ? "auto" : 25,
                    mr: isDrawerOpen ? 2 : "auto",
                    justifyContent: "center",
                  },
                  "& .MuiListItemText-primary": {
                    color: location.pathname === "/PortalChoferes" ? "#041562" : "white",
                  },
                  "&:hover": {
                    bgcolor: "white",
                    ".MuiListItemIcon-root": { color: "#041562" },
                    ".MuiListItemText-primary": { color: "#041562" },
                    borderRadius: "10px",
                  },
                  borderRadius: location.pathname === "/PortalChoferes" ? "10px" : "0px",
                  margin: location.pathname === "/PortalChoferes" ? "8px" : "0px",
                  overflow: "hidden",
                }}
              >
                <ListItemIcon>
                  <DriveEtaIcon />
                </ListItemIcon>
                {isDrawerOpen && <ListItemText primary="Administrar choferes" />}
              </ListItemButton>
            )}
            {isVisible("PortalClientes") && (
              <ListItemButton
                component={Link}
                to="/PortalClientes"
                sx={{
                  bgcolor: location.pathname === "/PortalClientes" ? "white" : "transparent",
                  color: location.pathname === "/PortalClientes" ? "#041562" : "white",
                  ".MuiListItemText-primary": {
                    fontSize: "0.775rem",
                  },
                  pl: isDrawerOpen ? 4 : 2,
                  "& .MuiListItemIcon-root": {
                    color: location.pathname === "/PortalClientes" ? "#041562" : "white",
                    minWidth: isDrawerOpen ? "auto" : 25,
                    mr: isDrawerOpen ? 2 : "auto",
                    justifyContent: "center",
                  },
                  "& .MuiListItemText-primary": {
                    color: location.pathname === "/PortalClientes" ? "#041562" : "white",
                  },
                  "&:hover": {
                    bgcolor: "white",
                    ".MuiListItemIcon-root": { color: "#041562" },
                    ".MuiListItemText-primary": { color: "#041562" },
                    borderRadius: "10px",
                  },
                  borderRadius: location.pathname === "/PortalClientes" ? "10px" : "0px",
                  margin: location.pathname === "/PortalClientes" ? "8px" : "0px",
                  overflow: "hidden",
                }}
              >
                <ListItemIcon>
                  <SupervisorAccountIcon />
                </ListItemIcon>
                {isDrawerOpen && <ListItemText primary="Administrar clientes" />}
              </ListItemButton>
            )}
            {isVisible("ControlSupervisora") && (
              <ListItemButton
                component={Link}
                to="/ControlSupervisora"
                sx={{
                  bgcolor: location.pathname === "/ControlSupervisora" ? "white" : "transparent",
                  color: location.pathname === "/ControlSupervisora" ? "#041562" : "white",
                  ".MuiListItemText-primary": {
                    fontSize: "0.775rem",
                  },
                  pl: isDrawerOpen ? 4 : 2,
                  "& .MuiListItemIcon-root": {
                    color: location.pathname === "/ControlSupervisora" ? "#041562" : "white",
                    minWidth: isDrawerOpen ? "auto" : 25,
                    mr: isDrawerOpen ? 2 : "auto",
                    justifyContent: "center",
                  },
                  "& .MuiListItemText-primary": {
                    color: location.pathname === "/ControlSupervisora" ? "#041562" : "white",
                  },
                  "&:hover": {
                    bgcolor: "white",
                    ".MuiListItemIcon-root": { color: "#041562" },
                    ".MuiListItemText-primary": { color: "#041562" },
                    borderRadius: "10px",
                  },
                  borderRadius: location.pathname === "/ControlSupervisora" ? "10px" : "0px",
                  margin: location.pathname === "/ControlSupervisora" ? "8px" : "0px",
                  overflow: "hidden",
                }}
              >
                <ListItemIcon>
                  <LeaderboardIcon />
                </ListItemIcon>
                {isDrawerOpen && <ListItemText primary="Control supervisora" />}
              </ListItemButton>
            )}
            {isVisible("AsignacionClientesEjecutivas") && (
              <ListItemButton
                component={Link}
                to="/AsignacionClientesEjecutivas"
                sx={{
                  bgcolor: location.pathname === "/AsignacionClientesEjecutivas" ? "white" : "transparent",
                  color: location.pathname === "/AsignacionClientesEjecutivas" ? "#041562" : "white",
                  ".MuiListItemText-primary": {
                    fontSize: "0.775rem",
                  },
                  pl: isDrawerOpen ? 4 : 2,
                  "& .MuiListItemIcon-root": {
                    color: location.pathname === "/AsignacionClientesEjecutivas" ? "#041562" : "white",
                    minWidth: isDrawerOpen ? "auto" : 25,
                    mr: isDrawerOpen ? 2 : "auto",
                    justifyContent: "center",
                  },
                  "& .MuiListItemText-primary": {
                    color: location.pathname === "/AsignacionClientesEjecutivas" ? "#041562" : "white",
                  },
                  "&:hover": {
                    bgcolor: "white",
                    ".MuiListItemIcon-root": { color: "#041562" },
                    ".MuiListItemText-primary": { color: "#041562" },
                    borderRadius: "10px",
                  },
                  borderRadius: location.pathname === "/AsignacionClientesEjecutivas" ? "10px" : "0px",
                  margin: location.pathname === "/AsignacionClientesEjecutivas" ? "8px" : "0px",
                  overflow: "hidden",
                }}
              >
                <ListItemIcon>
                  <TuneIcon />
                </ListItemIcon>
                {isDrawerOpen && <ListItemText primary="Asignar clientes" />}
              </ListItemButton>
            )}
            {isVisible("EstadisticasGlobal") && (
              <ListItemButton
                component={Link}
                to="/EstadisticasGlobal"
                sx={{
                  bgcolor: location.pathname === "/EstadisticasGlobal" ? "white" : "transparent",
                  color: location.pathname === "/EstadisticasGlobal" ? "#041562" : "white",
                  ".MuiListItemText-primary": {
                    fontSize: "0.775rem",
                  },
                  pl: isDrawerOpen ? 4 : 2,
                  "& .MuiListItemIcon-root": {
                    color: location.pathname === "/EstadisticasGlobal" ? "#041562" : "white",
                    minWidth: isDrawerOpen ? "auto" : 25,
                    mr: isDrawerOpen ? 2 : "auto",
                    justifyContent: "center",
                  },
                  "& .MuiListItemText-primary": {
                    color: location.pathname === "/EstadisticasGlobal" ? "#041562" : "white",
                  },
                  "&:hover": {
                    bgcolor: "white",
                    ".MuiListItemIcon-root": { color: "#041562" },
                    ".MuiListItemText-primary": { color: "#041562" },
                    borderRadius: "10px",
                  },
                  borderRadius: location.pathname === "/EstadisticasGlobal" ? "10px" : "0px",
                  margin: location.pathname === "/EstadisticasGlobal" ? "8px" : "0px",
                  overflow: "hidden",
                }}
              >
                <ListItemIcon>
                  <EqualizerIcon />
                </ListItemIcon>
                {isDrawerOpen && <ListItemText primary="Estadisticas Global" />}
              </ListItemButton>
            )}
             {isVisible("PortalVehiculos") && (
              <ListItemButton
                component={Link}
                to="/PortalVehiculos"
                sx={{
                  bgcolor: location.pathname === "/PortalVehiculos" ? "white" : "transparent",
                  color: location.pathname === "/PortalVehiculos" ? "#041562" : "white",
                  ".MuiListItemText-primary": {
                    fontSize: "0.775rem",
                  },
                  pl: isDrawerOpen ? 4 : 2,
                  "& .MuiListItemIcon-root": {
                    color: location.pathname === "/PortalVehiculos" ? "#041562" : "white",
                    minWidth: isDrawerOpen ? "auto" : 25,
                    mr: isDrawerOpen ? 2 : "auto",
                    justifyContent: "center",
                  },
                  "& .MuiListItemText-primary": {
                    color: location.pathname === "/PortalVehiculos" ? "#041562" : "white",
                  },
                  "&:hover": {
                    bgcolor: "white",
                    ".MuiListItemIcon-root": { color: "#041562" },
                    ".MuiListItemText-primary": { color: "#041562" },
                    borderRadius: "10px",
                  },
                  borderRadius: location.pathname === "/PortalVehiculos" ? "10px" : "0px",
                  margin: location.pathname === "/PortalVehiculos" ? "8px" : "0px",
                  overflow: "hidden",
                }}
              >
                <ListItemIcon>
                  <LocalShippingIcon />
                </ListItemIcon>
                {isDrawerOpen && <ListItemText primary="Administrar vehiculos" />}
              </ListItemButton>
            )}
            {isVisible("CrearRetirosPrueba") && (
              <ListItemButton
                component={Link}
                to="/CrearRetirosPrueba"
                sx={{
                  bgcolor: location.pathname === "/CrearRetirosPrueba" ? "white" : "transparent",
                  color: location.pathname === "/CrearRetirosPrueba" ? "#041562" : "white",
                  ".MuiListItemText-primary": {
                    fontSize: "0.775rem",
                  },
                  pl: isDrawerOpen ? 4 : 2,
                  "& .MuiListItemIcon-root": {
                    color: location.pathname === "/CrearRetirosPrueba" ? "#041562" : "white",
                    minWidth: isDrawerOpen ? "auto" : 25,
                    mr: isDrawerOpen ? 2 : "auto",
                    justifyContent: "center",
                  },
                  "& .MuiListItemText-primary": {
                    color: location.pathname === "/CrearRetirosPrueba" ? "#041562" : "white",
                  },
                  "&:hover": {
                    bgcolor: "white",
                    ".MuiListItemIcon-root": { color: "#041562" },
                    ".MuiListItemText-primary": { color: "#041562" },
                    borderRadius: "10px",
                  },
                  borderRadius: location.pathname === "/CrearRetirosPrueba" ? "10px" : "0px",
                  margin: location.pathname === "/CrearRetirosPrueba" ? "8px" : "0px",
                  overflow: "hidden",
                }}
              >
                <ListItemIcon>
                  <AirplaneTicketIcon />
                </ListItemIcon>
                {isDrawerOpen && <ListItemText primary="Crear retiros de prueba" />}
              </ListItemButton>
            )}
          </List>
        </Collapse>
      </>
    )
  );
}

export default MenuAdministracion;
