import React, { useState } from "react";
import { Link } from "react-router-dom";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructions";
import {
  ExpandLess,
  ExpandMore,
  Plagiarism,
  Email,
  Download,
  Filter1,
  Filter2,
  Filter3,
  Filter4,
  Filter5,
  Filter6,
  Filter7,
  Filter8,
  Edit,
  AllInbox,
} from "@mui/icons-material";
import BusinessIcon from "@mui/icons-material/Business";
import AssignmentIcon from "@mui/icons-material/Assignment";
import EditOffIcon from "@mui/icons-material/EditOff";
import { Search } from "@mui/icons-material";
import { useSelector } from "react-redux";

function MenuOperaciones({ isDrawerOpen, openSubMenu, handleClick, isVisible, hasVisibleSubmenus, location }) {
  const [openSubSubMenu, setOpenSubSubMenu] = useState("");
  const userInfo = useSelector((state) => state.user.userInfo);

  const handleSubSubMenuClick = (menu) => {
    if (openSubSubMenu === menu) {
      setOpenSubSubMenu("");
    } else {
      setOpenSubSubMenu(menu);
    }
  };

  return (
    hasVisibleSubmenus([
      "ListaCorreos",
      "CubicosOperaciones",
      "ManifiestosRetorno",
      "ManifiestoSalida",
      "ManifiestoIngresoBodega",
      "ManifiestosSalidaInhouse",
      "DescargaManifiestos",
      "ManifiestoEntradaAgente",
      "ManifiestoEntradaSantiago",
      "ListadosManifiestos",
      "CreacionEtiqueta",
    ]) && (
      <>
        <ListItemButton onClick={() => handleClick("operaciones")}>
          <ListItemIcon sx={{ color: "white" }}>
            <IntegrationInstructionsIcon />
          </ListItemIcon>
          {isDrawerOpen && <ListItemText primary="Operaciones" sx={{ color: "white" }} />}
          {isDrawerOpen &&
            (openSubMenu === "operaciones" ? (
              <ExpandLess sx={{ color: "white" }} />
            ) : (
              <ExpandMore sx={{ color: "white" }} />
            ))}
        </ListItemButton>

        <Collapse in={openSubMenu === "operaciones"} timeout="auto" unmountOnExit>
          <List component="div" disablePadding sx={{ pl: isDrawerOpen ? 4 : 2 }}>
            {isVisible("PorOD") && (
              <ListItemButton
                onClick={() => handleSubSubMenuClick("PorOD")}
                sx={{ justifyContent: "flex-end", color: "white" }}
              >
                <ListItemIcon sx={{ color: "white" }}>
                  <AssignmentIcon />
                </ListItemIcon>
                <ListItemText primary="Manifiestos Por OD" />
                {openSubSubMenu === "PorOD" ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
            )}
            <Collapse in={openSubSubMenu === "PorOD"} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {isVisible("ManifiestosSalidaInhouse") && (
                  <ListItemButton
                    component={Link}
                    to="/ManifiestosSalidaInhouse"
                    sx={{
                      bgcolor: location.pathname === "/ManifiestosSalidaInhouse" ? "white" : "transparent",
                      color: location.pathname === "/ManifiestosSalidaInhouse" ? "#041562" : "white",
                      ".MuiListItemText-primary": {
                        fontSize: "0.775rem",
                      },
                      pl: isDrawerOpen ? 4 : 2,
                      "& .MuiListItemIcon-root": {
                        color: location.pathname === "/ManifiestosSalidaInhouse" ? "#041562" : "white",
                        minWidth: isDrawerOpen ? "auto" : 25,
                        mr: isDrawerOpen ? 2 : "auto",
                        justifyContent: "center",
                      },
                      "& .MuiListItemText-primary": {
                        color: location.pathname === "/ManifiestosSalidaInhouse" ? "#041562" : "white",
                      },
                      "&:hover": {
                        bgcolor: "white",
                        ".MuiListItemIcon-root": { color: "#041562" },
                        ".MuiListItemText-primary": { color: "#041562" },
                        borderRadius: "10px",
                      },
                      borderRadius: location.pathname === "/ManifiestosSalidaInhouse" ? "10px" : "0px",
                      margin: location.pathname === "/ManifiestosSalidaInhouse" ? "8px" : "0px",
                      overflow: "hidden",
                    }}
                  >
                    <ListItemIcon>
                      <Filter1 />
                    </ListItemIcon>
                    {isDrawerOpen && <ListItemText primary="Manifiesto salida inhouse" />}
                  </ListItemButton>
                )}
                {isVisible("ManifiestoIngresoBodega") && (
                  <ListItemButton
                    component={Link}
                    to="/ManifiestoIngresoBodega"
                    sx={{
                      bgcolor: location.pathname === "/ManifiestoIngresoBodega" ? "white" : "transparent",
                      color: location.pathname === "/ManifiestoIngresoBodega" ? "#041562" : "white",
                      ".MuiListItemText-primary": {
                        fontSize: "0.775rem",
                      },
                      pl: isDrawerOpen ? 4 : 2,
                      "& .MuiListItemIcon-root": {
                        color: location.pathname === "/ManifiestoIngresoBodega" ? "#041562" : "white",
                        minWidth: isDrawerOpen ? "auto" : 25,
                        mr: isDrawerOpen ? 2 : "auto",
                        justifyContent: "center",
                      },
                      "& .MuiListItemText-primary": {
                        color: location.pathname === "/ManifiestoIngresoBodega" ? "#041562" : "white",
                      },
                      "&:hover": {
                        bgcolor: "white",
                        ".MuiListItemIcon-root": { color: "#041562" },
                        ".MuiListItemText-primary": { color: "#041562" },
                        borderRadius: "10px",
                      },
                      borderRadius: location.pathname === "/ManifiestoIngresoBodega" ? "10px" : "0px",
                      margin: location.pathname === "/ManifiestoIngresoBodega" ? "8px" : "0px",
                      overflow: "hidden",
                    }}
                  >
                    <ListItemIcon>
                      <Filter2 />
                    </ListItemIcon>
                    {isDrawerOpen && <ListItemText primary="Manifiesto Ingreso Bodega" />}
                  </ListItemButton>
                )}

                {isVisible("ManifiestoSalida") && (
                  <ListItemButton
                    component={Link}
                    to="/ManifiestoSalida"
                    sx={{
                      bgcolor: location.pathname === "/ManifiestoSalida" ? "white" : "transparent",
                      color: location.pathname === "/ManifiestoSalida" ? "#041562" : "white",
                      ".MuiListItemText-primary": {
                        fontSize: "0.775rem",
                      },
                      pl: isDrawerOpen ? 4 : 2,
                      "& .MuiListItemIcon-root": {
                        color: location.pathname === "/ManifiestoSalida" ? "#041562" : "white",
                        minWidth: isDrawerOpen ? "auto" : 25,
                        mr: isDrawerOpen ? 2 : "auto",
                        justifyContent: "center",
                      },
                      "& .MuiListItemText-primary": {
                        color: location.pathname === "/ManifiestoSalida" ? "#041562" : "white",
                      },
                      "&:hover": {
                        bgcolor: "white",
                        ".MuiListItemIcon-root": { color: "#041562" },
                        ".MuiListItemText-primary": { color: "#041562" },
                        borderRadius: "10px",
                      },
                      borderRadius: location.pathname === "/ManifiestoSalida" ? "10px" : "0px",
                      margin: location.pathname === "/ManifiestoSalida" ? "8px" : "0px",
                      overflow: "hidden",
                    }}
                  >
                    <ListItemIcon>
                      <Filter3 />
                    </ListItemIcon>
                    {isDrawerOpen && <ListItemText primary="Manifiesto Salida Bodega" />}
                  </ListItemButton>
                )}
                {isVisible("ManifiestoEntradaAgente") && (
                  <ListItemButton
                    component={Link}
                    to="/ManifiestoEntradaAgente"
                    sx={{
                      bgcolor: location.pathname === "/ManifiestoEntradaAgente" ? "white" : "transparent",
                      color: location.pathname === "/ManifiestoEntradaAgente" ? "#041562" : "white",
                      ".MuiListItemText-primary": {
                        fontSize: "0.775rem",
                      },
                      pl: isDrawerOpen ? 4 : 2,
                      "& .MuiListItemIcon-root": {
                        color: location.pathname === "/ManifiestoEntradaAgente" ? "#041562" : "white",
                        minWidth: isDrawerOpen ? "auto" : 25,
                        mr: isDrawerOpen ? 2 : "auto",
                        justifyContent: "center",
                      },
                      "& .MuiListItemText-primary": {
                        color: location.pathname === "/ManifiestoEntradaAgente" ? "#041562" : "white",
                      },
                      "&:hover": {
                        bgcolor: "white",
                        ".MuiListItemIcon-root": { color: "#041562" },
                        ".MuiListItemText-primary": { color: "#041562" },
                        borderRadius: "10px",
                      },
                      borderRadius: location.pathname === "/ManifiestoEntradaAgente" ? "10px" : "0px",
                      margin: location.pathname === "/ManifiestoEntradaAgente" ? "8px" : "0px",
                      overflow: "hidden",
                    }}
                  >
                    <ListItemIcon>
                      <Filter4 />
                    </ListItemIcon>
                    {isDrawerOpen && <ListItemText primary="Manifiesto Entrada Agente" />}
                  </ListItemButton>
                )}
                {isVisible("ListadosManifiestos") && (
                  <ListItemButton
                    component={Link}
                    to="/ListadosManifiestos"
                    sx={{
                      bgcolor: location.pathname === "/ListadosManifiestos" ? "white" : "transparent",
                      color: location.pathname === "/ListadosManifiestos" ? "#041562" : "white",
                      ".MuiListItemText-primary": {
                        fontSize: "0.775rem",
                      },
                      pl: isDrawerOpen ? 4 : 2,
                      "& .MuiListItemIcon-root": {
                        color: location.pathname === "/ListadosManifiestos" ? "#041562" : "white",
                        minWidth: isDrawerOpen ? "auto" : 25,
                        mr: isDrawerOpen ? 2 : "auto",
                        justifyContent: "center",
                      },
                      "& .MuiListItemText-primary": {
                        color: location.pathname === "/ListadosManifiestos" ? "#041562" : "white",
                      },
                      "&:hover": {
                        bgcolor: "white",
                        ".MuiListItemIcon-root": { color: "#041562" },
                        ".MuiListItemText-primary": { color: "#041562" },
                        borderRadius: "10px",
                      },
                      borderRadius: location.pathname === "/ListadosManifiestos" ? "10px" : "0px",
                      margin: location.pathname === "/ListadosManifiestos" ? "8px" : "0px",
                      overflow: "hidden",
                    }}
                  >
                    <ListItemIcon>
                      <Filter5 />
                    </ListItemIcon>
                    {isDrawerOpen && <ListItemText primary="Manifiestos Rutas" />}
                  </ListItemButton>
                )}
                {isVisible("ManifiestosRetorno") && (
                  <ListItemButton
                    component={Link}
                    to="/ManifiestosRetorno"
                    sx={{
                      bgcolor: location.pathname === "/ManifiestosRetorno" ? "white" : "transparent",
                      color: location.pathname === "/ManifiestosRetorno" ? "#041562" : "white",
                      ".MuiListItemText-primary": {
                        fontSize: "0.775rem",
                      },
                      pl: isDrawerOpen ? 4 : 2,
                      "& .MuiListItemIcon-root": {
                        color: location.pathname === "/ManifiestosRetorno" ? "#041562" : "white",
                        minWidth: isDrawerOpen ? "auto" : 25,
                        mr: isDrawerOpen ? 2 : "auto",
                        justifyContent: "center",
                      },
                      "& .MuiListItemText-primary": {
                        color: location.pathname === "/ManifiestosRetorno" ? "#041562" : "white",
                      },
                      "&:hover": {
                        bgcolor: "white",
                        ".MuiListItemIcon-root": { color: "#041562" },
                        ".MuiListItemText-primary": { color: "#041562" },
                        borderRadius: "10px",
                      },
                      borderRadius: location.pathname === "/ManifiestosRetorno" ? "10px" : "0px",
                      margin: location.pathname === "/ManifiestosRetorno" ? "8px" : "0px",
                      overflow: "hidden",
                    }}
                  >
                    <ListItemIcon>
                      <Filter6 />
                    </ListItemIcon>
                    {isDrawerOpen && <ListItemText primary="Manifiesto Retorno Agencias" />}
                  </ListItemButton>
                )}

                {isVisible("ManifiestoEntradaSantiago") && (
                  <ListItemButton
                    component={Link}
                    to="/ManifiestoEntradaSantiago"
                    sx={{
                      bgcolor: location.pathname === "/ManifiestoEntradaSantiago" ? "white" : "transparent",
                      color: location.pathname === "/ManifiestoEntradaSantiago" ? "#041562" : "white",
                      ".MuiListItemText-primary": {
                        fontSize: "0.775rem",
                      },
                      pl: isDrawerOpen ? 4 : 2,
                      "& .MuiListItemIcon-root": {
                        color: location.pathname === "/ManifiestoEntradaSantiago" ? "#041562" : "white",
                        minWidth: isDrawerOpen ? "auto" : 25,
                        mr: isDrawerOpen ? 2 : "auto",
                        justifyContent: "center",
                      },
                      "& .MuiListItemText-primary": {
                        color: location.pathname === "/ManifiestoEntradaSantiago" ? "#041562" : "white",
                      },
                      "&:hover": {
                        bgcolor: "white",
                        ".MuiListItemIcon-root": { color: "#041562" },
                        ".MuiListItemText-primary": { color: "#041562" },
                        borderRadius: "10px",
                      },
                      borderRadius: location.pathname === "/ManifiestoEntradaSantiago" ? "10px" : "0px",
                      margin: location.pathname === "/ManifiestoEntradaSantiago" ? "8px" : "0px",
                      overflow: "hidden",
                    }}
                  >
                    <ListItemIcon>
                      <Filter7 />
                    </ListItemIcon>
                    {isDrawerOpen && <ListItemText primary="Manifiesto Entrada Santiago" />}
                  </ListItemButton>
                )}
                {isVisible("ManifiestoRetornoCliente") && (
                  <ListItemButton
                    component={Link}
                    to="/ManifiestoRetornoCliente"
                    sx={{
                      bgcolor: location.pathname === "/ManifiestoRetornoCliente" ? "white" : "transparent",
                      color: location.pathname === "/ManifiestoRetornoCliente" ? "#041562" : "white",
                      ".MuiListItemText-primary": {
                        fontSize: "0.775rem",
                      },
                      pl: isDrawerOpen ? 4 : 2,
                      "& .MuiListItemIcon-root": {
                        color: location.pathname === "/ManifiestoRetornoCliente" ? "#041562" : "white",
                        minWidth: isDrawerOpen ? "auto" : 25,
                        mr: isDrawerOpen ? 2 : "auto",
                        justifyContent: "center",
                      },
                      "& .MuiListItemText-primary": {
                        color: location.pathname === "/ManifiestoRetornoCliente" ? "#041562" : "white",
                      },
                      "&:hover": {
                        bgcolor: "white",
                        ".MuiListItemIcon-root": { color: "#041562" },
                        ".MuiListItemText-primary": { color: "#041562" },
                        borderRadius: "10px",
                      },
                      borderRadius: location.pathname === "/ManifiestoRetornoCliente" ? "10px" : "0px",
                      margin: location.pathname === "/ManifiestoRetornoCliente" ? "8px" : "0px",
                      overflow: "hidden",
                    }}
                  >
                    <ListItemIcon>
                      <Filter8 />
                    </ListItemIcon>
                    {isDrawerOpen && <ListItemText primary="Manifiesto Retorno Clientes" />}
                  </ListItemButton>
                )}
              </List>
            </Collapse>
            {/* menu por cliente */}
            {isVisible("PorCliente") && (
              <ListItemButton
                onClick={() => handleSubSubMenuClick("PorCliente")}
                sx={{ justifyContent: "flex-end", color: "white" }}
              >
                <ListItemIcon sx={{ color: "white" }}>
                  <BusinessIcon />
                </ListItemIcon>
                <ListItemText primary="Manifiestos Por Cliente" />
                {openSubSubMenu === "PorCliente" ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
            )}
            <Collapse in={openSubSubMenu === "PorCliente"} timeout="auto" unmountOnExit>
              <List>
                {isVisible("ManifiestosSalidaInhouseRef") && (
                  <ListItemButton
                    component={Link}
                    to="/ManifiestosSalidaInhouseRef"
                    sx={{
                      bgcolor: location.pathname === "/ManifiestosSalidaInhouseRef" ? "white" : "transparent",
                      color: location.pathname === "/ManifiestosSalidaInhouseRef" ? "#041562" : "white",
                      ".MuiListItemText-primary": {
                        fontSize: "0.775rem",
                      },
                      pl: isDrawerOpen ? 4 : 2,
                      "& .MuiListItemIcon-root": {
                        color: location.pathname === "/ManifiestosSalidaInhouseRef" ? "#041562" : "white",
                        minWidth: isDrawerOpen ? "auto" : 25,
                        mr: isDrawerOpen ? 2 : "auto",
                        justifyContent: "center",
                      },
                      "& .MuiListItemText-primary": {
                        color: location.pathname === "/ManifiestosSalidaInhouseRef" ? "#041562" : "white",
                      },
                      "&:hover": {
                        bgcolor: "white",
                        ".MuiListItemIcon-root": { color: "#041562" },
                        ".MuiListItemText-primary": { color: "#041562" },
                        borderRadius: "10px",
                      },
                      borderRadius: location.pathname === "/ManifiestosSalidaInhouseRef" ? "10px" : "0px",
                      margin: location.pathname === "/ManifiestosSalidaInhouseRef" ? "8px" : "0px",
                      overflow: "hidden",
                    }}
                  >
                    <ListItemIcon>
                      <Filter1 />
                    </ListItemIcon>
                    {isDrawerOpen && <ListItemText primary="Manifiesto Salida Inhouse Ref." />}
                  </ListItemButton>
                )}
                {isVisible("ManifiestoIngresoBodegaRef") && (
                  <ListItemButton
                    component={Link}
                    to="/ManifiestoIngresoBodegaRef"
                    sx={{
                      bgcolor: location.pathname === "/ManifiestoIngresoBodegaRef" ? "white" : "transparent",
                      color: location.pathname === "/ManifiestoIngresoBodegaRef" ? "#041562" : "white",
                      ".MuiListItemText-primary": {
                        fontSize: "0.775rem",
                      },
                      pl: isDrawerOpen ? 4 : 2,
                      "& .MuiListItemIcon-root": {
                        color: location.pathname === "/ManifiestoIngresoBodegaRef" ? "#041562" : "white",
                        minWidth: isDrawerOpen ? "auto" : 25,
                        mr: isDrawerOpen ? 2 : "auto",
                        justifyContent: "center",
                      },
                      "& .MuiListItemText-primary": {
                        color: location.pathname === "/ManifiestoIngresoBodegaRef" ? "#041562" : "white",
                      },
                      "&:hover": {
                        bgcolor: "white",
                        ".MuiListItemIcon-root": { color: "#041562" },
                        ".MuiListItemText-primary": { color: "#041562" },
                        borderRadius: "10px",
                      },
                      borderRadius: location.pathname === "/ManifiestoIngresoBodegaRef" ? "10px" : "0px",
                      margin: location.pathname === "/ManifiestoIngresoBodegaRef" ? "8px" : "0px",
                      overflow: "hidden",
                    }}
                  >
                    <ListItemIcon>
                      <Filter2 />
                    </ListItemIcon>
                    {isDrawerOpen && <ListItemText primary="Manifiesto de Ingreso Ref." />}
                  </ListItemButton>
                )}
                {isVisible("ManifiestoSalidaRef") && (
                  <ListItemButton
                    component={Link}
                    to="/ManifiestoSalidaRef"
                    sx={{
                      bgcolor: location.pathname === "/ManifiestoSalidaRef" ? "white" : "transparent",
                      color: location.pathname === "/ManifiestoSalidaRef" ? "#041562" : "white",
                      ".MuiListItemText-primary": {
                        fontSize: "0.775rem",
                      },
                      pl: isDrawerOpen ? 4 : 2,
                      "& .MuiListItemIcon-root": {
                        color: location.pathname === "/ManifiestoSalidaRef" ? "#041562" : "white",
                        minWidth: isDrawerOpen ? "auto" : 25,
                        mr: isDrawerOpen ? 2 : "auto",
                        justifyContent: "center",
                      },
                      "& .MuiListItemText-primary": {
                        color: location.pathname === "/ManifiestoSalidaRef" ? "#041562" : "white",
                      },
                      "&:hover": {
                        bgcolor: "white",
                        ".MuiListItemIcon-root": { color: "#041562" },
                        ".MuiListItemText-primary": { color: "#041562" },
                        borderRadius: "10px",
                      },
                      borderRadius: location.pathname === "/ManifiestoSalidaRef" ? "10px" : "0px",
                      margin: location.pathname === "/ManifiestoSalidaRef" ? "8px" : "0px",
                      overflow: "hidden",
                    }}
                  >
                    <ListItemIcon>
                      <Filter3 />
                    </ListItemIcon>
                    {isDrawerOpen && <ListItemText primary="Manifiesto de Salida Ref." />}
                  </ListItemButton>
                )}
                {isVisible("ManifiestoEntradaAgenteRef") && (
                  <ListItemButton
                    component={Link}
                    to="/ManifiestoEntradaAgenteRef"
                    sx={{
                      bgcolor: location.pathname === "/ManifiestoEntradaAgenteRef" ? "white" : "transparent",
                      color: location.pathname === "/ManifiestoEntradaAgenteRef" ? "#041562" : "white",
                      ".MuiListItemText-primary": {
                        fontSize: "0.775rem",
                      },
                      pl: isDrawerOpen ? 4 : 2,
                      "& .MuiListItemIcon-root": {
                        color: location.pathname === "/ManifiestoEntradaAgenteRef" ? "#041562" : "white",
                        minWidth: isDrawerOpen ? "auto" : 25,
                        mr: isDrawerOpen ? 2 : "auto",
                        justifyContent: "center",
                      },
                      "& .MuiListItemText-primary": {
                        color: location.pathname === "/ManifiestoEntradaAgenteRef" ? "#041562" : "white",
                      },
                      "&:hover": {
                        bgcolor: "white",
                        ".MuiListItemIcon-root": { color: "#041562" },
                        ".MuiListItemText-primary": { color: "#041562" },
                        borderRadius: "10px",
                      },
                      borderRadius: location.pathname === "/ManifiestoEntradaAgenteRef" ? "10px" : "0px",
                      margin: location.pathname === "/ManifiestoEntradaAgenteRef" ? "8px" : "0px",
                      overflow: "hidden",
                    }}
                  >
                    <ListItemIcon>
                      <Filter4 />
                    </ListItemIcon>
                    {isDrawerOpen && <ListItemText primary="Manifiesto de entrada agente Ref." />}
                  </ListItemButton>
                )}

                {isVisible("ManifiestoRutaRef") && (
                  <ListItemButton
                    component={Link}
                    to="/ManifiestoRutaRef"
                    sx={{
                      bgcolor: location.pathname === "/ManifiestoRutaRef" ? "white" : "transparent",
                      color: location.pathname === "/ManifiestoRutaRef" ? "#041562" : "white",
                      ".MuiListItemText-primary": {
                        fontSize: "0.775rem",
                      },
                      pl: isDrawerOpen ? 4 : 2,
                      "& .MuiListItemIcon-root": {
                        color: location.pathname === "/ManifiestoRutaRef" ? "#041562" : "white",
                        minWidth: isDrawerOpen ? "auto" : 25,
                        mr: isDrawerOpen ? 2 : "auto",
                        justifyContent: "center",
                      },
                      "& .MuiListItemText-primary": {
                        color: location.pathname === "/ManifiestoRutaRef" ? "#041562" : "white",
                      },
                      "&:hover": {
                        bgcolor: "white",
                        ".MuiListItemIcon-root": { color: "#041562" },
                        ".MuiListItemText-primary": { color: "#041562" },
                        borderRadius: "10px",
                      },
                      borderRadius: location.pathname === "/ManifiestoRutaRef" ? "10px" : "0px",
                      margin: location.pathname === "/ManifiestoRutaRef" ? "8px" : "0px",
                      overflow: "hidden",
                    }}
                  >
                    <ListItemIcon>
                      <Filter5 />
                    </ListItemIcon>
                    {isDrawerOpen && <ListItemText primary="Manifiesto de Ruta Ref." />}
                  </ListItemButton>
                )}
                {isVisible("ManifiestosRetornoRef") && (
                  <ListItemButton
                    component={Link}
                    to="/ManifiestosRetornoRef"
                    sx={{
                      bgcolor: location.pathname === "/ManifiestosRetornoRef" ? "white" : "transparent",
                      color: location.pathname === "/ManifiestosRetornoRef" ? "#041562" : "white",
                      ".MuiListItemText-primary": {
                        fontSize: "0.775rem",
                      },
                      pl: isDrawerOpen ? 4 : 2,
                      "& .MuiListItemIcon-root": {
                        color: location.pathname === "/ManifiestosRetornoRef" ? "#041562" : "white",
                        minWidth: isDrawerOpen ? "auto" : 25,
                        mr: isDrawerOpen ? 2 : "auto",
                        justifyContent: "center",
                      },
                      "& .MuiListItemText-primary": {
                        color: location.pathname === "/ManifiestosRetornoRef" ? "#041562" : "white",
                      },
                      "&:hover": {
                        bgcolor: "white",
                        ".MuiListItemIcon-root": { color: "#041562" },
                        ".MuiListItemText-primary": { color: "#041562" },
                        borderRadius: "10px",
                      },
                      borderRadius: location.pathname === "/ManifiestosRetornoRef" ? "10px" : "0px",
                      margin: location.pathname === "/ManifiestosRetornoRef" ? "8px" : "0px",
                      overflow: "hidden",
                    }}
                  >
                    <ListItemIcon>
                      <Filter6 />
                    </ListItemIcon>
                    {isDrawerOpen && <ListItemText primary="Manifiesto de Retorno Ref. " />}
                  </ListItemButton>
                )}

                {isVisible("ManifiestoEntradaSantiagoRef") && (
                  <ListItemButton
                    component={Link}
                    to="/ManifiestoEntradaSantiagoRef"
                    sx={{
                      bgcolor: location.pathname === "/ManifiestoEntradaSantiagoRef" ? "white" : "transparent",
                      color: location.pathname === "/ManifiestoEntradaSantiagoRef" ? "#041562" : "white",
                      ".MuiListItemText-primary": {
                        fontSize: "0.775rem",
                      },
                      pl: isDrawerOpen ? 4 : 2,
                      "& .MuiListItemIcon-root": {
                        color: location.pathname === "/ManifiestoEntradaSantiagoRef" ? "#041562" : "white",
                        minWidth: isDrawerOpen ? "auto" : 25,
                        mr: isDrawerOpen ? 2 : "auto",
                        justifyContent: "center",
                      },
                      "& .MuiListItemText-primary": {
                        color: location.pathname === "/ManifiestoEntradaSantiagoRef" ? "#041562" : "white",
                      },
                      "&:hover": {
                        bgcolor: "white",
                        ".MuiListItemIcon-root": { color: "#041562" },
                        ".MuiListItemText-primary": { color: "#041562" },
                        borderRadius: "10px",
                      },
                      borderRadius: location.pathname === "/ManifiestoEntradaSantiagoRef" ? "10px" : "0px",
                      margin: location.pathname === "/ManifiestoEntradaSantiagoRef" ? "8px" : "0px",
                      overflow: "hidden",
                    }}
                  >
                    <ListItemIcon>
                      <Filter7 />
                    </ListItemIcon>
                    {isDrawerOpen && <ListItemText primary="Manifiesto de entrada Santiago Ref." />}
                  </ListItemButton>
                )}
                {isVisible("ManifiestoRetornoClienteRef") && (
                  <ListItemButton
                    component={Link}
                    to="/ManifiestoRetornoClienteRef"
                    sx={{
                      bgcolor: location.pathname === "/ManifiestoRetornoClienteRef" ? "white" : "transparent",
                      color: location.pathname === "/ManifiestoRetornoClienteRef" ? "#041562" : "white",
                      ".MuiListItemText-primary": {
                        fontSize: "0.775rem",
                      },
                      pl: isDrawerOpen ? 4 : 2,
                      "& .MuiListItemIcon-root": {
                        color: location.pathname === "/ManifiestoRetornoClienteRef" ? "#041562" : "white",
                        minWidth: isDrawerOpen ? "auto" : 25,
                        mr: isDrawerOpen ? 2 : "auto",
                        justifyContent: "center",
                      },
                      "& .MuiListItemText-primary": {
                        color: location.pathname === "/ManifiestoRetornoClienteRef" ? "#041562" : "white",
                      },
                      "&:hover": {
                        bgcolor: "white",
                        ".MuiListItemIcon-root": { color: "#041562" },
                        ".MuiListItemText-primary": { color: "#041562" },
                        borderRadius: "10px",
                      },
                      borderRadius: location.pathname === "/ManifiestoRetornoClienteRef" ? "10px" : "0px",
                      margin: location.pathname === "/ManifiestoRetornoClienteRef" ? "8px" : "0px",
                      overflow: "hidden",
                    }}
                  >
                    <ListItemIcon>
                      <Filter8 />
                    </ListItemIcon>
                    {isDrawerOpen && <ListItemText primary="Manifiesto Retorno Cliente Ref." />}
                  </ListItemButton>
                )}
              </List>
            </Collapse>
            {/* menu varios */}
            {isVisible("VariosOperaciones") && (
              <ListItemButton
                onClick={() => handleSubSubMenuClick("VariosOperaciones")}
                sx={{ justifyContent: "flex-end", color: "white" }}
              >
                <ListItemIcon sx={{ color: "white" }}>
                  <AllInbox />
                </ListItemIcon>
                <ListItemText primary="Varios" />
                {openSubSubMenu === "VariosOperaciones" ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
            )}
            <Collapse in={openSubSubMenu === "VariosOperaciones"} timeout="auto" unmountOnExit>
              {/* New client specific operations list here */}
              <List>
                {isVisible("CreacionEtiqueta") && (
                  <ListItemButton
                    component={Link}
                    to="/CreacionEtiqueta"
                    sx={{
                      bgcolor: location.pathname === "/CreacionEtiqueta" ? "white" : "transparent",
                      color: location.pathname === "/CreacionEtiqueta" ? "#041562" : "white",
                      ".MuiListItemText-primary": {
                        fontSize: "0.775rem",
                      },
                      pl: isDrawerOpen ? 4 : 2,
                      "& .MuiListItemIcon-root": {
                        color: location.pathname === "/CreacionEtiqueta" ? "#041562" : "white",
                        minWidth: isDrawerOpen ? "auto" : 25,
                        mr: isDrawerOpen ? 2 : "auto",
                        justifyContent: "center",
                      },
                      "& .MuiListItemText-primary": {
                        color: location.pathname === "/CreacionEtiqueta" ? "#041562" : "white",
                      },
                      "&:hover": {
                        bgcolor: "white",
                        ".MuiListItemIcon-root": { color: "#041562" },
                        ".MuiListItemText-primary": { color: "#041562" },
                        borderRadius: "10px",
                      },
                      borderRadius: location.pathname === "/CreacionEtiqueta" ? "10px" : "0px",
                      margin: location.pathname === "/CreacionEtiqueta" ? "8px" : "0px",
                      overflow: "hidden",
                    }}
                  >
                    <ListItemIcon>
                      <Filter2 />
                    </ListItemIcon>
                    {isDrawerOpen && <ListItemText primary="Impresion de etiqueta" />}
                  </ListItemButton>
                )}
                {isVisible("CubicosOperaciones") && (
                  <ListItemButton
                    component={Link}
                    to="/CubicosOperaciones"
                    sx={{
                      bgcolor: location.pathname === "/CubicosOperaciones" ? "white" : "transparent",
                      color: location.pathname === "/CubicosOperaciones" ? "#041562" : "white",
                      ".MuiListItemText-primary": {
                        fontSize: "0.775rem",
                      },
                      pl: isDrawerOpen ? 4 : 2,
                      "& .MuiListItemIcon-root": {
                        color: location.pathname === "/CubicosOperaciones" ? "#041562" : "white",
                        minWidth: isDrawerOpen ? "auto" : 25,
                        mr: isDrawerOpen ? 2 : "auto",
                        justifyContent: "center",
                      },
                      "& .MuiListItemText-primary": {
                        color: location.pathname === "/CubicosOperaciones" ? "#041562" : "white",
                      },
                      "&:hover": {
                        bgcolor: "white",
                        ".MuiListItemIcon-root": { color: "#041562" },
                        ".MuiListItemText-primary": { color: "#041562" },
                        borderRadius: "10px",
                      },
                      borderRadius: location.pathname === "/CubicosOperaciones" ? "10px" : "0px",
                      margin: location.pathname === "/CubicosOperaciones" ? "8px" : "0px",
                      overflow: "hidden",
                    }}
                  >
                    <ListItemIcon>
                      <Plagiarism />
                    </ListItemIcon>
                    {isDrawerOpen && <ListItemText primary="Editor Cubicos Retornos" />}
                  </ListItemButton>
                )}

                {isVisible("ListadoPendientes") && (
                  <ListItemButton
                    component={Link}
                    to="/ListadoPendientes"
                    sx={{
                      bgcolor: location.pathname === "/ListadoPendientes" ? "white" : "transparent",
                      color: location.pathname === "/ListadoPendientes" ? "#041562" : "white",
                      ".MuiListItemText-primary": {
                        fontSize: "0.775rem",
                      },
                      pl: isDrawerOpen ? 4 : 2,
                      "& .MuiListItemIcon-root": {
                        color: location.pathname === "/ListadoPendientes" ? "#041562" : "white",
                        minWidth: isDrawerOpen ? "auto" : 25,
                        mr: isDrawerOpen ? 2 : "auto",
                        justifyContent: "center",
                      },
                      "& .MuiListItemText-primary": {
                        color: location.pathname === "/ListadoPendientes" ? "#041562" : "white",
                      },
                      "&:hover": {
                        bgcolor: "white",
                        ".MuiListItemIcon-root": { color: "#041562" },
                        ".MuiListItemText-primary": { color: "#041562" },
                        borderRadius: "10px",
                      },
                      borderRadius: location.pathname === "/ListadoPendientes" ? "10px" : "0px",
                      margin: location.pathname === "/ListadoPendientes" ? "8px" : "0px",
                      overflow: "hidden",
                    }}
                  >
                    <ListItemIcon>
                      <PendingActionsIcon />
                    </ListItemIcon>
                    {isDrawerOpen && <ListItemText primary="Pendientes Manifiesto" />}
                  </ListItemButton>
                )}
                {isVisible("DescargaManifiestos") && (
                  <ListItemButton
                    component={Link}
                    to="/DescargaManifiestos"
                    sx={{
                      bgcolor: location.pathname === "/DescargaManifiestos" ? "white" : "transparent",
                      color: location.pathname === "/DescargaManifiestos" ? "#041562" : "white",
                      ".MuiListItemText-primary": {
                        fontSize: "0.775rem",
                      },
                      pl: isDrawerOpen ? 4 : 2,
                      "& .MuiListItemIcon-root": {
                        color: location.pathname === "/DescargaManifiestos" ? "#041562" : "white",
                        minWidth: isDrawerOpen ? "auto" : 25,
                        mr: isDrawerOpen ? 2 : "auto",
                        justifyContent: "center",
                      },
                      "& .MuiListItemText-primary": {
                        color: location.pathname === "/DescargaManifiestos" ? "#041562" : "white",
                      },
                      "&:hover": {
                        bgcolor: "white",
                        ".MuiListItemIcon-root": { color: "#041562" },
                        ".MuiListItemText-primary": { color: "#041562" },
                        borderRadius: "10px",
                      },
                      borderRadius: location.pathname === "/DescargaManifiestos" ? "10px" : "0px",
                      margin: location.pathname === "/DescargaManifiestos" ? "8px" : "0px",
                      overflow: "hidden",
                    }}
                  >
                    <ListItemIcon>
                      <Download />
                    </ListItemIcon>
                    {isDrawerOpen && <ListItemText primary="Descarga de manifiestos" />}
                  </ListItemButton>
                )}
                {isVisible("ConsultaCodigoBarra") && (
                  <ListItemButton
                    component={Link}
                    to="/ConsultaCodigoBarra"
                    sx={{
                      bgcolor: location.pathname === "/ConsultaCodigoBarra" ? "white" : "transparent",
                      color: location.pathname === "/ConsultaCodigoBarra" ? "#041562" : "white",
                      ".MuiListItemText-primary": {
                        fontSize: "0.775rem",
                      },
                      pl: isDrawerOpen ? 4 : 2,
                      "& .MuiListItemIcon-root": {
                        color: location.pathname === "/ConsultaCodigoBarra" ? "#041562" : "white",
                        minWidth: isDrawerOpen ? "auto" : 25,
                        mr: isDrawerOpen ? 2 : "auto",
                        justifyContent: "center",
                      },
                      "& .MuiListItemText-primary": {
                        color: location.pathname === "/ConsultaCodigoBarra" ? "#041562" : "white",
                      },
                      "&:hover": {
                        bgcolor: "white",
                        ".MuiListItemIcon-root": { color: "#041562" },
                        ".MuiListItemText-primary": { color: "#041562" },
                        borderRadius: "10px",
                      },
                      borderRadius: location.pathname === "/ConsultaCodigoBarra" ? "10px" : "0px",
                      margin: location.pathname === "/ConsultaCodigoBarra" ? "8px" : "0px",
                      overflow: "hidden",
                    }}
                  >
                    <ListItemIcon>
                      <Search />
                    </ListItemIcon>
                    {isDrawerOpen && <ListItemText primary="Consulta Codigo Barra NOVO" />}
                  </ListItemButton>
                )}
                {isVisible("GestionMedidas") && (
                  <ListItemButton
                    component={Link}
                    to="/GestionMedidas"
                    sx={{
                      bgcolor: location.pathname === "/GestionMedidas" ? "white" : "transparent",
                      color: location.pathname === "/GestionMedidas" ? "#041562" : "white",
                      ".MuiListItemText-primary": {
                        fontSize: "0.775rem",
                      },
                      pl: isDrawerOpen ? 4 : 2,
                      "& .MuiListItemIcon-root": {
                        color: location.pathname === "/GestionMedidas" ? "#041562" : "white",
                        minWidth: isDrawerOpen ? "auto" : 25,
                        mr: isDrawerOpen ? 2 : "auto",
                        justifyContent: "center",
                      },
                      "& .MuiListItemText-primary": {
                        color: location.pathname === "/GestionMedidas" ? "#041562" : "white",
                      },
                      "&:hover": {
                        bgcolor: "white",
                        ".MuiListItemIcon-root": { color: "#041562" },
                        ".MuiListItemText-primary": { color: "#041562" },
                        borderRadius: "10px",
                      },
                      borderRadius: location.pathname === "/GestionMedidas" ? "10px" : "0px",
                      margin: location.pathname === "/GestionMedidas" ? "8px" : "0px",
                      overflow: "hidden",
                    }}
                  >
                    <ListItemIcon>
                      <Edit />
                    </ListItemIcon>
                    {isDrawerOpen && <ListItemText primary="Edición Medidas NOVO" />}
                  </ListItemButton>
                )}
                {isVisible("ListaCorreos") && (
                  <ListItemButton
                    component={Link}
                    to="/ListaCorreos"
                    sx={{
                      bgcolor: location.pathname === "/ListaCorreos" ? "white" : "transparent",
                      color: location.pathname === "/ListaCorreos" ? "#041562" : "white",
                      ".MuiListItemText-primary": {
                        fontSize: "0.775rem",
                      },
                      pl: isDrawerOpen ? 4 : 2,
                      "& .MuiListItemIcon-root": {
                        color: location.pathname === "/ListaCorreos" ? "#041562" : "white",
                        minWidth: isDrawerOpen ? "auto" : 25,
                        mr: isDrawerOpen ? 2 : "auto",
                        justifyContent: "center",
                      },
                      "& .MuiListItemText-primary": {
                        color: location.pathname === "/ListaCorreos" ? "#041562" : "white",
                      },
                      "&:hover": {
                        bgcolor: "white",
                        ".MuiListItemIcon-root": { color: "#041562" },
                        ".MuiListItemText-primary": { color: "#041562" },
                        borderRadius: "10px",
                      },
                      borderRadius: location.pathname === "/ListaCorreos" ? "10px" : "0px",
                      margin: location.pathname === "/ListaCorreos" ? "8px" : "0px",
                      overflow: "hidden",
                    }}
                  >
                    <ListItemIcon>
                      <Email />
                    </ListItemIcon>
                    {isDrawerOpen && <ListItemText primary="Gestor de correos" />}
                  </ListItemButton>
                )}
                {userInfo.id === 739 ||
                userInfo.id === 722 ||
                userInfo.id === 70 ||
                userInfo.tipo_usuario === "ADMIN"
                  ? isVisible("GestorManifiestoSalida") && (
                      <ListItemButton
                        component={Link}
                        to="/GestorManifiestoSalida"
                        sx={{
                          bgcolor: location.pathname === "/GestorManifiestoSalida" ? "white" : "transparent",
                          color: location.pathname === "/GestorManifiestoSalida" ? "#041562" : "white",
                          ".MuiListItemText-primary": {
                            fontSize: "0.775rem",
                          },
                          pl: isDrawerOpen ? 4 : 2,
                          "& .MuiListItemIcon-root": {
                            color: location.pathname === "/GestorManifiestoSalida" ? "#041562" : "white",
                            minWidth: isDrawerOpen ? "auto" : 25,
                            mr: isDrawerOpen ? 2 : "auto",
                            justifyContent: "center",
                          },
                          "& .MuiListItemText-primary": {
                            color: location.pathname === "/GestorManifiestoSalida" ? "#041562" : "white",
                          },
                          "&:hover": {
                            bgcolor: "white",
                            ".MuiListItemIcon-root": { color: "#041562" },
                            ".MuiListItemText-primary": { color: "#041562" },
                            borderRadius: "10px",
                          },
                          borderRadius: location.pathname === "/GestorManifiestoSalida" ? "10px" : "0px",
                          margin: location.pathname === "/GestorManifiestoSalida" ? "8px" : "0px",
                          overflow: "hidden",
                        }}
                      >
                        <ListItemIcon>
                          <EditOffIcon />
                        </ListItemIcon>
                        {isDrawerOpen && <ListItemText primary="Gestor Manifiesto Salida" />}
                      </ListItemButton>
                    )
                  : null}
              </List>
            </Collapse>
          </List>
        </Collapse>
      </>
    )
  );
}

export default MenuOperaciones;
