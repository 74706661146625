import React, { useState, useEffect } from "react";
import {
  Paper,
  List,
  Button,
  Grid,
  ListItem,
  ListItemText,
  IconButton,
  Autocomplete,
  TextField,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";
import { getManifiestosPorPatente } from "../../../services/ManifiestosServices";
import { getPatentes } from "../../../services/ManifiestosServices";
import DescriptionIcon from "@mui/icons-material/Description";
import { format } from "date-fns";

const DescargaManifiestoPorPatente = () => {
  const [manifiestos, setManifiestos] = useState([]);
  const [fecha, setFecha] = useState("");
  const [patentes, setPatentes] = useState([]);
  const [selectedPatente, setSelectedPatente] = useState(null);

  useEffect(() => {
    const Patentes = async () => {
      try {
        const patentesData = await getPatentes();
        const patentesOptions = patentesData.map((patentes) => ({
          value: patentes.ID,
          label: patentes.PATENTE,
        }));
        setPatentes(patentesOptions);
      } catch {}
    };
    Patentes();
  }, []);

  const handleChangePatente = (event, newValue) => {
    if (newValue === null) {
      setSelectedPatente(null);
    } else {
      setSelectedPatente(newValue.label);
    }
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    try {
      setManifiestos([]);

      const datos = {
        FECHA: fecha,
        PATENTE: selectedPatente,
      };

      const response = await getManifiestosPorPatente(datos);
      if(response.length >0)
      {
        setManifiestos(response);
      }
      else{
        toast.error("Dia no cuenta con manifiestos de Ruta");
      }
      
    } catch (error) {
      console.error("Error al obtener detalles:", error);
    }
  };

  const handleChangeFecha = async (event, newValue) => {
    try {
      setFecha(event.target.value);
      setManifiestos([]);
    } catch (error) {}
  };

  return (
    <div>
      <form>
        <Paper style={{ padding: "10px" }}>
          <Typography variant="h6" align="center" gutterBottom>
            Busqueda Manifiestos por Proceso
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <Autocomplete
                value={selectedPatente}
                disablePortal
                id="size-small-outlined-multi"
                size="small"
                options={patentes}
                onChange={handleChangePatente}
                sx={{ width: "100%" }}
                PaperComponent={({ children }) => (
                  <Paper
                    sx={{
                      width: "100%",
                      textAlign: "left",
                      "& li": { textAlign: "left" },
                    }}
                  >
                    {children}
                  </Paper>
                )}
                renderInput={(params) => <TextField {...params} label="PATENTE" />}
                isOptionEqualToValue={(option, value) => option.value === value.value}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <TextField
                fullWidth
                type="date"
                size="small"
                label="Fecha"
                value={fecha}
                onChange={handleChangeFecha}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Button
                variant="contained"
                size="medium"
                sx={{
                  width: "100%",
                  height: "90%",
                  backgroundColor: "#041562",
                }}
                onClick={handleFormSubmit}
              >
                Buscar
              </Button>
            </Grid>
          </Grid>
          <div style={{ maxHeight: "650px", overflowY: "auto" }}>
            <List style={{ display: "flex", flexDirection: "column", padding: 0 }}>
              {manifiestos.map((manifiesto) => (
                <ListItem key={manifiesto.ID}>
                  <div style={{ position: "relative", display: "inline-block" }}>
                    <IconButton
                      onClick={() => {
                        const url = `https://backend.cargoexteam.cl/medicion/creacionManifiestoPDF/${
                          manifiesto.ID
                        }?useBarcode=${false}`;

                        window.open(url, "_blank");
                      }}
                    >
                      <DescriptionIcon style={{ color: "#DA251C" }} />
                    </IconButton>
                    <span
                      style={{
                        position: "absolute",
                        top: "0px",
                        right: "0px",
                        fontSize: "10px",
                        color: "#DA251C",
                      }}
                    >
                      PDF
                    </span>
                  </div>
                  <ListItemText
                    primary={`Manifiesto: ${manifiesto.ID}  / Creación: ${format(
                      new Date(manifiesto.FH_CREACION),
                      "dd-MM-yyyy",
                    )}`}
                    secondary={manifiesto.PROCESO === "SALIDARUTA" ? `CHOFER: ${manifiesto.CHOFER}` : null}
                    style={{ marginLeft: "10px" }}
                  />
                </ListItem>
              ))}
            </List>
          </div>
        </Paper>
      </form>
    </div>
  );
};

export default DescargaManifiestoPorPatente;
