import React, { useState, memo, useEffect, useCallback, useRef } from "react";
import moment from "moment";
import { Grid, Button, Card, CardContent, Autocomplete, TextField, Tooltip, Paper } from "@mui/material";
import Tabla from "../../components/DespachoComponentes/componentesRetiros/tabla";
import * as XLSX from "xlsx";
import Papa from "papaparse";
import { createOrderAPI, buscarCliente, CentroCostoClientes, buscarTipoUsuario } from "../../services/RetirosService";
import {
  toastDatosEnBlanco,
  ordenNoCreada,
  alertaComunasOrigenToast,
  alertaComunasDestinoToast,
  excelCargado,
  excelVacio,
} from "../../components/DespachoComponentes/componentesRetiros/Alertas";
import Swal from "sweetalert2";
import { ExcelDownloadLinkStgo } from "../../components/DespachoComponentes/componentesRetiros/DescargaExcel";
import { FloatingLabel, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  ValidadorArchivo,
  limiteDeFilas,
  LinearProgressOverlay,
  ValidarHomologacionCiudades,
} from "../../components/DespachoComponentes/componentesRetiros/Funciones";
import "../../components/assets/retiros.css";
import { useSelector } from "react-redux";

const RetirosClienteStgo = () => {
  const userInfo = useSelector((state) => state.user.userInfo);
  const id_usuario = userInfo ? userInfo.id : null;

  const [key, setKey] = useState(Date.now());
  const fileInputRef = useRef(null);
  const [data, setData] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [options, setOptions] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);
  const [progress, setProgress] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [fechaRetiros, setFechaRetiros] = useState();
  const [jsonDataArray, setJsonDataArray] = useState([]);
  const [codigoCliente, setCodigoCliente] = useState("");
  const [fechaManana, setFechaManana] = useState("");
  const [fechaFormateada, setFechaFormateada] = useState();
  const formControlRef = useRef();

  const [isAutocompleteEnabledCc, setIsAutocompleteEnabledCc] = useState(false);
  const [isAutocompleteEnabledFh, setIsAutocompleteEnabledFh] = useState(false);
  const [isbtnEnviar, setIsbtnEnviar] = useState(true);
  const [odCount, setOdCount] = useState(0);

  //Obtenemos cod_cliente segun id usuario en pagina
  const obtenerTipoCliente = useCallback(async () => {
    try {
      await buscarTipoUsuario(id_usuario);
    } catch (error) {
      console.error("Error al obtener el tipo de cliente:", error);
    }
  }, [id_usuario]);

  const obtenerDatosCliente = useCallback(async () => {
    try {
      const datoCodCliente = await buscarCliente(id_usuario);

      try {
        const dataAll = await CentroCostoClientes(datoCodCliente);

        const cCostoOptions = dataAll.map((canal) => ({
          value: canal.id,
          label: canal.CENTRO_COSTO,
        }));
        setOptions(cCostoOptions);
      } catch (e) {}

      setCodigoCliente(datoCodCliente);
    } catch (error) {
      console.error("Error al obtener los datos Cliente", error);
    }
  }, [id_usuario, setOptions, setCodigoCliente]);

  // La cabecera predefinida, excel debe terner los mismo datos.
  const predefinedHeaders = [
    "NOMBRE ORIGEN",
    "DIRECCION ORIGEN",
    "TELÉFONO",
    "COMUNA ORIGEN",
    "DESCRIPCION",
    "CENTRO DE COSTO",
    "CONTACTO ORIGEN",
    "BULTOS",
    "KILOS",
    "GUIA",
    "COMUNA DESTINO",
    "CORREO",
  ];

  const obligatoriasHeaders = [
    "NOMBRE ORIGEN",
    "DIRECCION ORIGEN",
    "TELÉFONO",
    "COMUNA ORIGEN",
    "CONTACTO ORIGEN",
    "COMUNA DESTINO",
  ];

  //Funcion para generar id referencia
  function generadorReferencia() {
    let date = new Date();
    let TEXT =
      date.getFullYear() +
      "" +
      (date.getMonth() + 1) +
      "" +
      date.getDate() +
      "" +
      date.getHours() +
      "" +
      date.getMinutes() +
      "" +
      date.getSeconds() +
      "" +
      date.getMilliseconds();

    const referencia = TEXT + Math.floor(Math.random() * 10000);

    return referencia;
  }

  //Control Autocomplete Centro Costo
  const handleChange = (event, value) => {
    try {
      setSelectedValue(value.label);
    } catch (error) {}
  };

  //Generamos continuacion IdReferencia
  function generateNumericId(length) {
    const charset = "0123456789";
    let numericId = "";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      numericId += charset[randomIndex];
    }

    return numericId;
  }

  //Funcion validar que la cabecera del excel sea la misma del codigo
  const validateHeaders = (parsedData, predefinedHeaders) => {
    const fileHeaders = parsedData.data[0];
    console.log("Encabezados del archivo CSV:", fileHeaders);
    console.log("Encabezados predefinidos:", predefinedHeaders);
    for (let i = 0; i < predefinedHeaders.length; i++) {
      if (fileHeaders[i] !== predefinedHeaders[i]) {
        return false;
      }
    }
    return true;
  };

  // Control Form Fecha
  const handleInputChangeFecha = (e) => {
    setFechaRetiros(e.target.value);
  };

  useEffect(() => {
    obtenerDatosCliente();

    obtenerTipoCliente();

    generateNumericId(2);

    const fechaActual = new Date();

    fechaActual.setDate(fechaActual.getDate() + 1);

    const formattedFechaManana = fechaActual.toISOString().split("T")[0];
    setFechaFormateada(fechaActual.toISOString().split("T")[0]);

    setFechaManana(formattedFechaManana);
    setFechaRetiros(formattedFechaManana);
  }, [obtenerDatosCliente, obtenerTipoCliente]);

  //Carga de Excel y sus validaciones
  const cargaExcelTabla = async (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsBinaryString(file);

    await new Promise((resolve) => {
      reader.onload = async (event) => {
        const workbook = XLSX.read(event.target.result, { type: "binary" });
        const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
        const csvData = XLSX.utils.sheet_to_csv(firstSheet);

        Papa.parse(csvData, {
          complete: async (parsedData) => {
            const headersMatch = validateHeaders(parsedData, predefinedHeaders);

            if (ValidadorArchivo(headersMatch, fechaRetiros)) {
              Papa.parse(csvData, {
                complete: async (parsedData) => {
                  const newHeaders = predefinedHeaders;
                  setHeaders(newHeaders);

                  const dataSinObjetosVacios = parsedData.data
                    .slice(0)
                    .map((row) => {
                      let obj = {};
                      newHeaders.forEach((header, index) => {
                        obj[header] = row[header];
                      });

                      return obj;
                    })
                    .filter((obj) => {
                      for (const key in obj) {
                        if (obj[key] !== "") {
                          return true;
                        }
                      }
                      return false;
                    });

                  setData(dataSinObjetosVacios);

                  parsedData.data.slice(0).forEach((row, index) => {
                    const rowData = {
                      NOMBRE: row["NOMBRE ORIGEN"],
                      DIRECCION: row["DIRECCION ORIGEN"],
                      TELEFONO: row["TELÉFONO"],
                      NOTA: `${row["DESCRIPCION"]} / ${row["CONTACTO ORIGEN"]}`,
                      CENTRO_COSTO: row["CENTRO DE COSTO"] || selectedValue,
                      BULTOS: row["BULTO"],
                      PESO: row["KILOS"],
                      TIPO_ORDEN: "RETIRO",
                      GUIA: row["GUIA"],
                      COD_CLIENTE: codigoCliente,
                      COMUNA: row["COMUNA DESTINO"],
                      MAIL: row["CORREO"],
                      FECHA_DIGITACION: fechaRetiros,
                      ID_REFERENCIA: generadorReferencia(),
                      USUARIO: id_usuario,
                      COMUNA_ORIGEN: row["COMUNA ORIGEN"],
                    };

                    if (limiteDeFilas(row)) {
                      jsonDataArray.push(JSON.stringify(rowData));
                    }
                  });

                  for (let rowIndex = 0; rowIndex < parsedData.data.length; rowIndex++) {
                    const row = parsedData.data[rowIndex];
                    if (limiteDeFilas(row)) {
                      const columnasFaltantes = obligatoriasHeaders.filter((columna) => !row[columna]);
                      if (columnasFaltantes.length > 0) {
                        toastDatosEnBlanco(columnasFaltantes.join(", "), rowIndex + 2);
                        setIsbtnEnviar(false);
                        e.target.value = "";
                        setData([]);
                        setJsonDataArray([]);
                        return;
                      }
                    }
                  }

                  const guiaFormatErrors = [];
                  parsedData.data.forEach((row, index) => {
                    const guias = row["GUIA"];
                    if (guias) {
                      const guiaParts = guias.split(" - ");
                      const isValidFormat =
                        guiaParts.every((part) => part.trim().length > 0) &&
                        (guiaParts.length === 1 ||
                          guiaParts.length === 2 ||
                          guiaParts.length === 3 ||
                          guiaParts.length === 4 ||
                          guiaParts.length === 5) &&
                        guias.match(/^([^ -]+( - [^ -]+)*)$/);
                      if (!isValidFormat) {
                        guiaFormatErrors.push(index + 2);
                      }
                    }
                  });

                  if (guiaFormatErrors.length > 0) {
                    const errorMessage = `Filas con formato incorrecto en columna GUIA: la Fila ${guiaFormatErrors.join(
                      ", ",
                    )} No tiene el formato correcto`;
                    toast.error(errorMessage);
                    setIsbtnEnviar(false);
                    e.target.value = "";
                    setData([]);
                    setJsonDataArray([]);
                    return;
                  }

                  try {
                    const comunasInvalidasOrigen = [];
                    const comunasInvalidasDestino = [];

                    const totalFilas = jsonDataArray.length;
                    let filaExcel = 0;
                    setIsVisible(true);

                    await Promise.all(
                      jsonDataArray.map(async (rowData) => {
                        const parsedRow = JSON.parse(rowData);
                        await ValidarHomologacionCiudades(parsedRow.COMUNA_ORIGEN, comunasInvalidasOrigen);
                        await ValidarHomologacionCiudades(parsedRow.COMUNA, comunasInvalidasDestino);
                        filaExcel++;
                        const newProgress = (filaExcel / totalFilas) * 100;
                        setProgress(newProgress);
                      }),
                    );

                    if (comunasInvalidasOrigen.length > 0) {
                      const newProgress = (filaExcel / totalFilas) * 100;
                      setProgress(newProgress);
                      if (newProgress === 100) {
                        setCompleted(true);
                      }
                      alertaComunasOrigenToast(comunasInvalidasOrigen);
                      setData([]);
                      setJsonDataArray([]);
                      setKey(Date.now());
                      e.target.value = "";
                    } else if (comunasInvalidasDestino.length > 0) {
                      const newProgress = (filaExcel / totalFilas) * 100;
                      setProgress(newProgress);
                      if (newProgress === 100) {
                        setCompleted(true);
                      }
                      alertaComunasDestinoToast(comunasInvalidasDestino);
                      setData([]);
                      setJsonDataArray([]);
                      setKey(Date.now());
                      e.target.value = "";
                    } else if (jsonDataArray.length === 0) {
                      excelVacio();
                      fileInputRef.current.value = "";
                    } else {
                      setJsonDataArray(jsonDataArray);
                      const odCount = parsedData.data.filter((row) => row).length;
                      setOdCount(odCount);
                      setIsbtnEnviar(false);
                      setIsAutocompleteEnabledCc(true);
                      setIsAutocompleteEnabledFh(true);
                      excelCargado();
                    }
                  } catch (error) {
                    console.error("Error en el procesamiento de datos:", error);
                  }
                  setProgress(100);
                  setIsVisible(false);
                  setProgress(0);
                  setCompleted(false);
                },
                header: true,
              });
            } else {
              setIsbtnEnviar(true);
              e.target.value = "";
              setKey(Date.now());
              fileInputRef.current.value = "";
              setData([]);
              setJsonDataArray([]);
            }
          },
        });
      };
    });
  };

  const LimpiaArray = (e) => {
    setJsonDataArray([]);
  };

  const Cancelar = async () => {
    resetState();
  };

  const resetState = () => {
    setData([]);
    setOdCount(0);
    setJsonDataArray([]);
    setIsAutocompleteEnabledCc(false);
    setIsAutocompleteEnabledFh(false);
    setIsVisible(false);
    setProgress(0);
    setCompleted(false);
    setIsbtnEnviar(true);
    fileInputRef.current.value = "";
  };

  const tooltipMessage =
    "Si se selecciona un Centro de Costo, este será automáticamente agregado a las filas vacías y se reflejará en el archivo final.";

  const crearOrdenes = async () => {
    const totalOrders = jsonDataArray.length;
    let createdOrdersCount = 0;

    const createdOrders = [];

    for (const jsonData of jsonDataArray) {
      try {
        setIsVisible(true);

        const response = await createOrderAPI(jsonData);

        createdOrdersCount++;
        const newProgress = (createdOrdersCount / totalOrders) * 100;
        setProgress(newProgress);

        if (response && response.success) {
          createdOrders.push(response);
          const newProgress = (createdOrdersCount / totalOrders) * 100;
          setProgress(newProgress);
          if (newProgress === 100) {
            setCompleted(true);
          }
        }
      } catch (error) {
        toast.error("Error al crear la orden. Por favor, inténtalo de nuevo.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    }

    setProgress(100);

    if (createdOrders.length > 0) {
      Swal.fire({
        title: "Retiros creados con éxito",
        text: "Todas las órdenes se han creado exitosamente.",
        icon: "success",
        confirmButtonText: "Cerrar",
      });
      setIsVisible(false);
      setProgress(0);
      setCompleted(false);

      createdOrders.forEach((order, index) => {
        const rowIndex = index + 1;

        if (rowIndex <= data.length) {
          data[rowIndex - 1]["ORDEN D."] = order.od;
        }
      });

      const selectedCc = selectedValue;
      const updatedData = data.map((row) => ({
        ...row,
        "CENTRO DE COSTO": row["CENTRO DE COSTO"] || selectedCc,
      }));

      const filteredData = updatedData.filter((row) => limiteDeFilas(row, "CENTRO DE COSTO"));
      setData(filteredData);

      const fechaActual = moment().format("YYYY-MM-DD");

      const nombreArchivo = `Retiros_${fechaActual}.xlsx`;

      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(updatedData, {
        header: headers,
      });

      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");
      XLSX.writeFile(workbook, nombreArchivo);

      setTimeout(() => {
        setKey(Date.now());
        fileInputRef.current.value = "";

        setData([]);
        setJsonDataArray([]);
        setFechaRetiros(fechaFormateada);
        formControlRef.current.value = fechaManana;
        resetState();
      }, 2000);
    } else {
      ordenNoCreada();
    }
  };

  return (
    <div>
      <Paper style={{ padding: "10px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Tooltip title={tooltipMessage} arrow placement="left">
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                onChange={handleChange}
                options={options}
                renderInput={(params) => <TextField {...params} label="CENTRO COSTO" />}
                disabled={isAutocompleteEnabledCc}
              />
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FloatingLabel controlId="floatingInput" label="Fecha" className="mb-3 h-100" width="10px">
              <Form.Control
                className="tu-archivo-de-estilos1"
                type="date"
                name="FH_DIGITACION"
                onChange={handleInputChangeFecha}
                defaultValue={fechaManana}
                ref={formControlRef}
                disabled={isAutocompleteEnabledFh}
              />
            </FloatingLabel>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Form.Group controlId="formFile">
              <Form.Label className="tu-archivo-de-estilos1">Seleccione Archivo Excel</Form.Label>
              <Form.Control
                type="file"
                ref={fileInputRef}
                key={key}
                onChange={cargaExcelTabla}
                onClick={LimpiaArray}
                size="sm"
              />
            </Form.Group>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Button
              style={{ backgroundColor: "#041562", color: "#FFFFFF" }}
              className="boton2"
              size="large"
              variant="contained"
              color="primary"
              onClick={crearOrdenes}
              disabled={isbtnEnviar}
            >
              Enviar
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              style={{ backgroundColor: "#DA251C" }}
              className="boton2"
              size="large"
              variant="contained"
              color="primary"
              onClick={Cancelar}
            >
              Cancelar
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <h3>Órdenes Cargadas: {odCount}</h3>
        </Grid>
        <div className="centered-link">
          <ExcelDownloadLinkStgo />
        </div>

        <div>
          <LinearProgressOverlay isVisible={isVisible} progress={progress} completed={completed} disabled={false} />
        </div>

        {data.length > 0 && (
          <Grid item xs={10} sm={10} md={6} style={{ width: "100%", maxWidth: "100%" }}>
            <Card elevation={10} className="cardContentWithMargin">
              <CardContent
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  Width: "100%",
                  maxHeight: "75vh",
                  overflowX: "auto",
                  overflowY: "auto",
                }}
              >
                <Tabla
                  data={data}
                  headers={headers}
                  onCpdateellU={(rowIndex, header, newValue) => {
                    const newData = [...data];
                    newData[rowIndex][header] = newValue;
                    setData(newData);
                  }}
                />
              </CardContent>
            </Card>
          </Grid>
        )}
      </Paper>
    </div>
  );
};

export default memo(RetirosClienteStgo);
