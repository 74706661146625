import axios from "axios";
const BASE_API_URL = process.env.REACT_APP_BACKEND_URL;

const apiClient = axios.create({
  baseURL: BASE_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

/* obtenemos Graficos ejecutiva */
export const obtenerGraficoEjecutiva = async (ID_USERS) => {
  try {
    const response = await apiClient.post("inicio/obtenerGraficoEjecutiva", {
      ID_USERS,
    });
    return response.data;
  } catch (error) {
    console.error("Error al obtener los datos:", error.response);
    throw error;
  }
};
