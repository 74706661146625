import axios from "axios";

/* Usa la variable de entorno para la URL base */
const BASE_API_URL = process.env.REACT_APP_BACKEND_URL;

const apiClient = axios.create({
  baseURL: BASE_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const crearManifiestoChofer = async (cod_chofer, od_papeles, cod_usuario) => {
  try {
    const response = await apiClient.post("appMovil/crearManifiestoChofer", {
      cod_chofer,
      od_papeles,
      cod_usuario,
    });
    return response.data;
  } catch (error) {
    console.error("Error al crear el manifiesto:", error.response);
    throw error;
  }
};

export const obtenerOrdenesPrueba = async () => {
  try {
    const response = await apiClient.get("appMovil/obtenerOrdenesPrueba");
    return response.data;
  } catch (error) {
    console.error("Error al obtener las órdenes:", error.response);
    throw error;
  }
};

