import React, { useState, useEffect, useRef, useCallback } from "react";
import "../../../components/OperacionesComponentes/componentesManifiestoSalidaAnden/Styles";
import { Button, Grid, TextField, Typography, Autocomplete, Paper, CircularProgress } from "@mui/material";
import "bootstrap/dist/css/bootstrap.min.css";
import moment from "moment";
import useSound from "use-sound";
import alerta from "../../../components/OperacionesComponentes/componentesManifiestos/Sonidos/AlertaMa.mp3";
import alertaManifiesto0 from "../../../components/OperacionesComponentes/componentesManifiestos/Sonidos/Alerta Manifiesto.mp3";
import alertaFecha from "../../../components/OperacionesComponentes/componentesManifiestos/Sonidos/Alerta Fecha Diferente.mp3";
import {
  buscarDatosOrden,
  insertPickingTemp,
  insertManifiesto,
  deletePickingTemp,
  insertPicking,
  buscarDatosPickingTemp,
  getCiudades,
  buscarTransportistas,
  buscarPendientesSalida,
  getPassword,
  getAgentes,
  downloadManifestPdfGlobal,
} from "../../../services/ManifiestosServices";
import Mail_Gestor_Salida from "../../../components/OperacionesComponentes/componentesManifiestoSalidaAnden/Mail";
import Swal from "sweetalert2";
import TablaComparativa from "../../../components/OperacionesComponentes/componentesManifiestoSalidaAnden/TablaComparativa";
import { useSelector } from "react-redux";
import TablaManifiestos from "../../../components/OperacionesComponentes/componentesManifiestos/TablaManifiestos";
import DeleteIcon from "@mui/icons-material/Delete";

const ManifiestoSalida = () => {
  const inputRef = useRef(null);

  const userInfo = useSelector((state) => state.user.userInfo);
  const id_user = userInfo ? userInfo.id : null;
  const nombreUser = userInfo ? userInfo.name : null;
  const iataUser = userInfo ? userInfo.iata_agentes : null;
  const ciudadUser = userInfo ? userInfo.nombre_ciudad_agentes : null;
  //const correoAgente = userInfo ? userInfo.email : null;
  const [correoAgente, setCorreoAgente ]= useState("");
  const [proceso, setProceso] = useState("");
  const [fechaActual, setfechaActual] = useState("");
  const [nota, setNota] = useState("");
  const [ciudad, setCiudad] = useState(null);
  const [ciudadesOption, setCiudadesOption] = useState([]);
  const [iataDestino, setIataDestino] = useState(null);
  const [transporte, setTransporte] = useState(null);
  const [idTransporte, setIdTransporte] = useState(null);
  const [transporteOption, setTransporteOption] = useState([]);
  const [agente, setAgente] = useState(null);
  const [agentesOption, setAgentesOption] = useState([]);
  const [via, setVia] = useState(null);
  const [viaOption, setViaOption] = useState([]);
  const [sellos, setSellos] = useState("");
  const [totalBultos, setTotalBultos] = useState(0);
  const [totalOrdenes, setTotalOrdenes] = useState(0);
  const [ordenesDataTabla, setOrdenesDataTabla] = useState([]);
  const [ordenesDataTablaM, setOrdenesDataTablaM] = useState([]);
  const [ordenesPendientes, setOrdenesPendientes] = useState(0);
  const [password, setPassword] = useState(null);
  const [agenteSeleccionado, setAgenteSeleccionado] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const [loadingEliminar, setLoadingEliminar] = React.useState(false);
  const [isbtnEnviar, setIsbtnEnviar] = useState(false);
  const [playsound] = useSound(alerta);
  const [playsoundManifiesto, { stop: stopManifiesto }] = useSound(alertaManifiesto0, {});
  const [playsoundFecha, { stop: stopFecha }] = useSound(alertaFecha, {});

  useEffect(() => {
    const fetchData = async () => {
      try {
        setProceso("SALIDAANDEN");
        console.log(userInfo);

        const fechaActual = moment().format("YYYY-MM-DD");
        setfechaActual(fechaActual);

        const dataCiudades = await getCiudades();
        const ciudadesOption = dataCiudades.map((ciudades) => ({
          value: ciudades.IATA,
          label: ciudades.NOMBRE,
        }));
        setCiudadesOption(ciudadesOption);

        const dataTransporte = await buscarTransportistas();
        const transporteOption = dataTransporte.map((transportes) => ({
          value: transportes.COD_CHOFER,
          label: transportes.NOMBRE,
        }));
        setTransporteOption(transporteOption);

        const opciones = [
          { label: "AÉREO", value: "AEREO" },
          { label: "TERRESTRE", value: "TERRESTRE" },
        ];
        setViaOption(opciones);

        const passwords = await getPassword();
        setPassword(passwords[0].PASSWORD);
      } catch {}
    };
    fetchData();
  }, [userInfo]);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const columns = [
    { field: "OD", headerName: "OD", width: 100 },
    { field: "NOMBRE", headerName: "Cliente", width: 250 },
    { field: "BULTOS", headerName: "Bultos", width: 100 },
    { field: "TIPO_ORDEN", headerName: "Tipo Orden", width: 100 },
    { field: "NOMBRE_DESTINO", headerName: "Comuna", width: 150 },
    {
      field: "delete",
      headerName: "Eliminar",
      width: 100,
      renderCell: (params) => (
        <DeleteIcon style={{ color: "#DA251C", cursor: "pointer" }} onClick={() => handleDelete(params.row.OD)} />
      ),
    },
  ];
  const handleDelete = async (orden) => {
    setLoadingEliminar(true);
    const result = await Swal.fire({
      title: "¿Estás seguro?",
      text: "No podrás revertir esta acción",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, eliminar!",
    });

    if (result.isConfirmed) {
      try {
        const response = await deletePickingTemp({
          OD: orden,
          COD_USUARIO: id_user,
          PROCESO: proceso,
        });

        if (response.message === "PickingTemp Eliminado con éxito") {
          const datos = {
            COD_USUARIO: id_user,
            COMUNA: iataDestino,
            PROCESO: proceso,
            AGENTE: agente.label,
          };
          try {
            const resultados = await buscarDatosPickingTemp(datos);

            let totalBultosTemp = 0;
            if (resultados && resultados.results) {
              resultados.results.forEach((orden) => {
                if (orden.TIPO_ORDEN !== "RETIRO") {
                  totalBultosTemp += orden.BULTOS;
                }
              });
              setTotalOrdenes(resultados.results.length);
            }

            setTotalBultos(totalBultosTemp);

            if (resultados && Array.isArray(resultados.results)) {
              setOrdenesDataTabla(resultados.results);
            } else {
              setOrdenesDataTabla([]);
            }
            const dataManif = await buscarPendientesSalida(iataDestino, iataUser);

            setOrdenesDataTablaM(dataManif);
            setOrdenesPendientes(dataManif.length);
          } catch (error) {
            console.error("Error al buscar datos de picking temp:", error);
            setOrdenesDataTabla([]);
          }
        } else {
          Swal.fire("No se pudo eliminar", response.message, "error");
        }
      } catch (error) {
        console.error("Error al eliminar el picking temp:", error);
        Swal.fire("Error!", "No se pudo eliminar el registro debido a un error en el servidor.", "error");
      } finally {
        setLoadingEliminar(false);
      }
    }
    setLoadingEliminar(false);
  };

  const timeoutRef = useRef();

  const getFecha = () => {
    try {
      const fechaActual = new Date();
      const options = {
        timeZone: "America/Santiago",
        hour12: false,
      };

      const fecha = fechaActual.toLocaleDateString("es-CL", options);

      const [dia, mes, año] = fecha.split("-");

      const nuevaFecha = `${año}-${mes}-${dia}`;

      const hora = fechaActual.toLocaleTimeString("es-CL", {
        ...options,
        hour12: false,
      });

      const fechaHoraFormateada = `${nuevaFecha} ${hora}`;

      return fechaHoraFormateada;
    } catch (error) {
      console.error("Error al obtener la fecha:", error);
      return null;
    }
  };

  const showError = (message) => {
    Swal.fire({
      icon: "error",
      title: message,
    });
    playsound();
    inputRef.current.value = "";
  };

  const resetState = () => {
    setNota("");
    setSellos("");
    setTransporte(null);
    setIdTransporte(null);
    setIataDestino(null);
    setCiudad(null);
    setAgente(null);
    setVia(null);
    inputRef.current.value = "";
    setOrdenesDataTabla([]);
    setOrdenesDataTablaM([]);
    setOrdenesPendientes(0);
    setTotalBultos(0);
    setTotalOrdenes(0);
    const fechaActual = moment().format("YYYY-MM-DD");
    setfechaActual(fechaActual);
    setAgenteSeleccionado(false);
    setIsbtnEnviar(false);
    setLoading(false);
  };

  const handleChangeCiudades = async (event, newValue) => {
    setCiudad(newValue);
    setIataDestino(newValue.value);

    const dataAgentes = await getAgentes(newValue.value);
    const agentesOption = dataAgentes.map((agentes) => ({
      value: 0,
      label: agentes.AGENTE_ASIGNADO,
      email: agentes.email,
    }));
    setAgentesOption(agentesOption);
  };

  const handleChangeTransporte = (event, newValue) => {
    setTransporte(newValue);
    setIdTransporte(newValue.value);
  };

  const handleActualizar = async () => {
    const dataManif = await buscarPendientesSalida(iataDestino, iataUser);
    setLoading(false);
    setIsbtnEnviar(false);
    setOrdenesDataTablaM(dataManif);
    setOrdenesPendientes(dataManif.length);
  };

  const actualizarPendientesSalida = () => {
    buscarPendientesSalida(iataDestino, iataUser)
      .then((dataManif) => {
        setOrdenesDataTablaM(dataManif);
        setOrdenesPendientes(dataManif.length);
        timeoutRef.current = setTimeout(actualizarPendientesSalida, 2 * 60 * 1000);
      })
      .catch((error) => console.error("Error al actualizar los datos pendientes de salida:", error));
  };

  const handleChangeAgentes = async (event, newValue) => {
    setAgente(newValue);
    setAgenteSeleccionado(newValue !== null);
    setCorreoAgente(newValue.email);

    const datos = {
      COD_USUARIO: id_user,
      COMUNA: iataDestino,
      PROCESO: proceso,
      AGENTE: newValue.label,
    };

    const dataPickingTemp = await buscarDatosPickingTemp(datos);

    let totalBultosTemp = 0;
    if (dataPickingTemp && dataPickingTemp.results) {
      dataPickingTemp.results.forEach((orden) => {
        if (orden.TIPO_ORDEN !== "RETIRO") {
          totalBultosTemp += orden.BULTOS;
        }
      });
      setTotalOrdenes(dataPickingTemp.results.length);
    }

    setTotalBultos(totalBultosTemp);

    if (dataPickingTemp && dataPickingTemp.results) {
      setOrdenesDataTabla(dataPickingTemp.results);
    } else {
      setOrdenesDataTabla([]);
    }
    actualizarPendientesSalida();
  };

  const handleChangeVia = async (event, newValue) => {
    setVia(newValue);
  };

  const handleChangeSellos = (event) => {
    setSellos(event.target.value);
  };

  const handleKeyDownSellos = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      setSellos(sellos + "-");
    }
  };

  const handleChangeNota = (event) => {
    setNota(event.target.value);
  };

  const ingresarOrdenTabla = async (dataOrden) => {
    try {
      if (dataOrden && dataOrden.length > 0) {
        setOrdenesDataTabla([...ordenesDataTabla, dataOrden[0]]);
        if (dataOrden[0].TIPO_ORDEN !== "RETIRO") {
          setTotalBultos(totalBultos + dataOrden[0].BULTOS);
        }

        setTotalOrdenes(totalOrdenes + 1);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const ordenIngresada = useCallback(
    async (event) => {
      event.preventDefault();
      const ordenValue = inputRef.current.value.trim();

      try {
        if (iataDestino === null) {
          showError("Debe Seleccionar un Destino");
          return;
        }
        if (agente === null) {
          showError("Debe Seleccionar un Agente");
          return;
        }
        if (transporte === null) {
          showError("Debe Seleccionar un Transporte");
          return;
        }
        if (via === null) {
          showError("Debe Seleccionar una Via");
          return;
        }

        const ordenExistente = ordenesDataTabla.some((orden) => {
          const ordenOD = orden.OD.toString().trim();
          return ordenOD === ordenValue;
        });

        if (ordenExistente) {
          showError("Orden ya ingresada al manifiesto!!!");
          return;
        } else {
          const dataOrden = await buscarDatosOrden(ordenValue);

          if (!dataOrden) {
            showError("Orden no existe!!!");
            return;
          } else {
            let mensaje = "";
            if (
              (dataOrden[0].TIPO_ORDEN === "ENTREGA" && iataDestino !== dataOrden[0].IATA_PADRE_COMUNA_DESTINO) ||
              (dataOrden[0].TIPO_ORDEN === "RETIRO" && iataDestino !== dataOrden[0].IATA_PADRE_COMUNA_ORIGEN)
            ) {
              mensaje = "Destino";
            } else if (
              moment(dataOrden[0].FH_DIGITACION).format("YYYY-MM-DD") !== fechaActual &&
              dataOrden[0].TIPO_ORDEN === "ENTREGA"
            ) {
              mensaje = "Fecha";
            }

            if (mensaje !== "") {
              playsoundFecha();
              const { value: passwordC, dismiss } = await Swal.fire({
                title: `Está a punto de ingresar una Orden con ${mensaje} diferente!!!`,
                icon: "warning",
                showCancelButton: true,
                cancelButtonText: "Cancelar",
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Si, Ingresar!",
                html: '<input id="swal-input1" class="swal2-input" type="password" placeholder="Contraseña">',
                preConfirm: () => {
                  return document.getElementById("swal-input1").value;
                },
              });

              if (dismiss === "cancel") {
                stopFecha();
                inputRef.current.value = "";
                return;
              }

              if (passwordC === password) {
                const response = await insertPickingTemp({
                  OD: dataOrden[0].OD,
                  COD_USUARIO: id_user,
                  COD_CHOFER: id_user,
                  COMUNA: iataDestino,
                  PROCESO: proceso,
                  AGENTE: agente.label,
                });

                if (response === true) {
                  ingresarOrdenTabla(dataOrden);
                  stopFecha();
                  inputRef.current.value = "";
                } else {
                  showError(`Error en la inserción, favor de repetir el pistoleo.`);
                }
              } else {
                Swal.fire(`Contraseña incorrecta`);
              }
            } else {
              const response = await insertPickingTemp({
                OD: dataOrden[0].OD,
                COD_USUARIO: id_user,
                COD_CHOFER: id_user,
                COMUNA: iataDestino,
                PROCESO: proceso,
                AGENTE: agente.label,
              });

              if (response === true) {
                ingresarOrdenTabla(dataOrden);
                inputRef.current.value = "";
              } else {
                showError(`Error en la inserción, favor de repetir el pistoleo.`);
              }
            }
          }
        }
      } catch (error) {
        console.error("Error:", error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      iataDestino,
      agente,
      transporte,
      via,
      fechaActual,
      playsoundFecha,
      password,
      stopFecha,
      ordenesDataTabla,
      id_user,
      proceso,
      playsound,
    ],
  );

  const handleIngresarOrden = async (event) => {
    await ordenIngresada(event);
  };

  const crearManifiesto = async () => {
    try {
      const fecha = getFecha();

      const data = {
        FH_CREACION: fecha,
        FH_CIERRE: fecha,
        COD_USUARIO: id_user,
        PROCESO: proceso,
        ID_CLIENTE: 0,
        COD_CHOFER: idTransporte,
        ESTADO: "CERRADO",
        COD_OPERADOR_ENTRADA: id_user,
        COD_CHOFER_ENTRADA: id_user,
        NOMBRE_CHOFER_ENTRADA: nombreUser,
        SELLOS: sellos,
        CANTIDAD_VALIJAS: 0,
        TOTAL_BULTOS: totalBultos,
        TIPO_RUTA: via.label,
        TIPO_MANIFIESTO: proceso,
        ORIGEN: iataUser,
        DESTINO: iataDestino,
        NOTA: nota,
        FH_MAIL: "",
        RUTA: iataUser,
      };

      const response = await insertManifiesto(data);

      const datosParaEnviar = {
        COD_USUARIO: id_user,
        TIPO_MANIFIESTO: proceso,
        DESTINO: iataDestino,
        ID_MANIFIESTO: response,
        AGENTE: agente.label,
      };

      try {
        await insertPicking(datosParaEnviar);
        setOrdenesDataTabla([]);
        setTotalBultos("");
      } catch (error) {
        console.log("Fallo el envio");
      }
      return response;
    } catch (error) {
      console.error("Error al crear el manifiesto:", error);
    }
  };

  const handleCrearManifiesto = async () => {
    setLoading(true);
    setIsbtnEnviar(true);
    try {
      const dataManif = await buscarPendientesSalida(iataDestino, iataUser);
      setLoading(false);
      setIsbtnEnviar(false);
      setOrdenesDataTablaM(dataManif);
      setOrdenesPendientes(dataManif.length);
      if (ordenesDataTabla.length === 0) {
        showError("Manifiesto debe contener alguna orden!!!");
        playsound();
        return;
      } else if (dataManif.length !== 0) {
        Swal.fire({
          title: "Aún quedan ordenes pendientes",
          text: "Esta a punto de crear un nuevo Manifiesto!",
          icon: "warning",
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si, Crear!",
        }).then(async (result) => {
          if (result.isConfirmed) {
            let passwordCorrecta = false;
            while (!passwordCorrecta) {
              const { value: passwordC, dismiss } = await Swal.fire({
                title: "Ingrese su contraseña",
                html: '<input id="swal-input1" class="swal2-input" type="password" placeholder="Contraseña">',
                showCancelButton: true,
                cancelButtonText: "Cancelar",
                preConfirm: () => {
                  return document.getElementById("swal-input1").value;
                },
              });
              if (dismiss === "cancel") {
                stopManifiesto();
                inputRef.current.value = "";

                return;
              }
              if (passwordC === password) {
                passwordCorrecta = true;
                stopManifiesto();
                setLoading(true);
                const numeroManifiesto = await crearManifiesto();
                await Mail_Gestor_Salida({ proceso, correoAgente, numeroManifiesto });
                Swal.fire({
                  title: "Manifiesto Creado",
                  text: "N°: " + numeroManifiesto,
                  icon: "success",
                });
                try {
                  await downloadManifestPdfGlobal(numeroManifiesto, proceso, ciudad.label);
                  resetState();
                } catch (error) {
                  showError("Error al descargar el PDF del manifiesto");
                }
              } else {
                Swal.fire(`Contraseña incorrecta`);
              }
            }
          } else {
            stopManifiesto();
          }
        });
        playsoundManifiesto();
      } else {
        Swal.fire({
          title: "Seguro de Crear Manifiesto?",
          text: "Esta a punto de crear un nuevo Manifiesto!",
          icon: "warning",
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si, Crear!",
        }).then(async (result) => {
          if (result.isConfirmed) {
            setLoading(true);
            const numeroManifiesto = await crearManifiesto();
            await Mail_Gestor_Salida({ proceso, correoAgente, numeroManifiesto });
            resetState();
            Swal.fire({
              title: "Manifiesto Creado",
              text: "N°: " + numeroManifiesto,
              icon: "success",
            });
            try {
              await downloadManifestPdfGlobal(numeroManifiesto, proceso, ciudad.label);
            } catch (error) {
              showError("Error al descargar el PDF del manifiesto");
            }
          }
        });
      }
    } catch {}
  };

  return (
    <div>
      <Paper style={{ padding: "10px" }}>
        <form>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <TextField
                disabled
                size="small"
                id="outlined-size-small"
                label="CIUDAD"
                value={ciudadUser}
                sx={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <TextField
                disabled
                size="small"
                id="outlined-size-small"
                label="RESPONSABLE"
                value={nombreUser}
                sx={{ width: "100%" }}
              />
            </Grid>

            <Grid item xs={12} sm={4} md={4} lg={4}>
              <TextField
                disabled
                size="small"
                id="outlined-size-small"
                name="FH_ACTUAL"
                //type="datetime-local"
                label="Fecha"
                sx={{ width: "100%" }}
                value={fechaActual}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <Autocomplete
                disableClearable
                value={ciudad}
                disablePortal
                id="size-small-outlined-multi"
                size="small"
                options={ciudadesOption}
                onChange={handleChangeCiudades}
                sx={{ width: "100%" }}
                disabled={agenteSeleccionado}
                PaperComponent={({ children }) => (
                  <Paper
                    sx={{
                      width: "100%",
                      textAlign: "left",
                      "& li": { textAlign: "left" },
                    }}
                  >
                    {children}
                  </Paper>
                )}
                renderInput={(params) => <TextField {...params} label="DESTINO" />}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <Autocomplete
                disableClearable
                value={agente}
                disablePortal
                id="size-small-outlined-multi"
                size="small"
                options={agentesOption}
                onChange={handleChangeAgentes}
                sx={{ width: "100%" }}
                disabled={agenteSeleccionado}
                PaperComponent={({ children }) => (
                  <Paper
                    sx={{
                      width: "100%",
                      textAlign: "left",
                      "& li": { textAlign: "left" },
                    }}
                  >
                    {children}
                  </Paper>
                )}
                renderInput={(params) => <TextField {...params} label="AGENTE" />}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <Autocomplete
                disableClearable
                value={transporte}
                disablePortal
                id="size-small-outlined-multi"
                size="small"
                options={transporteOption}
                onChange={handleChangeTransporte}
                sx={{ width: "100%" }}
                PaperComponent={({ children }) => (
                  <Paper
                    sx={{
                      width: "100%",
                      textAlign: "left",
                      "& li": { textAlign: "left" },
                    }}
                  >
                    {children}
                  </Paper>
                )}
                renderInput={(params) => <TextField {...params} label="TRANSPORTE" />}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <Autocomplete
                disableClearable
                value={via}
                disablePortal
                id="size-small-outlined-multi"
                size="small"
                options={viaOption}
                onChange={handleChangeVia}
                sx={{ width: "100%" }}
                PaperComponent={({ children }) => (
                  <Paper
                    sx={{
                      width: "100%",
                      textAlign: "left",
                      "& li": { textAlign: "left" },
                    }}
                  >
                    {children}
                  </Paper>
                )}
                renderInput={(params) => <TextField {...params} label="VIA" />}
              />
            </Grid>

            <Grid item xs={12} sm={8} md={8} lg={8}>
              <TextField
                size="small"
                id="outlined-size-small"
                fullWidth
                label="Nota"
                multiline
                rows={1}
                name="message"
                value={nota}
                onChange={handleChangeNota}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TextField
                size="small"
                id="outlined-size-small"
                fullWidth
                label="SELLOS"
                multiline
                rows={1}
                name="message"
                value={sellos}
                onKeyDown={handleKeyDownSellos}
                onChange={handleChangeSellos}
                required
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4} justifyContent="flex-end">
              <Button
                variant="contained"
                size="medium"
                sx={{
                  width: "100%",
                  height: "90%",
                  backgroundColor: "#041562",
                }}
                onClick={handleCrearManifiesto}
                disabled={isbtnEnviar}
              >
                {loading ? <CircularProgress sx={{ color: "#FFFFFF" }} size={24} /> : "Crear Manifiesto"}
              </Button>
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              {loading && <CircularProgress />}

              {""}
            </Grid>
          </Grid>
        </form>
      </Paper>
      <Paper style={{ padding: "10px", marginTop: "10px" }}>
        <form>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={2} md={2} lg={2}>
              <input
                ref={inputRef}
                type="text"
                placeholder="Ingrese OD"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    ordenIngresada(e);
                  }
                }}
                required
              />
            </Grid>
            <Grid item xs={12} sm={2} md={2} lg={2} justifyContent="flex-end">
              <Button
                variant="contained"
                size="medium"
                sx={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#041562",
                }}
                onClick={handleIngresarOrden}
              >
                INGRESAR
              </Button>
            </Grid>
            <Grid item xs={12} sm={2} md={2} lg={2}>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Arial, sans-serif",
                  color: "#041562",
                  fontWeight: "bold",
                  fontSize: "1.3rem",
                }}
              >
                ORDENES: {totalOrdenes}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={2} md={2} lg={2}>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "Arial, sans-serif",
                  color: "#041562",
                  fontWeight: "bold",
                  fontSize: "1.3rem",
                }}
              >
                BULTOS: {totalBultos}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4} justifyContent="flex-end">
              <Button
                variant="contained"
                size="medium"
                sx={{
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#DA251C",
                }}
                onClick={handleActualizar}
              >
                ACTUALIZAR
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={8} md={8} lg={8} style={{ marginTop: "10px", marginBottom: "10px" }}>
              <TablaManifiestos rows={ordenesDataTabla} columns={columns} loading={loadingEliminar} />
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4} style={{ marginTop: "10px", marginBottom: "10px" }}>
              <TablaComparativa ordenesDataTablaM={ordenesDataTablaM} pendientes={ordenesPendientes} />
            </Grid>
          </Grid>
        </form>
      </Paper>
    </div>
  );
};
export default ManifiestoSalida;
