import React, { useState, useMemo } from "react";
import { Box, TextField, Button } from "@mui/material";
import { DataGrid, esES } from "@mui/x-data-grid";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as XLSX from "xlsx";

const ReusableTableCedibles = ({ columns, rows, loading, searchField }) => {
  const [searchText, setSearchText] = useState("");
  const [showDuplicates, setShowDuplicates] = useState(false);

  const filteredRows = useMemo(() => {
    const searchLowercase = searchText.toLowerCase().trim();
    let filtered = rows;

    if (showDuplicates) {
      const guiaCounts = rows.reduce((acc, row) => {
        acc[row.guia] = (acc[row.guia] || 0) + 1;
        return acc;
      }, {});

      filtered = rows.filter((row) => guiaCounts[row.guia] > 1);
    }

    return filtered.filter((row) => {
      const searchValue = row[searchField] 
        ? row[searchField].toString().toLowerCase().trim() 
        : "";
      
      return searchValue === searchLowercase || 
             searchValue.includes(searchLowercase);
    }).map((row, index) => ({
      ...row,
      uniqueId: `${row.guia}-${index}`
    }));
  }, [searchText, rows, searchField, showDuplicates]);

  const formatDataForExport = (data) => {
    return data.map((row) => ({
      Guía: row.guia,
      "OD Papel": row.odPapel,
      "Fecha digitacion": new Date(row.fecha).toLocaleString("es-CL", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      }),
    }));
  };

  const exportToExcel = () => {
    if (filteredRows.length === 0) {
      toast.error("No hay datos para descargar.");
      return;
    }
    const formattedData = formatDataForExport(filteredRows);
    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "guias");
    XLSX.writeFile(workbook, "Guias.xlsx");
  };

  return (
    <Box sx={{ height: 340, width: 1 }}>
      <Box sx={{ display: "flex", alignItems: "center", gap: 1, mb: 1 }}>
        <TextField
          fullWidth
          size="small"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          variant="outlined"
          placeholder={`Buscar por ${searchField}...`}
          sx={{ mt: 1, flex: 1 }}
        />
        <Button
          variant="contained"
          onClick={exportToExcel}
          sx={{
            mt: 1,
            backgroundColor: "#1A7042",
            color: "white",
            "&:hover": {
              backgroundColor: "#145A32",
            },
          }}
        >
          Descargar Excel
        </Button>
        <Button
          variant="outlined"
          onClick={() => setShowDuplicates((prev) => !prev)}
          sx={{ mt: 1 }}
        >
          {showDuplicates ? "Mostrar Todos" : "Mostrar Duplicados"}
        </Button>
      </Box>
      <DataGrid
        rows={filteredRows}
        columns={columns.map((column) => ({
          ...column,
        }))}
        pageSize={5}
        loading={loading}
        checkboxSelection={false}
        autoPageSize={true}
        disableColumnMenu={false}
        disableColumnSelector
        disableRowSelectionOnClick
        getRowId={(row) => row.uniqueId}
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        sx={{
          ".MuiDataGrid-virtualScroller": {
            overflowX: "auto",
          },
        }}
      />
    </Box>
  );
};

export default ReusableTableCedibles;