import React, { useState, useEffect } from "react";
import { Grid, Container, Typography } from "@mui/material";
import CustomAutocomplete from "../../../utils/CustomAutocomplete";
import CustomTextField from "../../../utils/CustomTextField";
import CustomButton from "../../../utils/CustomButton";
import CustomDate from "../../../utils/CustomDate";
import {
  obtenerCiudades,
  obtenerClientes,
  obtenerDatosCliente,
  EtiquetaObtener,
  editOrdenData,
  buscarManifiestos,
  buscarMovimientos,
} from "../../../services/ordenesServices";
import { toast } from "react-toastify";
import { jsPDF } from "jspdf";
import { useSelector } from "react-redux";
import ImageModal from "./ImageModal";
import DocumentHandler from "./DocumentHandler";
import ManifiestoParaOrden from "./manifiestoParaOrden";
import MovimientosParaOrden from "./movimientoParaOrden";
import Fab from "@mui/material/Fab";
import SaveIcon from "@mui/icons-material/Save";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";

const EdicionOrdenes = ({ ordenData: initialOrdenData }) => {
  const userInfo = useSelector((state) => state.user.userInfo);
  const id_usuario = userInfo ? userInfo.id : null;
  const [editableOrdenData, setEditableOrdenData] = useState({ ...initialOrdenData });
  const [ciudades, setCiudades] = useState([]);
  const [comunaOrigen, setComunaOrigen] = useState(null);
  const [comunaDestino, setComunaDestino] = useState(null);
  const [clientes, setClientes] = useState([]);
  const [cliente, setCliente] = useState(null);
  const [fechaDigitacion, setFechaDigitacion] = useState(new Date(editableOrdenData.FH_DIGITACION));
  const tiposDeOrden = ["ENTREGA", "RETIRO"];
  const vias = ["AEREO", "TERRESTRE"];
  const tiposDeCarga = ["AMBIENTE", "FRIO", "BOLSAS", "CAJA", "REFRIGERADO", "SOBRE", "PALLET"];
  const [tipoOrden, setTipoOrden] = useState(editableOrdenData.TIPO_ORDEN || "");
  const [via, setVia] = useState(editableOrdenData.VIA || "");
  const [tipoCarga, setTipoCarga] = useState(editableOrdenData.TIPO_CARGA || "");
  const [canales, setCanales] = useState([]);
  const [tiposNegocio, setTiposNegocio] = useState([]);
  const [centrosCosto, setCentrosCosto] = useState([]);
  const [direcciones, setDirecciones] = useState([]);
  const [canal, setCanal] = useState(editableOrdenData.CANAL || null);
  const [tipoDeNegocio, setTipoDeNegocio] = useState(editableOrdenData.TIPO_NEGOCIO || null);
  const [centroDeCosto, setCentroDeCosto] = useState(editableOrdenData.CENTRO_COSTO || null);
  const [loading, setLoading] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [totalImages, setTotalImages] = useState(0);
  const [base64Images, setBase64Images] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [manifiestos, setManifiestos] = useState([]);
  const [movimientos, setMovimientos] = useState([]);
  const [guias, setGuias] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [dimensions, setDimensions] = useState({
    ALTO: editableOrdenData?.ALTO || "",
    LARGO: editableOrdenData?.LARGO || "",
    ANCHO: editableOrdenData?.ANCHO || "",
  });

  const handleInputChange = (e, convertToUpperCase = false) => {
    const { name, value } = e.target;
    let updatedValue = value;

    if (convertToUpperCase) {
      updatedValue = value.toUpperCase();
    }
    setEditableOrdenData((prev) => ({
      ...prev,
      [name]: updatedValue,
    }));
  };

  const handleDimensionChange = (field, value) => {
    setDimensions((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  /* calcular el volumen */
  const calculateVolume = () => {
    const volume = (dimensions.ALTO * dimensions.LARGO * dimensions.ANCHO) / 4000;
    return volume.toFixed(3);
  };

  const handleFechaChange = (newValue) => {
    setFechaDigitacion(newValue);
  };

  /* obtener etiquetas logica */
  const handleObtenerEtiqueta = async () => {
    const OD = editableOrdenData.OD;
    setLoading(true);
    try {
      const etiquetaData = await EtiquetaObtener(OD);
      setBase64Images(etiquetaData.etiqueta);
      setTotalImages(etiquetaData.etiqueta.length);
      setCurrentImageIndex(0);
      mostrarImagen();
    } catch (error) {
      toast.error("Error al obtener las etiquetas. Por favor, inténtalo de nuevo.");
    } finally {
      setLoading(false);
    }
  };

  /* modal para mostrar imagen */
  const mostrarImagen = () => {
    setShowModal(true);
  };

  /* funcion para las fotos y ir a imprimir */
  const handlePrint = () => {
    if (base64Images.length === 0) {
      toast.error("No hay imágenes para imprimir");
      return;
    }
    const pdf = new jsPDF({
      unit: "mm",
      format: [100, 100],
    });
    base64Images.forEach((image, index) => {
      if (index > 0) {
        pdf.addPage();
      }
      const imageWidth = 100;
      const imageHeight = 100;
      const scale = Math.min(100 / imageWidth, 100 / imageHeight);
      const scaledWidth = imageWidth * scale;
      const scaledHeight = imageHeight * scale;
      const xPosition = (100 - scaledWidth) / 2;
      const yPosition = (100 - scaledHeight) / 2;

      pdf.addImage(image, "PNG", xPosition, yPosition, scaledWidth, scaledHeight);
    });
    const pdfBlob = pdf.output("blob");
    const pdfUrl = URL.createObjectURL(pdfBlob);
    const printWindow = window.open(pdfUrl, "_blank");
    printWindow.onload = () => {
      setTimeout(() => {
        printWindow.focus();
        printWindow.print();
        URL.revokeObjectURL(pdfUrl);
      }, 500);
    };
  };

  /* funcion para guardar la edicion */
  const handleSave = async () => {
    const volume = calculateVolume();
    const editedData = {
      ...editableOrdenData,
      ALTO: dimensions.ALTO,
      LARGO: dimensions.LARGO,
      ANCHO: dimensions.ANCHO,
      PESO_VOLUMEN: volume,
      USUARIO: id_usuario,
      CUIDAD_ORIGEN: comunaOrigen ? comunaOrigen.IATA : "",
      CUIDAD_DESTINO: comunaDestino ? comunaDestino.IATA : "",
      GUIA: guias.join(" - "),
      COD_CLIENTE: editableOrdenData.COD_CLIENTE,
      CANAL: editableOrdenData.CANAL,
      TIPO_NEGOCIO: editableOrdenData.TIPO_NEGOCIO,
      CENTRO_COSTO: editableOrdenData.CENTRO_COSTO,
      FH_DIGITACION: fechaDigitacion,
    };
    const ID = editableOrdenData.ID;
    try {
      await editOrdenData(ID, editedData);
      toast.success("Orden editada exitosamente.");
      setUpdateTrigger((prev) => !prev); 
    } catch (error) {
      toast.error(`Error al editar la orden: ${error.message}`);
    }
  };

  /* generador de id referencia */
  const generadorReferencia = () => {
    let date = new Date();
    var TEXT =
      date.getFullYear() +
      "" +
      (date.getMonth() + 1) +
      "" +
      date.getDate() +
      "" +
      date.getHours() +
      "" +
      date.getMinutes() +
      "" +
      date.getSeconds();

    setEditableOrdenData((prevState) => ({
      ...prevState,
      ID_REFERENCIA: TEXT,
    }));
  };

  useEffect(() => {
    if (editableOrdenData) {
      setDimensions({
        ALTO: editableOrdenData.ALTO || "",
        LARGO: editableOrdenData.LARGO || "",
        ANCHO: editableOrdenData.ANCHO || "",
      });
    }
  }, [editableOrdenData]);

  /* cargar clientes */
  useEffect(() => {
    const cargarClientes = async () => {
      try {
        const clientesObtenidos = await obtenerClientes();
        setClientes(clientesObtenidos);
        if (editableOrdenData.NOMBRE_CLIENTE) {
          const clienteActualizado = clientesObtenidos.find((c) => c.NOMBRE === editableOrdenData.NOMBRE_CLIENTE);
          setCliente(clienteActualizado);
        }
      } catch (error) {}
    };
    cargarClientes();
  }, [editableOrdenData.NOMBRE_CLIENTE]);

  /* cargar ciudades */
  useEffect(() => {
    const cargarCiudades = async () => {
      try {
        const ciudadesObtenidas = await obtenerCiudades();
        setCiudades(ciudadesObtenidas);
        const comunaOrigen = ciudadesObtenidas.find((c) => c.IATA === initialOrdenData.COMUNA_ORIGEN);
        const comunaDestino = ciudadesObtenidas.find((c) => c.IATA === initialOrdenData.COMUNA);
        setComunaOrigen(comunaOrigen);
        setComunaDestino(comunaDestino);
      } catch (error) {}
    };
    cargarCiudades();
  }, [initialOrdenData.COMUNA, initialOrdenData.COMUNA_ORIGEN]);

  /* obtener el resto de datos para el cliente */
  useEffect(() => {
    const cargarDatosCliente = async () => {
      if (cliente && cliente.ID) {
        setIsLoading(true);
        try {
          const { canales, tiposNegocio, centrosCosto, direcciones } = await obtenerDatosCliente(cliente.ID);
          setCanales(canales);
          setTiposNegocio(tiposNegocio);
          setCentrosCosto(centrosCosto);
          setDirecciones(direcciones);
        } catch (error) {
        } finally {
          setIsLoading(false);
        }
      } else {
        setCanales([]);
        setTiposNegocio([]);
        setCentrosCosto([]);
        setDirecciones([]);
      }
    };
    cargarDatosCliente();
  }, [cliente]);

  /* UseEffect para inicializar los valores cuando la página carga por primera vez */
  useEffect(() => {
    setCanal(editableOrdenData.CANAL || null);
    setTipoDeNegocio(editableOrdenData.TIPO_NEGOCIO || null);
    setCentroDeCosto(editableOrdenData.CENTRO_COSTO || null);
  }, [editableOrdenData.CANAL, editableOrdenData.TIPO_NEGOCIO, editableOrdenData.CENTRO_COSTO]);

  /* carga manifiestos */
  useEffect(() => {
    const cargarManifiestos = async () => {
      if (editableOrdenData.OD) {
        try {
          const manifiestosObtenidos = await buscarManifiestos({ searchValue: editableOrdenData.OD });
          setManifiestos(manifiestosObtenidos);
        } catch (error) {}
      }
    };
    cargarManifiestos();
  }, [editableOrdenData.OD]);

  /* carga movimientos de la orden */
  useEffect(() => {
    const cargarMovimientos = async () => {
      if (editableOrdenData.OD) {
        try {
          const movimientosObtenidos = await buscarMovimientos({ searchValue: editableOrdenData.OD });
          setMovimientos(movimientosObtenidos);
        } catch (error) {
          console.error("Error al cargar movimientos:", error);
        }
      }
    };
    cargarMovimientos();
  }, [editableOrdenData.OD, updateTrigger]);

  useEffect(() => {
    if (editableOrdenData) {
      setDimensions({
        ALTO: editableOrdenData.ALTO || "",
        LARGO: editableOrdenData.LARGO || "",
        ANCHO: editableOrdenData.ANCHO || "",
      });
    }
  }, [editableOrdenData]);

  return (
    <Container maxWidth="lg">
      <Typography variant="h6" gutterBottom>
        Datos orden
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={2.4}>
          <CustomTextField
            label="OD"
            name="OD"
            value={editableOrdenData.OD || ""}
            onChange={handleInputChange}
            margin="dense"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2.4}>
          <CustomTextField
            label="OD Papel"
            name="OD_PAPEL"
            value={editableOrdenData.OD_PAPEL || ""}
            onChange={handleInputChange}
            margin="dense"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2.4}>
          <div style={{ position: "relative" }}>
            <CustomTextField
              label="ID Referencia"
              name="ID_REFERENCIA"
              value={editableOrdenData.ID_REFERENCIA || ""}
              margin="dense"
            />
            <button
              type="button"
              onClick={generadorReferencia}
              style={{
                background: "none",
                border: "none",
                position: "absolute",
                top: "50%",
                right: "10px",
                transform: "translateY(-50%)",
                cursor: "pointer",
              }}
            >
              <FontAwesomeIcon icon={faPen} />
            </button>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={2.4}>
          <CustomTextField
            label="Agente asignado"
            name="AGENTE_ASIGNADO"
            value={editableOrdenData.AGENTE_ASIGNADO || ""}
            onChange={handleInputChange}
            disabled
            margin="dense"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2.4}>
          <CustomTextField
            label="Correo"
            name="MAIL"
            value={editableOrdenData.MAIL || ""}
            onChange={handleInputChange}
            margin="dense"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2.4}>
          <CustomAutocomplete
            label="Clientes"
            options={clientes}
            getOptionLabel={(option) => option.NOMBRE}
            value={cliente}
            onChange={(event, newValue) => {
              setCliente(newValue);
              setEditableOrdenData((prevState) => ({
                ...prevState,
                COD_CLIENTE: newValue ? newValue.ID : null,
              }));
            }}
            isLoading={isLoading}
            margin="dense"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2.4}>
          <CustomAutocomplete
            label="Comuna Origen"
            options={ciudades}
            getOptionLabel={(option) => `${option.NOMBRE} (${option.IATA})`}
            value={comunaOrigen}
            onChange={(event, newValue) => {
              setComunaOrigen(newValue);
              setEditableOrdenData((prev) => ({
                ...prev,
                IATA_CIUDAD_ORIGEN: newValue ? newValue.IATA : "",
              }));
            }}
            isLoading={isLoading}
            margin="dense"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2.4}>
          <CustomAutocomplete
            label="Comuna Destino"
            options={ciudades}
            getOptionLabel={(option) => `${option.NOMBRE} (${option.IATA})`}
            value={comunaDestino}
            onChange={(event, newValue) => {
              setComunaDestino(newValue);
              setEditableOrdenData((prev) => ({
                ...prev,
                IATA_CIUDAD_DESTINO: newValue ? newValue.IATA : "",
              }));
            }}
            isLoading={isLoading}
            margin="dense"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2.4}>
          <CustomDate label="Fecha de Digitación" value={fechaDigitacion} onChange={handleFechaChange} margin="dense" />
        </Grid>
        <Grid item xs={12} sm={6} md={2.4}>
          <CustomAutocomplete
            label="Canales"
            options={canales}
            getOptionLabel={(option) => option}
            value={canal}
            onChange={(event, newValue) => {
              setCanal(newValue);
              setEditableOrdenData((prevState) => ({
                ...prevState,
                CANAL: newValue,
              }));
            }}
            isLoading={isLoading}
            margin="dense"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2.4}>
          <CustomAutocomplete
            label="Tipo de negocio"
            options={tiposNegocio}
            getOptionLabel={(option) => option}
            value={tipoDeNegocio}
            onChange={(event, newValue) => {
              setTipoDeNegocio(newValue);
              setEditableOrdenData((prevState) => ({
                ...prevState,
                TIPO_NEGOCIO: newValue,
              }));
            }}
            isLoading={isLoading}
            margin="dense"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2.4}>
          <CustomAutocomplete
            label="Centro costo"
            freeSolo
            options={centrosCosto}
            getOptionLabel={(option) => option}
            value={centroDeCosto}
            onChange={(event, newValue) => {
              setCentroDeCosto(newValue);
              setEditableOrdenData((prevState) => ({
                ...prevState,
                CENTRO_COSTO: newValue,
              }));
            }}
            onInputChange={(event, newInputValue) => {
              setEditableOrdenData((prevState) => ({
                ...prevState,
                CENTRO_COSTO: newInputValue,
              }));
            }}
            isLoading={isLoading}
            margin="dense"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2.4}>
          <CustomAutocomplete
            label="Tipo de orden"
            options={tiposDeOrden}
            getOptionLabel={(option) => option}
            value={tipoOrden}
            onChange={(event, newValue) => {
              setTipoOrden(newValue);
              setEditableOrdenData((prev) => ({ ...prev, TIPO_ORDEN: newValue }));
            }}
            margin="dense"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2.4}>
          <CustomAutocomplete
            label="Via"
            options={vias}
            getOptionLabel={(option) => option}
            value={via}
            onChange={(event, newValue) => {
              setVia(newValue);
              setEditableOrdenData((prev) => ({ ...prev, VIA: newValue }));
            }}
            margin="dense"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2.4}>
          <CustomAutocomplete
            label="Tipo de carga"
            options={tiposDeCarga}
            getOptionLabel={(option) => option}
            value={tipoCarga}
            onChange={(event, newValue) => {
              setTipoCarga(newValue);
              setEditableOrdenData((prev) => ({ ...prev, TIPO_CARGA: newValue }));
            }}
            margin="dense"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2.4}>
          <CustomTextField
            label="NOTA"
            name="NOTA"
            onChange={handleInputChange}
            value={editableOrdenData.NOTA || ""}
            margin="dense"
          />
        </Grid>
      </Grid>

      {/* Remitente Section */}
      <Typography variant="h6" gutterBottom style={{ marginTop: "5px" }}>
        Remitente
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={3}>
          <CustomTextField
            label="Nombre retiro"
            name="NOMBRE_RETIRO"
            value={editableOrdenData.NOMBRE_RETIRO || ""}
            onChange={handleInputChange}
            margin="dense"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <CustomTextField
            label="Dirección retiro"
            name="DIRECCION_RETIRO"
            value={editableOrdenData.DIRECCION_RETIRO || ""}
            onChange={handleInputChange}
            margin="dense"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <CustomTextField
            label="Contacto retiro"
            name="CONTACTO"
            value={editableOrdenData.CONTACTO || ""}
            onChange={handleInputChange}
            margin="dense"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <CustomTextField
            label="Teléfono retiro"
            name="TELEFONO_ORIGEN"
            value={editableOrdenData.TELEFONO_ORIGEN || ""}
            onChange={handleInputChange}
            margin="dense"
          />
        </Grid>
      </Grid>

      {/* Destinatario Section */}
      <Typography variant="h6" gutterBottom style={{ marginTop: "5px" }}>
        Destinatario
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={3}>
          <CustomTextField
            label="Nombre"
            name="NOMBRE"
            value={editableOrdenData.NOMBRE || ""}
            onChange={handleInputChange}
            margin="dense"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <CustomTextField
            label="RUT"
            name="RUT"
            value={editableOrdenData.RUT || ""}
            onChange={handleInputChange}
            margin="dense"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <CustomAutocomplete
            label="Direcciones"
            freeSolo
            options={direcciones}
            getOptionLabel={(option) => (option ? option : "")}
            value={editableOrdenData.DIRECCION || ""}
            onChange={(event, newValue) => {
              setEditableOrdenData((prevState) => ({
                ...prevState,
                DIRECCION: newValue,
              }));
            }}
            onInputChange={(event, newInputValue) => {
              setEditableOrdenData((prevState) => ({
                ...prevState,
                DIRECCION: newInputValue,
              }));
            }}
            isLoading={isLoading}
            margin="dense"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <CustomTextField
            label="Contacto destino"
            name="CONTACTO_DESTINO"
            value={editableOrdenData.CONTACTO_DESTINO || ""}
            onChange={handleInputChange}
            margin="dense"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <CustomTextField
            label="Teléfono"
            name="TELEFONO"
            value={editableOrdenData.TELEFONO || ""}
            onChange={handleInputChange}
            margin="dense"
          />
        </Grid>
      </Grid>

      {/* Dimensiones Section */}
      <Typography variant="h6" gutterBottom style={{ marginTop: "5px" }}>
        Dimensiones
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={3}>
          <CustomTextField
            label="Bultos"
            name="BULTOS"
            value={editableOrdenData.BULTOS || ""}
            onChange={handleInputChange}
            margin="dense"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <CustomTextField
            label="Peso KG"
            name="PESO"
            value={editableOrdenData.PESO || ""}
            onChange={handleInputChange}
            margin="dense"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <CustomTextField
            label="ALTO"
            name="ALTO"
            value={dimensions.ALTO}
            onChange={(e) => handleDimensionChange("ALTO", e.target.value)}
            margin="dense"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <CustomTextField
            label="LARGO"
            name="LARGO"
            value={dimensions.LARGO}
            onChange={(e) => handleDimensionChange("LARGO", e.target.value)}
            margin="dense"
          />
        </Grid>
        {/* Second Row for Dimensiones */}
        <Grid item xs={12} sm={6} md={3}>
          <CustomTextField
            label="ANCHO"
            name="ANCHO"
            value={dimensions.ANCHO}
            onChange={(e) => handleDimensionChange("ANCHO", e.target.value)}
            margin="dense"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <CustomTextField label="VOLUMEN" name="VOLUMEN" value={calculateVolume()} readOnly margin="dense" />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <CustomButton onClick={handleObtenerEtiqueta} isLoading={loading}>
            {loading ? "Cargando..." : "Etiqueta"}
          </CustomButton>
        </Grid>
      </Grid>

      {/* Image Modal */}
      <ImageModal
        showModal={showModal}
        setShowModal={setShowModal}
        base64Images={base64Images}
        currentImageIndex={currentImageIndex}
        setCurrentImageIndex={setCurrentImageIndex}
        totalImages={totalImages}
        handlePrint={handlePrint}
      />

      {/* DocumentHandler Component */}
      {editableOrdenData && editableOrdenData.ID && (
        <DocumentHandler ordenData={editableOrdenData} guias={guias} setGuias={setGuias} />
      )}

      {/* ManifiestoParaOrden Component */}
      {manifiestos.length > 0 && <ManifiestoParaOrden manifiestos={manifiestos} />}

      {/* MovimientosParaOrden Component */}
      {movimientos.length > 0 && <MovimientosParaOrden movimientos={movimientos} />}

      {/* Save Button */}
      <Fab
        color="primary"
        aria-label="save"
        sx={{
          position: "fixed",
          bottom: 20,
          right: 20,
          backgroundColor: "#041562",
          "&:hover": {
            backgroundColor: "#031042",
          },
          zIndex: 1000,
        }}
        onClick={handleSave}
      >
        <SaveIcon />
      </Fab>
    </Container>
  );
};

export default EdicionOrdenes;
